import React from 'react'
import {
    Box, Typography, Button, Dialog, DialogTitle,
    InputLabel, Container, TableBody, Table,
    TableContainer, TableHead, TableRow, Snackbar, Alert, Stack, CircularProgress, DialogContent, DialogActions, Chip,

} from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import { ThemeProvider } from '@mui/styles'
import { useDropzone } from 'react-dropzone'
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import MyForm from './MyForm';
import { StyledButtonOutlined, StyledTableCell, StyledTableContainer, StyledTableRow, StyledSelectOpt, StyledMenuItem, StyledTextField, colorTheme } from '../../../stylings/styles';
import importIcon from '../../../img/icons/importIcon.png';
import filterIcon from '../../../img/icons/filter.png';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";


const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

// const StyledTextField = withStyles({
//     root: {
//         '& .MuiOutlinedInput-root': {
//             '& fieldset': {
//                 border: '0px solid',
//             },
//             '&.Mui-focused fieldset': {
//                 borderColor: "#fff",
//             },
//         },
//     },
// })(TextField);
// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: "#FAFAFA",
//         fontSize: "12px",
//         fontWeight: "700",
//     },
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 14,
//     },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     // hide last border
//     '&:last-child td, &:last-child th': {
//         border: 0,
//     },
// }));

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const Leads = (props) => {
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false)
    const [csvText, setCSVTxt] = React.useState("")
    const [successMessage, setSuccessMessage] = React.useState('');
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(null)
    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(null)
    const [manualEntry, setManualEntry] = React.useState(false);
    const [csvFile, setCsvFile] = React.useState();
    const [headers, setHeaders] = React.useState(null);
    const [manualData, setManualData] = React.useState([])
    const [leadContacts, setLeadContacts] = React.useState([])
    const [isSomethingChanged, setIsSomethingChanged] = React.useState(false)
    const [leadsLoading, setLeadsLoading] = React.useState(false)
    const [typeMap, setTypeMap] = React.useState(null)
    const [newLeadAdded, setNewLeadAdded] = React.useState(false)
    const [editButtonClicked, setEditButtonClicked] = React.useState(false)
    const [editFormData, setEditFormData] = React.useState(null)
    const [filterValue, setFilterValue] = React.useState("none")
    const [buttonLoading, setButtonLoading] = React.useState(false)
    const [deleteLoading, setDeleteLoading] = React.useState(false)
    const [deleteLeadDialougeOpen, setDeleteLeadDialougeOpen] = React.useState(false)
    const [uploading, setUploading] = React.useState(false);
    const [uploadProgress, setUploadProgress] = React.useState(0);

    const [checkedDeleteRows, setCheckedDeleteRows] = React.useState([]);
    // console.log(props.id)

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorSnackbarOpen(false);
    }
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessSnackbarOpen(false);
    }


    React.useEffect(() => {
        funcFetch();
    }, [newLeadAdded])

    React.useEffect(() => {
        funcFetch();
    }, [successSnackbarOpen])

    async function funcFetch() {
        fetch(`${REACT_APP_SERVER_URL}/get_leads`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "campaign": {
                    'campaign_id': props.id
                }
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else if (response.status === 202) {
                console.log("token expired")
                navigate('/login', { state: { sessionExpired: true } })
            }
            else {
                console.log("some error")
            }
        })
            .then(data => {
                console.log(data);
                setLeadContacts(data['data']['data'])
            })
            .catch(error => console.log(error))
    }

    const { getRootProps, getInputProps } = useDropzone({
        noClick: csvFile === undefined ? false : true,
        accept: { "text/csv": [".csv"] },
        onDrop: async (acceptedFiles) => {
            setCsvFile(acceptedFiles[acceptedFiles.length - 1])
            setUploading(true);

            // for (let progress = 0; progress <= 100; progress++) {
            //     await new Promise((resolve) => setTimeout(resolve, 100));
            //     setUploadProgress(progress);
            // }


            setUploading(false);
            setUploadProgress(0);
        }

    })
    const processCSV = (str, delim = ',') => {
        const head = str.slice(0, str.indexOf('\r\n')).split(delim);
        setHeaders(head);
        let tempTypeMap = {}
        console.log(head)
        for (let i = 0; i < head.length; i++) {
            tempTypeMap[head[i]] = null
        }
        setTypeMap(tempTypeMap)
    }

    const processCsvForSeperating = (csvData) => {
        console.log(csvData);
        const rows = csvData.trim().split('\n');
        const header = rows[0].split(',');
        const data = {};

        for (let i = 1; i < rows.length; i++) {
            const values = rows[i].split(',');
            for (let j = 0; j < values.length; j++) {
                const columnName = header[j]?.replace(/ |\r\n|\r|\n/g, '');
                const columnValue = values[j]?.trim();

                if (!data[columnName]) {
                    data[columnName] = [];
                }
                data[columnName].push(columnValue);
            }
        }
        data['headers'] = header.length;
        setCSVTxt(data)
    }
    const submit = () => {
        try {
            const file = csvFile;
            const reader = new FileReader();
            reader.onload = function (e) {
                const text = e.target.result;
                processCsvForSeperating(text)
                processCSV(text)
            }
            reader.readAsText(file);

        }
        catch (err) {
            console.log(err)
        }
    }

    const handleSelectChange = (col, data) => {
        let tempTypeMap = typeMap
        tempTypeMap[col] = data
        setTypeMap(tempTypeMap)
        setIsSomethingChanged(!isSomethingChanged)
    }

    const handleTypeSelectHandler = () => {
        setButtonLoading(true)
        fetch(`${REACT_APP_SERVER_URL}/upload_csv`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "data": { mapping: { ...reverseObjectKeyValue(typeMap) }, ...csvText },
                'campaign_id': props.id,
            })
        }).then(response => {
            if (response.status === 200) {
                setButtonLoading(false)
                handleDialogClose()
                setNewLeadAdded(!newLeadAdded)
                return response.json()
            }
            else if (response.status === 202) {
                setButtonLoading(false)
                console.log("token expired")
                navigate('/login', { state: { sessionExpired: true } })
                return response.json()
            }
            else {
                console.log("some error")
                setButtonLoading(false)
                setErrorSnackbarOpen(true)
                return response.json()
            }
        })
            .then(data => {
                if (data.status === 201) {
                    setErrorMessage(data.msg)
                    handleDialogClose()
                    return;
                }
                if (data.status === 501) {
                    setErrorMessage(data.msg)
                    handleDialogClose()
                    return;
                }
                if (data.status === 200) {
                    setSuccessSnackbarOpen(true)
                    setSuccessMessage(data.msg)
                    handleDialogClose()
                }
                else {
                    setErrorMessage("Something Went Wrong");
                    handleDialogClose()
                    return;
                }
                console.log(data)
            })
            .catch(error => console.log(error))

    }
    const addRowHandler = () => {
        let tempManualData = manualData
        tempManualData.push({
            email: "",
            Fname: '',
            Lname: "",
        })
        setIsSomethingChanged(!isSomethingChanged)
        setManualData(tempManualData)
    }

    function removeRow(index) {
        if (manualData.length === 1) {
            setErrorSnackbarOpen(true);
            setErrorMessage('Row cannot be empty!')
            return;
        }
        else {
            let tempManualData = manualData;
            tempManualData.splice(index, 1);
            setIsSomethingChanged(!isSomethingChanged)
            setManualData(tempManualData)
        }
    }

    function isValidSubmit(arr) {
        for (let i = 0; i < arr.length; i++) {
            const { Fname, email } = arr[i];
            if (!Fname || !email) {
                return false;
            }
        }
        return true;
    }

    function manualDataSubmitHandler() {
        setLeadsLoading(true)
        // alert(isValidSubmit(manualData));

        if (!isValidSubmit(manualData)) {
            setErrorMessage("Form will not submit if email and firstname is empty !!")
            setErrorSnackbarOpen(true)
            setLeadsLoading(false)
            return;
        } else {
            fetch(`${REACT_APP_SERVER_URL}/lead_manual`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                method: 'POST',
                body: JSON.stringify({
                    "data": {
                        'data': manualData,
                        'campaign_id': props.id
                    },
                })
            }).then(response => {
                if (response.status === 200) {
                    setLeadsLoading(false)
                    handleDialogClose()
                    setNewLeadAdded(!newLeadAdded)
                    return response.json()
                }
                else if (response.status === 202) {
                    setLeadsLoading(false)
                    setErrorSnackbarOpen(true)
                    console.log("token expired")
                    navigate('/login', { state: { sessionExpired: true } })
                    return response.json()
                }
                else {
                    console.log("some error")
                    setLeadsLoading(false)
                    setErrorSnackbarOpen(true)
                    return response.json()
                }
            })
                .then(data => {
                    if (data.status === 201) {
                        setErrorMessage(data.msg)
                        return;
                    }
                    if (data.status === 501) {
                        setErrorMessage(data.msg)
                        return;
                    }
                    setSuccessSnackbarOpen(true)
                    setSuccessMessage(data.msg)
                    console.log(data)
                })
                .catch(error => console.log(error))
        }
    }
    const deleteLeadClicked = (checkedDeleteRows) => {
        setDeleteLeadDialougeOpen(true);
        setCheckedDeleteRows(checkedDeleteRows);
    }
    const handleDeleteLead = (data) => {
        setDeleteLoading(true)
        fetch(`${REACT_APP_SERVER_URL}/lead_delete`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "campaign": {
                    'campaign_id': props.id,
                    "lead": data
                }
            })
        }).then(response => {
            if (response.status === 200) {
                setDeleteLoading(false)
                setSuccessMessage("Successfully deleted");
                setCheckedDeleteRows([])
                funcFetch();
                setSuccessSnackbarOpen(true)
                return response.json()
            }
            else if (response.status === 202) {
                console.log("token expired")
                navigate('/login', { state: { sessionExpired: true } })
                setDeleteLoading(false)
            }
            else {
                console.log("some error")
                setErrorMessage(response.msg)
                setErrorSnackbarOpen(true)
            }
        })
            .then(data => {
                setDeleteLoading(false)
                console.log(data.msg);
            })
            .catch(error => console.log(error))
        setDeleteLeadDialougeOpen(false);
    }


    const manualDataChangeHandler = (data, type, i) => {
        let tempManualData = manualData
        tempManualData[i][type] = data
        setIsSomethingChanged(!isSomethingChanged)
        setManualData(tempManualData)
    }
    const handleDialogClose = () => {
        setManualData([])
        setManualEntry(false)
        setCsvFile(undefined)
        setHeaders(null)
        setOpen(false)
    }

    const handleCheckboxChange = (event, id) => {
        if (event.target.checked) {
            setCheckedDeleteRows([...checkedDeleteRows, id]);
        } else {
            setCheckedDeleteRows(checkedDeleteRows.filter((rowId) => rowId !== id));
        }
    };

    const handleSelectAll = () => {
        if (checkedDeleteRows.length === leadContacts.length) {
            setCheckedDeleteRows([]);
        } else {
            const allEmails = leadContacts.map(data => data['email']);
            setCheckedDeleteRows(allEmails);
        }
    };


    const validateEmail = (email) => {
        // Regular expression to validate email format
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return emailRegex.test(email);
    };

    function validateEmails(array) {
        return array.every((obj) => obj['email'] && validateEmail(obj['email']));
    }
    function reverseObjectKeyValue(obj) {
        const reversedObj = {};
        for (const key in obj) {
            const value = obj[key];
            {

                if (value === 'empty' || value === null || value === '') {
                }
                else {

                    if (value === "custom") {
                        if (!reversedObj[value]) {
                            reversedObj[value] = [];
                        }
                        reversedObj[value].push(key);
                    } else {
                        reversedObj[value] = key;
                    }

                }
            }
        }
        return reversedObj;
    }

    // console.log();
    return (
        <ThemeProvider theme={colorTheme}>
            <Dialog
                PaperProps={{
                    style: { borderRadius: "20px" }
                }}
                open={deleteLeadDialougeOpen}
            >
                <div style={{ width: "500px", height: "200px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <DialogTitle id="alert-dialog-title">
                        <p style={{ color: "#EF6D6D", fontWeight: "bold", fontSize: "20px", textAlign: 'center' }}>
                            Are you sure you want to delete selected Leads?
                        </p>
                    </DialogTitle>
                    <DialogActions sx={{ mb: 4, float: "right" }}>
                        <Button onClick={() => handleDeleteLead(checkedDeleteRows)} variant="contained" sx={{ color: "#fff" }} color='success'>Delete</Button>
                        <Button onClick={() => { setDeleteLeadDialougeOpen(false); setCheckedDeleteRows([]) }} variant="contained" sx={{ color: "#fff" }} color='error' disabled={false}>
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
            {/* Value {JSON.stringify(checkedDeleteRows)} */}
            {!editButtonClicked && <>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                    <Stack direction="row" alignItems="center" gap="20px">
                        <FormControl sx={{ minWidth: 90, textAlign: 'center' }} size='small'>
                            <InputLabel id="demo-simple-select-autowidth-label">
                                <Stack direction='row' alignItems='center' spacing={1} sx={{ fontSize: '14px' }}><img src={filterIcon} alt="icon" width='18px' /> <span>Filter</span></Stack>
                            </InputLabel>
                            <StyledSelectOpt
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={filterValue === "none" ? "" : filterValue}
                                onChange={(e) => {
                                    setFilterValue(e.target.value)
                                }}
                                autoWidth
                                label="Filter"
                                inputProps={{ IconComponent: () => null }}
                                sx={{ height: "2.2rem", borderRadius: "2px" }}
                            >
                                <StyledMenuItem value={"opened"} sx={{ width: "150px", color: "green", display: "flex", flexDirection: "column" }}>Opened</StyledMenuItem>
                                <StyledMenuItem value={"not_opened"} sx={{ width: "150px", color: "purple", display: "flex", flexDirection: "column" }}>Not Opened</StyledMenuItem>
                                <StyledMenuItem value={"sent"} sx={{ width: "150px", color: "green", display: "flex", flexDirection: "column" }}>Sent</StyledMenuItem>
                                <StyledMenuItem value={"not_sent"} sx={{ width: "150px", color: "purple", display: "flex", flexDirection: "column" }}>Not Sent</StyledMenuItem>
                                <StyledMenuItem value={"replied"} sx={{ width: "150px", color: "green", display: "flex", flexDirection: "column" }}>Replied</StyledMenuItem>
                                <StyledMenuItem value={1} sx={{ width: "150px", color: "purple", display: "flex", flexDirection: "column" }}>Unsubscribed</StyledMenuItem>
                                <StyledMenuItem value={"none"} sx={{ width: "150px", color: "black", display: "flex", flexDirection: "column" }}>None</StyledMenuItem>
                            </StyledSelectOpt>
                        </FormControl>
                        <Typography>Total Leads: <strong>{leadContacts.length}</strong></Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap="20px">
                        <Button onClick={() => setOpen(true)} sx={{ ml: 1, mb: 1, backgroundColor: '#481BEB' }} variant="contained">Add data</Button>
                        <Button onClick={handleSelectAll} sx={{ ml: 1, mb: 1, }} color="error" variant="outlined">{checkedDeleteRows.length === leadContacts.length ? "Unselect All" : "Select All"}</Button>
                        <Button onClick={() => deleteLeadClicked(checkedDeleteRows)} sx={{ mb: 1, display: checkedDeleteRows.length !== 0 ? "flex" : "none", color: "#fff" }} variant="contained" color='error'>Delete</Button>
                    </Stack>
                </Stack>
            </>}

            {
                leadContacts.length === 0 &&
                <StyledTableContainer>
                    <Table sx={{ minWidth: 650, borderCollapse: "separate", borderSpacing: "0 10px", }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell >EMAIL</StyledTableCell>
                                <StyledTableCell >FIRST NAME</StyledTableCell>
                                <StyledTableCell >LAST NAME</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                leadContacts.map((data, i) => (
                                    <StyledTableRow key={i}>
                                        <StyledTableCell sx={{ fontSize: "14px", fontWeight: '700' }}>

                                        </StyledTableCell>
                                        <StyledTableCell >

                                        </StyledTableCell>
                                        <StyledTableCell >

                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            }

                        </TableBody>
                    </Table>
                </StyledTableContainer>
            }
            {
                !deleteLoading ? leadContacts.length !== 0 && !editButtonClicked &&
                    <StyledTableContainer>
                        <Table sx={{ minWidth: 650, borderCollapse: "separate", borderSpacing: "0 10px", }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell >EMAIL</StyledTableCell>
                                    <StyledTableCell >FIRST NAME</StyledTableCell>
                                    <StyledTableCell >LAST NAME</StyledTableCell>
                                    <StyledTableCell >EDIT LEAD</StyledTableCell>
                                    <StyledTableCell >DELETE</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    leadContacts.filter(item => item.status === (filterValue) || item.open_status === (filterValue) || item.unsubscribed === (filterValue) || (filterValue === "none")).map((data, i) => (
                                        <StyledTableRow hover sx={{ cursor: "pointer" }} key={i}>
                                            <StyledTableCell onClick={() => {
                                                setEditButtonClicked(true);
                                                setEditFormData(data)
                                            }} sx={{ fontSize: "14px", width: "40%", fontWeight: '700' }}>
                                                {data['email']}
                                                <Chip label={data['status']} sx={{ ml: "10px", color: data['status'] === "unsubscribed" ? "red" : "" }} size='small' color="primary" variant="outlined" />
                                                <Chip label={data['open_status']} sx={{ ml: "10px", color: data['open_status'] === "bounced" ? "red" : "" }} size='small' color="success" variant="outlined" />

                                                {
                                                    data['unsubscribed'] === 1 && <Chip label={data['unsubscribed'] ? "Unsubscribed" : "Unsubscribed"} sx={{ ml: "10px", pt: 0.5, color: !data['unsubscribed'] ? "red" : "red" }} size='small' variant="outlined" />
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => {
                                                setEditButtonClicked(true);
                                                setEditFormData(data)
                                            }}>
                                                {data['Fname']}
                                            </StyledTableCell >
                                            <StyledTableCell onClick={() => {
                                                setEditButtonClicked(true);
                                                setEditFormData(data)
                                            }}>
                                                {data['Lname']}
                                            </StyledTableCell>
                                            <StyledTableCell >
                                                <Button onClick={() => {
                                                    setEditButtonClicked(true);
                                                    setEditFormData(data)
                                                }}>
                                                    <EditIcon sx={{ color: '#481BEB' }} />
                                                </Button>
                                            </StyledTableCell>
                                            <StyledTableCell >
                                                <Checkbox
                                                    checked={checkedDeleteRows.includes(data['email'])}
                                                    onChange={(event) => handleCheckboxChange(event, data['email'])}
                                                    sx={{
                                                        color: '#EF6D6D',
                                                        '&.Mui-checked': {
                                                            color: '#EF6D6D',
                                                        },
                                                    }}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                }

                            </TableBody>
                        </Table>
                    </StyledTableContainer> : <h1>
                    <CircularProgress size={25} />&nbsp;deleting...........
                </h1>

                // < Stack >
                //     {
                //         leadContacts.map((data) => (
                //             <Box key={data} sx={{ cursor: "pointer", my: 1, p: 2, boxShadow: "0px 2px 11px rgba(0,0,0,0.07)", display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                //                 <Typography>
                //                     {data['email']}
                //                 </Typography>
                //                 <Typography>
                //                     {data['Fname']}
                //                 </Typography>
                //                 <Typography>
                //                     {data['Lname']}
                //                 </Typography>
                //             </Box>
                //         ))
                //     }
                // </Stack >
            }
            {editButtonClicked && <MyForm setSuccessMessage={setSuccessMessage} setSuccessSnackbarOpen={setSuccessSnackbarOpen} setEditButtonClicked={setEditButtonClicked} editFormData={editFormData} />}
            <Dialog
                onClose={handleDialogClose}
                open={open}
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>
                    Add Data
                </DialogTitle>
                <DialogContent sx={{
                    "&::-webkit-scrollbar": {
                        width: "5px",
                        height: "2px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#D0CED5",
                        borderRadius: "6px",
                    },
                }}
                >
                    {
                        !manualEntry &&
                        <>
                            {
                                headers === null &&
                                <>
                                    <Container {...getRootProps()} maxWidth="sm" sx={{ gap: '15px', borderRadius: "10px", p: 4, border: "1px solid #E7E7E7", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                        {
                                            csvFile === undefined &&
                                            <>
                                                <img src={importIcon} alt="icon" width='' />
                                                <input
                                                    {...getInputProps()}
                                                />
                                                <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "#141B3A" }}>
                                                    Drag & Drop file here
                                                </Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "#141B3A" }}>or</Typography>
                                                <StyledButtonOutlined
                                                    variant='outlined'
                                                    sx={{ textTransform: "none", borderColor: '#989898', color: '#7D7D7D' }}
                                                >
                                                    Browse Files
                                                </StyledButtonOutlined>

                                                <Typography align="center" sx={{ color: "#7D7D7D", fontSize: "12px" }}>
                                                    <Checkbox
                                                        // sx={{
                                                        //     color: '#EF6D6D',
                                                        //     '&.Mui-checked': {
                                                        //         color: '#EF6D6D',
                                                        //     },
                                                        // }}
                                                        icon={<RadioButtonUncheckedIcon sx={{ border: '#481BEB' }} />} checkedIcon={<CheckCircleIcon sx={{ color: '#481BEB' }} />}
                                                    />
                                                    Import a CSV file. You can put the columns in any order, XCampaign will automatically recognize each custom variable.
                                                </Typography>
                                            </>
                                        }
                                        {
                                            csvFile !== undefined &&
                                            <>
                                                <Typography>{csvFile.name}</Typography>
                                                {uploading ? (
                                                    <Box sx={{ width: '100%' }}>
                                                        <LinearProgressWithLabel value={uploadProgress} />
                                                    </Box>
                                                ) : (
                                                    <Button variant="outlined" onClick={submit}>Upload</Button>
                                                )}
                                            </>
                                        }
                                        {/* <Typography align="center" sx={{ mt: 1, color: "#141B3A", fontSize: "14px" }}>
                                            I want to verify leads using xemailverify before uploading.
                                        </Typography> */}
                                    </Container>
                                    <Typography align="center" sx={{ my: 2 }}>or</Typography>
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <Button onClick={() => {
                                            setManualEntry(true)
                                            addRowHandler()
                                        }} variant='contained' sx={{ backgroundColor: '#481BEB' }}>Enter Manually</Button>
                                    </Box>
                                </>

                            }
                            {
                                headers !== null &&
                                <>
                                    <TableContainer sx={{ borderRadius: '10px', border: "1px solid #E7E7E7" }}>
                                        <Table sx={{ minWidth: 650, }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell sx={{ textTransform: "uppercase" }}>Column from sheet</StyledTableCell>
                                                    <StyledTableCell align='right' sx={{ textTransform: "uppercase" }}>Field Type</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    headers.map((row, index) => (
                                                        <StyledTableRow key={index} sx={{ boxShadow: "none", borderTop: "1px solid #E7E7E7" }}>
                                                            <StyledTableCell>
                                                                {row}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='right'>

                                                                <FormControl sx={{ minWidth: '150px', ml: 2, }}>
                                                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                                                    <StyledSelectOpt
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"

                                                                        autoWidth
                                                                        onChange={(e) => handleSelectChange(row, e.target.value)}
                                                                        MenuProps={{
                                                                            PaperProps: {
                                                                                sx: {
                                                                                    maxHeight: 100, "&::-webkit-scrollbar": {
                                                                                        width: "5px",
                                                                                        height: "2px",
                                                                                    },
                                                                                    "&::-webkit-scrollbar-thumb": {
                                                                                        backgroundColor: "#D0CED5",
                                                                                        borderRadius: "6px",
                                                                                    },
                                                                                }
                                                                            }
                                                                        }}
                                                                    >

                                                                        <StyledMenuItem value={'email'}>Email</StyledMenuItem>
                                                                        <StyledMenuItem value={'fname'}>First Name</StyledMenuItem>
                                                                        <StyledMenuItem value={'lname'}>Last Name</StyledMenuItem>
                                                                        <StyledMenuItem value={'contact'}>Contacts</StyledMenuItem>
                                                                        <StyledMenuItem value={'company'}>Company</StyledMenuItem>
                                                                        <StyledMenuItem value={'empty'}>Do not import</StyledMenuItem>
                                                                        <StyledMenuItem value={'custom'}>Custom</StyledMenuItem>
                                                                    </StyledSelectOpt>
                                                                </FormControl>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                                        <Button variant='contained' sx={{ float: "right", px: 5, py: 1 }} onClick={handleTypeSelectHandler}>
                                            {buttonLoading ? (
                                                <CircularProgress size={25} sx={{ color: "#fff" }} />
                                            ) : (
                                                'Submit'
                                            )}
                                        </Button>
                                    </Box>
                                </>
                            }
                        </>
                    }
                    {
                        manualEntry &&
                        <>
                            {
                                manualData.map((data, i) => (
                                    <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
                                        <StyledTextField
                                            type='email'
                                            label="Email"
                                            value={data["email"]}
                                            helperText={data['email'] ? validateEmail(data['email']) ? "" : "Please enter valid email format" : ""}
                                            error={data["email"] ? !validateEmail(data['email']) : false}
                                            required
                                            onChange={(e) => {
                                                manualDataChangeHandler(e.target.value, "email", i)
                                            }}
                                            sx={{ backgroundColor: "#E7E7E7", borderRadius: "5px" }}
                                        />
                                        <StyledTextField
                                            required
                                            label="First Name"
                                            sx={{ mx: 2, backgroundColor: "#E7E7E7", borderRadius: "5px" }}
                                            value={data["Fname"]}
                                            onChange={(e) => manualDataChangeHandler(e.target.value, "Fname", i)}
                                        />
                                        <StyledTextField
                                            label="Last Name"
                                            value={data["Lname"]}
                                            onChange={(e) => manualDataChangeHandler(e.target.value, "Lname", i)}
                                            sx={{ backgroundColor: "#E7E7E7", borderRadius: "5px" }}
                                        />
                                        <Button sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClick={(e) => { removeRow(i) }} color="error" startIcon={<DeleteIcon sx={{ mx: 0 }} />}>
                                        </Button>
                                    </Box>
                                ))
                            }
                            <Button sx={{ float: "left" }} onClick={addRowHandler} variant='contained'>Add Row</Button>
                            <Button sx={{ float: "right" }} onClick={manualDataSubmitHandler} variant='outlined' disabled={leadsLoading || !validateEmails(manualData)}>
                                {
                                    leadsLoading && <CircularProgress size={25} />
                                }
                                {
                                    !leadsLoading && "Submit"
                                }
                            </Button>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant='outlined' color='error'>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    )
}

export default Leads