import React, { useEffect, useState } from 'react'
import { Box, Snackbar, Alert, Typography, Button, Stack, IconButton, TextField, Dialog } from "@mui/material";
// import ReactDragListView from 'react-drag-listview';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EmailIcon from '@mui/icons-material/Email';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useLocation } from 'react-router-dom';

const Steps = (props) => {
    const { state } = useLocation();
    const [varientsData, setVarientsData] = React.useState(props.data)
    const [isSomethingChanged, setIsSomethingChanged] = React.useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(
        state === null ? false : state.status !== 200
    );
    const [errorMsg, seterrorMsg] = useState("");

    const [deletconfirmDialog, setDeletconfirmDialog] = React.useState(false);
    

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    
        setErrorSnackbarOpen(false);
    };

    const addVariantHandler = () => {
        // setNoOfVariants(noOfVariants + 1)
        console.log("Current length of varient array:", varientsData["varient"].length);
    
    if (varientsData["varient"].length < 10) {
        let tempVarientData = { ...varientsData }; // Create a shallow copy
        let tempEmptyJson = {
            "subject": "",
            "body": "",
            "status": "on"
        };
        tempVarientData["varient"].push(tempEmptyJson);
        setVarientsData(tempVarientData);
        handleStepDataChange(tempVarientData);
        setIsSomethingChanged(!isSomethingChanged);
    } else {
        setErrorSnackbarOpen(true);
        seterrorMsg("Maximum number of variants reached (10)");
    }
    }
    const deleteVariantHandler = (i) => {
        props.isvarientDeleted(true);
        let tempVarientData = varientsData
        tempVarientData["varient"].splice(i, 1)
        currentVarientHandler(i===0? i:i-1);
        setVarientsData(tempVarientData)
        handleStepDataChange(tempVarientData)
        setIsSomethingChanged(!isSomethingChanged)
    }

    const currentVarientHandler = (ind) => {
        if (props.index === props.selected) {
            let tempVarientData = varientsData
            tempVarientData["current_varient"] = ind + 1
            props.setCurrentVarient(ind);
            setVarientsData(tempVarientData)
            handleStepDataChange(tempVarientData)
            setIsSomethingChanged(!isSomethingChanged)
        }
    }
    const handleStepDataChange = (data) => {
        props.handleStepDataChange(data)
    }
    const handleStepChange = (index) => {
        props.handleStepChange(index)
    }
    const waitTimeChangeHandler = (e) => {
        let tempVarientData = varientsData
        if(e.target.value > 7){
            tempVarientData["wait_time"] = parseInt(7)
        }else{
            tempVarientData["wait_time"] = parseInt(e.target.value)
        }
        setVarientsData(tempVarientData)
        handleStepDataChange(tempVarientData)
        setIsSomethingChanged(!isSomethingChanged)
    }
    const handleStepDelete = (index) => {
        props.handleStepDelete(index)
    }

    const handleDrop = (event) => {
        event.preventDefault();
        const sourceIndex = parseInt(event.dataTransfer.getData("text/plain"));
        if (sourceIndex !== props.index) {
            props.handleReorder(sourceIndex, props.index);
        }
    };

    useEffect(() => {
        setVarientsData(props.data)
    }, [props.data])
  
    
    
    return (
        <>
            <Dialog
                open={deletconfirmDialog}
                onClose={() => setDeletconfirmDialog(false)}
            >
                <Box sx={{ width: "500px", textAlign: "center", p: 2 }} >
                    <Typography sx={{ fontSize: "18px", color: "#474747", fontWeight: "700", }}>Are you want to sure delete this Step? This will affect the campaign</Typography>
                    <Box sx={{ gap: '20px', display: "flex", justifyContent: "center", m: 2 }}>
                        <Button onClick={() => {
                            setDeletconfirmDialog(false)
                            handleStepDelete(props.index)
                        }}
                            sx={{ border: "1px solid", textAlign: "center", color: "#EF6D6D" }}
                        >
                            Delete
                            <DeleteForeverIcon />
                        </Button>
                        <Button onClick={() => setDeletconfirmDialog(false)}
                            sx={{ border: "1px solid", textAlign: "center", color: "#38C68B" }}
                        >Cancel</Button>
                    </Box>
                </Box>
            </Dialog>

            <Box onDragOver={(event) => event.preventDefault()} onDrop={(event) => handleDrop(event, props.index)} sx={{ width: "100%", padding: '3px', borderRadius: '16px', border: props.selected === props.index ? "1px solid #7953ff" : null }}>
                <Box sx={{boxShadow: "0 2px 11px 0px #0000001a", mb: 2, borderRadius: '12px'}}>
                    <Box
                        sx={{ p: 2, width: '100%', height: "50px", display: "flex", alignItems: "center", borderBottom: '1px solid #E7E7E7', }}
                    >
                        <EmailIcon onClick={() => handleStepChange(props.index)} sx={{ fontSize: "18px", color: "#7D7D7D", mr: 0.5 }} />
                        <Typography onClick={() => handleStepChange(props.index)} sx={{ fontWeight: '400', fontFamily: "'Inter', sans-serif", fontSize: "14px", color: "#4F4F4F", width:"90%" }}>Step {props.index + 1}</Typography>
                        <IconButton onClick={() => handleStepChange(props.index)} sx={{ ml: 'auto' }}>
                            <SettingsIcon sx={{ fontSize: "18px", color: "#7D7D7D", }} onClick={()=>props.settinghandleCliked(props.index)}/>
                        </IconButton>
                        {props.length !== 1 && <IconButton onClick={() => setDeletconfirmDialog(true)} >
                            <DeleteIcon sx={{ fontSize: "18px", color: "#7D7D7D", }} />
                        </IconButton>}
                    </Box>
                    <Box onClick={() => handleStepChange(props.index)} sx={{ p: 0, mt:1, width: "100%", height: "auto", minHeight: "55px", overflow: "auto", display: "flex", alignItems: "center", fontSize: "14px", color: "#4F4F4F",
                        "&::-webkit-scrollbar": {
                            width: "1px",
                            height: "1px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            background: "#481beb57",
                            borderRadius: "4px", 
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                            background: "gray", 
                        },
                    }}>
                        <Stack spacing={1}>
                            {
                                varientsData["varient"].map((data, i) => (
                                    <Box key={i} sx={{scrollbarWidth:"none", display:"flex", justifyContent:"center", alignItems: 'center', width:"100%", py: 1, px: 1, borderRadius: "5px", backgroundColor: varientsData["current_varient"] === i + 1 && props.index === props.selected ? "rgba(0,0,0,0.07)" : null, boxShadow: varientsData["current_varient"] === i + 1 && props.index === props.selected ? "0px 2px 11px rgba(0,0,0,0.07)" : null, cursor: "pointer", }}>

                                     <span style={{padding: '5px 10px', justifyContent: 'center', alignItems: 'center', color: '#9491AD', backgroundColor: '#F1F0FB', borderRadius: '50%' }}>
                                        {String.fromCharCode(65 + i)}
                                     </span>
                                     <Typography onClick={(e) =>
                                        currentVarientHandler(i)} sx={{width:varientsData["varient"].length !==1?"220px":"250px", overflow: "ellipses", px: 1, fontSize: '14px', fontFamily: "'Inter', sans-serif" }}>Subject:&nbsp;{data["subject"] === '' ? '<-Previous Subject->' : data["subject"]}</Typography>
                                        {varientsData["varient"].length !== 1 && <Button onClick={() => deleteVariantHandler(i)} variant="outlined"
                                            style={{
                                                maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px',
                                                border: "none", ":hover": { "border": "none" },
                                                color: "red", fontSize: "14px",
                                                textTransform: "none",
                                                fontWeight: "700",
                                                float:"right",
                                                borderRadius:"50%"
                                            }}>
                                            <DeleteIcon sx={{ fontSize: '16px' }} />
                                        </Button>}
                                    </Box>
                                ))
                            }
                        </Stack>
                    </Box>
                    <Box sx={{ justifyContent: "flex-end", width: '100%', display: "flex", alignItems: "center", }}>
                        <Button onClick={addVariantHandler} variant="standard" sx={{ py: 1, border: "none", color: "#481BEB", fontSize: "14px", textTransform: "none", fontWeight: "700",  
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        }}>
                            <AddCircleOutlineIcon sx={{ fontSize: '16px' }} />&nbsp;&nbsp;Add Varient
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ color: "rgb(199, 197, 197)", p: 2, justifyContent: "", gap:"5px", width: '100%', height: "60px", display: "flex", alignItems: "center", boxShadow: '0 2px 11px 0px #0000001a', borderRadius: '12px' }}>
                    <Stack direction='row' alignItems='center' gap='2px'>
                        <AccessTimeFilledIcon sx={{width: '16px'}} />
                        <Typography sx={{ fontSize: "14px", fontWeight: "400", fontFamily: "'Inter', sans-serif" }}>
                            Wait time
                        </Typography>
                    </Stack>
                    <TextField
                        type='number'
                        onChange={waitTimeChangeHandler}
                        onWheel={(e) => e.target.blur()}
                        value={varientsData['wait_time']}
                        sx={{ backgroundColor: '#F7F7F9',borderRadius: '4px', width: '41px', p: 0, border: 'none', "& fieldset": { p: 0, border: 'none' }, }}
                        fullWidth
                        inputProps={{
                            min:"1",
                            max:"7",
                            style: {
                                padding: '3px 8px 3px 8px',
                                fontSize: "14px",
                                fontFamily: "'Inter', sans-serif",
                            }
                        }}
                    />
                    <Typography sx={{fontSize: "14px", fontWeight: "400", fontFamily: "'Inter', sans-serif"}}> days, then </Typography>
                </Box>
            </Box>
            <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                open={errorSnackbarOpen}
                autoHideDuration={6000}
                onClose={handleErrorSnackbarClose}
            >
                <Alert
                onClose={handleErrorSnackbarClose}
                variant="filled"
                severity="error"
                sx={{ width: "100%" }}
                >
                {errorMsg}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Steps