import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  CssBaseline,
  Box,
  Container,
  Typography,
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import xCampaignLogo from "../../img/icons/xCampaignLogo.png";
import HomeButton from "../../img/icons/HomeButton.png";
import rectangle from "../../img/svg/rectangle.png";
import ring from "../../img/svg/ring.png";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const mdTheme = createTheme({
  typography: {
    fontFamily: ["HK Nova", "Inter"].join(","),
  },
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorSnackbarOpen(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackbarOpen(false);
  };

  function validatePW(str) {
    if (str.length <= 5) {
      return false;
    }

    if (!/[A-Z]/.test(str)) {
      return false;
    }
    if (!/[a-z]/.test(str)) {
      return false;
    }
    return true;
  }

  const submitHandler = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    if (validatePW(password) === true) {
      fetch(`${REACT_APP_SERVER_URL}/reset-password`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          password: password,
          username: state.token,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            navigate("/login", { state: { success: true } });
            setButtonLoading(false);
            setSuccessSnackbarOpen(true);
            return response.json();
          } else {
            setButtonLoading(false);
            setErrorSnackbarOpen(true);
            return response.json();
          }
        })
        .then((data) => {
          console.log(data);
          setErrorMessage(data.msg);
          setSuccessMessage(data.msg);
        });
    } else {
      setButtonLoading(false);
      setErrorSnackbarOpen(true);
      setErrorMessage("Enter Valid Password");
    }
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Link exact to="/" >
        <img src={HomeButton} alt="brand-logo" style={{ cursor: "pointer" }} />
      </Link>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img src={xCampaignLogo} alt='brand-logo' />
      </Box>
      <Box maxWidth='sm' sx={{ margin: '0 auto', mt: 10, display: "flex", justifyContent: "center", alignItems: "center", position: 'relative' }}>
        <Container maxWidth='xs' sx={{ zIndex: 10, backgroundColor: "#fff", p: 5, px: 4, mx: 2, boxShadow: "0px 32px 100px rgba(50, 47, 85, 0.1)", borderRadius: "40px" }}>
          <Typography sx={{ fontSize: "32px", fontWeight: "bold", fontFamily: "'HK Nova', sans-serif", mb: 4, }}>
            Set New Password
          </Typography>
          <Typography sx={{ fontSize: "14px", pb: 1, fontWeight: "600", fontFamily: "'Inter', sans-serif", }}>Password</Typography>
          <TextField
            required
            placeholder="Enter Password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              fontFamily: "'Inter', sans-serif",
              fontWeight: "400",
              fontSize: "14px",
              backgroundColor: "#f7f7f9",
              borderRadius: "8px",
              color: "#9491AD",
              mb: 3,
              "& fieldset.MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            InputProps={{
              style: { color: "#9491AD", fontSize: '14px' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />

          <Typography sx={{ fontSize: "14px", pb: 1, fontWeight: "600", fontFamily: "'Inter', sans-serif", }}>
            Confirm Password
          </Typography>
          <TextField
            required
            placeholder="Confirm Password"
            type="password"
            name="password"
            value={confirmPassword}
            fullWidth
            onChange={(e) => setConfirmPassword(e.target.value)}
            inputProps={{
              style: { color: "#9491AD", fontSize: '14px' },
            }}
            sx={{
              fontFamily: "'Inter', sans-serif",
              fontWeight: "400",
              fontSize: "14px",
              backgroundColor: "#f7f7f9",
              borderRadius: "8px",
              color: "#9491AD",
              mb: 3,
              "& fieldset.MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
          {/* <Button
                                type='submit'
                                variant='contained'
                                fullWidth
                                sx={{ mt: 1, borderRadius: "6px", textTransform: "none", fontSize: "14px", fontWeight: "700", backgroundColor: "#481BEB" }}
                            >
                                Send Reset Link
                            </Button> */}
          {/* <li>Password length must be Greater than 5</li>
          <li>Must have one uppercase character</li>
          <li>Must have one lowercase character</li>
          <br></br> */}
          {
            <Button
              type="submit"
              variant="contained"
              fullWidth
              onClick={submitHandler}
              sx={{ my: 1, py: 2, borderRadius: "6px", textTransform: "none", fontSize: "14px", fontWeight: "500", backgroundColor: "#3D17C6" }}
              disabled={buttonLoading}
            >
              {buttonLoading && <CircularProgress size={25} />}
              {!buttonLoading && "Submit"}
            </Button>
          }
        </Container>
        <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", right: "calc(100% - 15%)", top: "0", zIndex: 1 }}>
          <circle cx="23.5" cy="23.5" r="23.5" fill="#FFE163" />
        </svg>
        <img src={rectangle} alt='svg' style={{ position: "absolute", bottom: "-50px", left: "0" }} />
      </Box>
      <img src={ring} alt='svg' style={{ width: "120px", position: "absolute", bottom: '', right: "10%" }} />

      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert
          onClose={handleErrorSnackbarClose}
          variant="filled"
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert
          onClose={handleSuccessSnackbarClose}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default ResetPassword;
