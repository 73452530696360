import React from 'react'
import { Box, Stack, Grid, Typography, Button, Card, CircularProgress, Drawer, Table, TableHead, TableRow, TableBody, Snackbar, Alert, Chip } from '@mui/material'
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { ThemeProvider } from "@mui/material/styles";
import TimelineIcon from '@mui/icons-material/Timeline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import BarChartIcon from '@mui/icons-material/BarChart';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts'

import { useEffect } from 'react';
import jwt from "jwt-decode";
import cookie from "cookiejs";
import { StyledTableCell, StyledTableContainer, StyledTableRow, StyledTypography, colorTheme } from '../../stylings/styles';


// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: "rgb(217, 219, 222)",
//         fontSize: "12px",
//         fontWeight: "700"
//     },
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 14,
//     },
// }));
// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     // hide last border
//     '&:last-child td, &:last-child th': {
//         border: 0,
//     },
//     '&:nth-of-type(even)': {
//         backgroundColor: "rgba(217, 219, 222,0.4)"
//     },
// }));

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Analytics = () => {

    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(null)
    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(null);
    const [analyticsUrl, setAnalyticsURL] = React.useState("/campaign_analytics");
    const user = jwt(cookie.get("token"));
    const [drawerData, setDrawerData] = React.useState(null);
    const [analyticsLoading, setAnalyticsLoading] = React.useState(false);
    const [campaignData, setCampaigndata] = React.useState([]);
    const [accountData, setAccountdata] = React.useState([]);
    const [toggleSideBar, setToggleSideBar] = React.useState(false);
    const [toggle, setToggle] = React.useState(true);
    const [pageLoaded, setPageLoaded] = React.useState(false);
    const [drawerLoaded, setDrawerLoaded] = React.useState(true);
    const [pageData, setPageData] = React.useState({});
    const [graphArray, setGraphArray] = React.useState([]);
    const [graphArrayCampaign, setGraphArrayCampaign] = React.useState([]);
    const [filteredGraphArray, setFilteredGraphArray] = React.useState([]);
    const [filteredGraphArrayCampaign, setFilteredGraphArrayCampaign] = React.useState([]);
    const skipCount = 3;

    useEffect(() => {
        const graphData = [graphArray];
        const graphDataCampaign = [graphArrayCampaign];

        setGraphArray(graphData);
        setGraphArrayCampaign(graphDataCampaign);
    }, []);

    const skipData = (data) => {
        return data?.filter((entry, index) => index % skipCount === 0);
    };

    useEffect(() => {
        const filteredData = skipData(graphArray);
        setFilteredGraphArray(filteredData);
    }, [graphArray]);

    useEffect(() => {
        const filteredDataCampaign = skipData(graphArrayCampaign);
        setFilteredGraphArrayCampaign(filteredDataCampaign);
    }, [graphArrayCampaign]);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorSnackbarOpen(false);
    }
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessSnackbarOpen(false);
    }

    useEffect(() => {
        setPageLoaded(false);
        fetch(`${REACT_APP_SERVER_URL}/get_graph`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "email": user.email
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else if (response.status === 202) {
                setPageLoaded(true);
                setErrorMessage(response.msg);
                setErrorSnackbarOpen(true)
                console.log("token expired")
            } else {
                setErrorMessage(response.msg);
                setErrorSnackbarOpen(true)
                console.log("some error")
            }
        })
            .then(data => {
                setPageData(data);
                var result = [];
                for (var i in data.data) {
                    result.push(data.data[i]);
                }
                console.log(result);
                setGraphArray(result);
                setPageLoaded(true);
            })
            .catch(error => console.log(error))
    }, []);

    useEffect(() => {
        setAnalyticsLoading(true)
        fetch(`${REACT_APP_SERVER_URL}${analyticsUrl}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "email": user.email
            })
        }).then(response => {
            if (response.status === 200) {
                setAnalyticsLoading(false)
                return response.json()
            }
            else if (response.status === 202) {
                setErrorMessage(response.msg);
                setErrorSnackbarOpen(true)
                console.log("token expired")
            }
            else {
                setErrorMessage(response.msg);
                setErrorSnackbarOpen(true)
                console.log("some error")
            }
        })
            .then(data => {
                // setSuccessMessage(data.msg);
                // setSuccessSnackbarOpen(true);
                // console.warn("this is new ", data);
                if (analyticsUrl === "/campaign_analytics") {
                    setCampaigndata(data.data)
                } else {
                    setAccountdata(data.data)
                }
            })
            .catch(error => console.log(error))
    }, [analyticsUrl]);

    var handleOnClickDrawer = (id) => {
        setDrawerLoaded(false)
        setToggleSideBar(true);
        fetch(`${REACT_APP_SERVER_URL}/get_graph_campaign`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "campaign": {
                    "campaign_id": id
                }
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json()
            } else if (response.status === 202) {
                // setDrawerLoaded(true);
                setErrorMessage(response.msg);
                setErrorSnackbarOpen(true)
                console.log("token expired")
            } else {
                setErrorMessage(response.msg);
                setErrorSnackbarOpen(true)
                console.log("some error")
            }
        })
            .then(data => {
                console.log(data);
                setGraphArrayCampaign(data.data)
                setDrawerLoaded(true);
                return;
            })
            .catch(error => console.log(error))
    }
    return (
        <ThemeProvider theme={colorTheme}>
            {
                !drawerLoaded &&
                <Drawer
                    anchor={'right'}
                    open={toggleSideBar}
                    onClose={() => setToggleSideBar(false)}
                >
                    <Box sx={{ display: "flex", justifyContent: 'center', width: "900px", p: 4 }}>
                        <CircularProgress />
                    </Box>
                </Drawer>
            }
            {
                drawerLoaded &&
                <Drawer
                    anchor={'right'}
                    open={toggleSideBar}
                    onClose={() => setToggleSideBar(false)}
                >
                    <Box sx={{ width: "900px" }}>
                        <Box sx={{ width: "100%", pt: 4 }}>
                            <Box sx={{ width: "100%", textAlign: "center", fontSize: "26px", color: "green", textShadow: "2px 2px 5px yellow" }}>
                                {drawerData && drawerData.name}
                            </Box>
                            <Grid container columnSpacing={2} sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 1, mr: 5 }}>
                                <Grid item sx={{ width: "90%", borderRadius: "10px", paddingTop: "50px", marginBottom: 4, p: 3, backgroundColor: "#fafafa", boxShadow: "5px 5px 10px #f2f0f0", border: "1px solid #e7e7e7", }}>
                                    <ResponsiveContainer width="100%" aspect={2}>
                                        <LineChart data={filteredGraphArrayCampaign}
                                            width={700}
                                            height={300}
                                        >
                                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                                            <XAxis dataKey={"date"} />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend verticalAlign='top' align='right' iconType='square' iconSize={8} />
                                            <Line type="monotone" dataKey={"sent_total"} stroke='#7860DA' strokeWidth={2} activeDot={{ r: 4 }} />
                                            <Line type="monotone" dataKey={"open_count"} stroke="#38C68B" strokeWidth={2} activeDot={{ r: 4 }} />
                                            {/* <Line type="monotone" dataKey={"unique_opens"} stroke="#9AD26D" strokeWidth={2} activeDot={{ r: 4 }} />
                                            <Line type="monotone" dataKey={"reply_total"} stroke="#35BCF7" strokeWidth={2} activeDot={{ r: 4 }} />
                                            <Line type="monotone" dataKey={"bounce_count"} stroke="#eb4034" strokeWidth={2} activeDot={{ r: 4 }} /> */}
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Grid>
                                <Grid item sx={{ width: "90%", p: 1 }}>
                                    <Box sx={{ width: "100%", height: "100%", backgroundColor: "#fafafa", boxShadow: "5px 5px 10px #f2f0f0", borderRadius: '10px', p: 4 }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: "700", pb: 1 }}>Other Information</Typography>
                                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Card sx={{ width: "15x0px", height: "76px", boxShadow: "none", borderRadius: "10px", display: "flex", justifyContent: 'center', alignItems: "center", px: 2, my: 1 }}>
                                                <Grid container>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Typography sx={{ width: "100px", fontSize: "12px", color: "#7D7D7D" }}>Sent</Typography>
                                                        <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>{drawerData && drawerData.analytics.sent_total}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", justifyContent: 'end' }}>
                                                        <Box sx={{ backgroundColor: "rgba(56, 198, 139, 0.1)", borderRadius: "8px", height: '40px', width: "40px", display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", }}>
                                                            <TrendingDownIcon sx={{ fontSize: '12px', color: "#38C68B" }} />
                                                            <Typography sx={{ fontSize: "10px", fontWeight: "500", color: "#38C68B" }}>1.1%</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                            <Card sx={{ width: "15x0px", height: "76px", boxShadow: "none", borderRadius: "10px", display: "flex", justifyContent: 'center', alignItems: "center", px: 2, my: 1 }}>
                                                <Grid container>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Typography sx={{ width: "100px", fontSize: "12px", color: "#7D7D7D" }}>Opened</Typography>
                                                        <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>{drawerData && drawerData.analytics.unique_opens}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", justifyContent: 'end' }}>
                                                        <Box sx={{ backgroundColor: "rgba(56, 198, 139, 0.1)", borderRadius: "8px", height: '40px', width: "40px", display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", }}>
                                                            <TrendingDownIcon sx={{ fontSize: '12px', color: "#38C68B" }} />
                                                            <Typography sx={{ fontSize: "10px", fontWeight: "500", color: "#38C68B" }}>1.1%</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                            <Card sx={{ width: "15x0px", height: "76px", boxShadow: "none", borderRadius: "10px", display: "flex", justifyContent: 'center', alignItems: "center", px: 2, my: 1 }}>
                                                <Grid container>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Typography sx={{ width: "100px", fontSize: "12px", color: "#7D7D7D" }}>Reply</Typography>
                                                        <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>{drawerData && drawerData.analytics.reply_count}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", justifyContent: 'end' }}>
                                                        <Box sx={{ backgroundColor: "rgba(56, 198, 139, 0.1)", borderRadius: "8px", height: '40px', width: "40px", display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", }}>
                                                            <TrendingDownIcon sx={{ fontSize: '12px', color: "#38C68B" }} />
                                                            <Typography sx={{ fontSize: "10px", fontWeight: "500", color: "#38C68B" }}>1.1%</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                            <Card sx={{ width: "15x0px", height: "76px", boxShadow: "none", borderRadius: "10px", display: "flex", justifyContent: 'center', alignItems: "center", px: 2, my: 1 }}>
                                                <Grid container>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Typography sx={{ width: "100px", fontSize: "12px", color: "#7D7D7D" }}>Unique Opened</Typography>
                                                        <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>{drawerData && drawerData.analytics.open_count}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", justifyContent: 'end' }}>
                                                        <Box sx={{ backgroundColor: "rgba(56, 198, 139, 0.1)", borderRadius: "8px", height: '40px', width: "40px", display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", }}>
                                                            <TrendingDownIcon sx={{ fontSize: '12px', color: "#38C68B" }} />
                                                            <Typography sx={{ fontSize: "10px", fontWeight: "500", color: "#38C68B" }}>1.1%</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Drawer>
            }

            {
                !pageLoaded &&
                <Box sx={{ display: "flex", justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            }
            {
                pageLoaded &&
                <Box sx={{ backgroundColor: "#fff", borderRadius: 3 }}>
                    <Stack direction='row' spacing={1} alignItems='center' sx={{ my: 2 }}>
                        <StyledTypography variant='h1' sx={{ fontSize: '2rem', }}>CAMPAIGN</StyledTypography>
                        {/* <Chip sx={{ fontWeight: "600", padding: '0 10px', fontSize: '12px', fontFamily: "'Inter', sans-serif", color: '#fff', backgroundColor: "#38C68B" }}  label={'Active'} /> */}
                    </Stack>
                    {/* <Stack direction='row' spacing={2} alignItems='center' sx={{my:2}}>
                        <StyledTypography variant='h1' sx={{fontSize: '2rem',}}>London CMO List-Apollo</StyledTypography>
                        <Stack direction='row' spacing={1} alignItems='center'>
                            <PauseCircleIcon sx={{ color: "#989898", fontSize: '20px', position: 'relative', top: '2px' }} />
                                <img src={expandIcon} alt="icon" sx={{ width: '20px', position: 'relative', top: '2px' }} />
                            <MoreHorizOutlinedIcon sx={{ color: "#989898", fontSize: '20px', position: 'relative', top: '2px' }} />
                        </Stack>
                    </Stack>
                    <Stack direction='row' spacing={1} alignItems='center' justifyContent='center' sx={{ borderRadius: "4px", p:1, width: "250px", my: 3, color: "#4F4F4F", backgroundColor: '#F7F7F9'}}>
                        <CalendarTodayOutlinedIcon sx={{ color: "#4F4F4F", fontSize: '20px', position: 'relative', top: '-2px' }} />
                        <StyledTypography>Jan 6, 2022 - Jan 13, 2022</StyledTypography>
                    </Stack> */}
                    <Typography>{JSON.stringify()}</Typography>

                    <Box sx={{ width: '100%', height: "auto", my: 3, display: "flex", flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap', gap: '10px' }}>
                        <Card sx={{ width: "200px", my: 1, boxShadow: "0 2px 11px 0px #0000001a", borderRadius: '20px', p: 2, }}>
                            <Typography align='center' sx={{ fontWeight: '500', fontSize: '14px' }}> Sent</Typography>
                            <Box sx={{ position: 'relative', display: 'flex', justifyContent: "center" }}>
                                <CircularProgress variant="determinate" value={(457100 / 5000)} size={100} thickness={5} sx={{ color: "#7860DA", my: 2 }} />
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: "16px", fontWeight: "700" }}>
                                        {pageData["completeData"]["sent_total"]}
                                    </Typography>
                                </Box>
                            </Box>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' gap='10px' sx={{ color: '#989898', fontSize: '12px', fontFamily: "'Inter', sans-serif" }}>
                                <Stack direction='row' alignItems='center' gap='5px' >
                                    <span>Pending</span>
                                    <ErrorOutlineOutlinedIcon sx={{ color: "#989898", fontSize: '16px', }} />
                                </Stack>
                                <span>172</span>
                            </Stack>
                        </Card>
                        <Card sx={{ width: "200px", my: 1, boxShadow: "0 2px 11px 0px #0000001a", borderRadius: '20px', p: 2, }}>
                            <Typography align='center' sx={{ fontWeight: '500', fontSize: '14px' }}>Delivered</Typography>
                            <Box sx={{ position: 'relative', display: 'flex', justifyContent: "center" }}>
                                <CircularProgress variant="determinate" value={(457100 / 5000)} size={100} thickness={5} sx={{ color: "#38C68B", my: 2 }} />
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: "16px", fontWeight: "700" }}>
                                        {pageData["completeData"]["open_count"]}
                                    </Typography>
                                </Box>
                            </Box>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' gap='10px' sx={{ color: '#989898', fontSize: '12px', fontFamily: "'Inter', sans-serif" }}>
                                <Stack direction='row' alignItems='center' gap='5px' >
                                    <span>Undelivered Email</span>
                                    <ErrorOutlineOutlinedIcon sx={{ color: "#989898", fontSize: '16px', }} />
                                </Stack>
                                <span>172</span>
                            </Stack>
                        </Card>
                        <Card sx={{ width: "200px", my: 1, boxShadow: "0 2px 11px 0px #0000001a", borderRadius: '20px', p: 2, }}>
                            <Typography align='center' sx={{ fontWeight: '500', fontSize: '14px' }}>Opens</Typography>
                            <Box sx={{ position: 'relative', display: 'flex', justifyContent: "center" }}>
                                <CircularProgress variant="determinate" value={(457100 / 5000)} size={100} thickness={5} sx={{ color: "#F36C8B", my: 2 }} />
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: "16px", fontWeight: "700" }}>
                                        {pageData["completeData"]["unique_opens"]}
                                    </Typography>
                                </Box>
                            </Box>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' gap='10px' sx={{ color: '#989898', fontSize: '12px', fontFamily: "'Inter', sans-serif" }}>
                                <Stack direction='row' alignItems='center' gap='5px' >
                                    <span>Unique Open</span>
                                    <ErrorOutlineOutlinedIcon sx={{ color: "#989898", fontSize: '16px', }} />
                                </Stack>
                                <span>172</span>
                            </Stack>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' gap='10px' sx={{ color: '#989898', fontSize: '12px', fontFamily: "'Inter', sans-serif" }}>
                                <Stack direction='row' alignItems='center' gap='5px' >
                                    <span>Unique Open Rate</span>
                                    <ErrorOutlineOutlinedIcon sx={{ color: "#989898", fontSize: '16px', }} />
                                </Stack>
                                <span>50%</span>
                            </Stack>
                        </Card>
                        <Card sx={{ width: "200px", my: 1, boxShadow: "0 2px 11px 0px #0000001a", borderRadius: '20px', p: 2, }}>
                            <Typography align='center' sx={{ fontWeight: '500', fontSize: '14px' }}>Click</Typography>
                            <Box sx={{ position: 'relative', display: 'flex', justifyContent: "center" }}>
                                <CircularProgress variant="determinate" value={(457100 / 5000)} size={100} thickness={5} sx={{ color: "#35BCF7", my: 2 }} />
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: "16px", fontWeight: "700" }}>
                                        {pageData["completeData"]["reply_total"]}
                                    </Typography>
                                </Box>
                            </Box>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' gap='10px' sx={{ color: '#989898', fontSize: '12px', fontFamily: "'Inter', sans-serif" }}>
                                <Stack direction='row' alignItems='center' gap='5px' >
                                    <span>Click</span>
                                    <ErrorOutlineOutlinedIcon sx={{ color: "#989898", fontSize: '16px', }} />
                                </Stack>
                                <span>172</span>
                            </Stack>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' gap='10px' sx={{ color: '#989898', fontSize: '12px', fontFamily: "'Inter', sans-serif" }}>
                                <Stack direction='row' alignItems='center' gap='5px' >
                                    <span>Unique Click Rate</span>
                                    <ErrorOutlineOutlinedIcon sx={{ color: "#989898", fontSize: '16px', }} />
                                </Stack>
                                <span>50%</span>
                            </Stack>
                        </Card>
                        <Card sx={{ width: "200px", my: 1, boxShadow: "0 2px 11px 0px #0000001a", borderRadius: '20px', p: 2, }}>
                            <Typography align='center' sx={{ fontWeight: '500', fontSize: '14px', }}>Conversation</Typography>
                            <Box sx={{ position: 'relative', display: 'flex', justifyContent: "center" }}>
                                <CircularProgress variant="determinate" value={(457100 / 5000)} size={100} sx={{ color: "#94D26D", my: 2 }} thickness={5} />
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: "16px", fontWeight: "700" }}>
                                        {pageData["completeData"]["bounce_count"]}
                                    </Typography>
                                </Box>
                            </Box>
                            <Stack direction='row' justifyContent='space-around' alignItems='center' sx={{ px: 1, overFlow: 'hidden' }}>
                                <BarChartIcon sx={{ color: "#94D26D", fontSize: '32px', }} />
                                <SignalCellularAltIcon sx={{ color: "#94D26D", fontSize: '32px', }} />
                                <BarChartIcon sx={{ color: "#94D26D", fontSize: '32px', }} />
                                <SignalCellularAltIcon sx={{ color: "#94D26D", fontSize: '32px', }} />
                                <BarChartIcon sx={{ color: "#94D26D", fontSize: '32px', }} />
                                <SignalCellularAltIcon sx={{ color: "#94D26D", fontSize: '32px', }} />
                            </Stack>
                        </Card>
                    </Box>

                    <Box sx={{ width: "100%", p: 2, my: 3 }}>
                        <Grid container columnSpacing={2} sx={{ display: "flex" }}>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ borderRadius: "32px", paddingTop: "50px", boxShadow: "0 2px 11px 0px #0000001a", p: 3 }}>
                                <ResponsiveContainer width="100%" height='100%'>
                                    <LineChart data={filteredGraphArray} >
                                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                                        <XAxis dataKey={"date"} />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend verticalAlign='top' align='right' iconType='square' iconSize={8} />
                                        <Line type="monotone" dataKey={"sent_total"} stroke='#7860DA' strokeWidth={2} activeDot={{ r: 4 }} />
                                        <Line type="monotone" dataKey={"open_count"} stroke="#38C68B" strokeWidth={2} activeDot={{ r: 4 }} />
                                        {/* <Line type="monotone" dataKey={"unique_opens"} stroke="#9AD26D" strokeWidth={2} activeDot={{ r: 4 }} />
                                        <Line type="monotone" dataKey={"reply_total"} stroke="#35BCF7" strokeWidth={2} activeDot={{ r: 4 }} />
                                        <Line type="monotone" dataKey={"bounce_count"} stroke="#eb4034" strokeWidth={2} activeDot={{ r: 4 }} /> */}
                                    </LineChart>
                                </ResponsiveContainer>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Box sx={{ width: "100%", backgroundColor: "#F7F7F9", borderRadius: '32px', p: 4 }}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: "700", mb: 1 }}>Other Information</Typography>
                                    {
                                        [1, 2].map((ind) => (
                                            <Card key={ind} sx={{ height: "76px", boxShadow: "none", borderRadius: "10px", display: "flex", justifyContent: 'center', alignItems: "center", px: 2, my: 1 }}>
                                                <Grid container>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Typography sx={{ fontSize: "12px", color: "#7D7D7D" }}>Unsubscribe</Typography>
                                                        <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>254</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", justifyContent: 'end' }}>
                                                        <Box sx={{ backgroundColor: "rgba(56, 198, 139, 0.1)", borderRadius: "8px", height: '40px', width: "40px", display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", }}>
                                                            <TrendingDownIcon sx={{ fontSize: '12px', color: "#38C68B" }} />
                                                            <Typography sx={{ fontSize: "10px", fontWeight: "500", color: "#38C68B" }}>1.1%</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        ))
                                    }
                                    <Card sx={{ height: "76px", boxShadow: "none", borderRadius: "10px", display: "flex", justifyContent: 'center', alignItems: "center", px: 2, my: 1 }}>
                                        <Grid container>
                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <Typography sx={{ fontSize: "12px", color: "#7D7D7D" }}>Complain</Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>254</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", justifyContent: 'end' }}>
                                                <Box sx={{ backgroundColor: "rgba(243, 108, 139, 0.1)", borderRadius: "8px", height: '40px', width: "40px", display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", }}>
                                                    <TrendingDownIcon sx={{ fontSize: '12px', color: "#F36C8B" }} />
                                                    <Typography sx={{ fontSize: "10px", fontWeight: "500", color: "#F36C8B" }}>1.1%</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                    <Card sx={{ height: "76px", boxShadow: "none", borderRadius: "10px", display: "flex", justifyContent: 'center', alignItems: "center", px: 2, my: 1 }}>
                                        <Grid container>
                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <Typography sx={{ fontSize: "12px", color: "#7D7D7D" }}>Blocked</Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>254</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", justifyContent: 'end' }}>
                                                <Box sx={{ backgroundColor: "rgba(56, 198, 139, 0.1)", borderRadius: "8px", height: '40px', width: "40px", display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", }}>
                                                    <TrendingDownIcon sx={{ fontSize: '12px', color: "#38C68B" }} />
                                                    <Typography sx={{ fontSize: "10px", fontWeight: "500", color: "#38C68B" }}>1.1%</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ width: "100%", m: 3 }}>
                            <Button onClick={() => {
                                setToggle(true);
                                setAnalyticsURL("/campaign_analytics");
                            }} sx={{ color: toggle ? "blue" : "grey", borderRadius: "1px", borderBottom: toggle ? "3px solid blue" : "none" }}><TimelineIcon />&nbsp;Campaign Analytics</Button>
                            <Button onClick={() => {
                                setToggle(false)
                                setAnalyticsURL("/sender_analytics");
                            }} sx={{ color: !toggle ? "blue" : "grey", borderRadius: "1px", borderBottom: !toggle ? "3px solid blue" : "none" }}><TimelineIcon />&nbsp;Account Analytics</Button>
                        </Box>
                        {

                            analyticsLoading ?
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", height: "500px" }}>
                                    <CircularProgress />
                                </Box> :
                                toggle ?
                                    <Box>
                                        <StyledTableContainer>
                                            <Table sx={{ minWidth: 650, borderCollapse: "separate", borderSpacing: "0 10px", }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align='center'>CAMPAIGN</StyledTableCell>
                                                        <StyledTableCell align='center'>STATUS</StyledTableCell>
                                                        <StyledTableCell align='center'>CONTACTED</StyledTableCell>
                                                        <StyledTableCell align='center'>OPENED</StyledTableCell>
                                                        <StyledTableCell align='center'>REPLY</StyledTableCell>
                                                        <StyledTableCell align='center'>UNIQUE OPENS</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {campaignData.data && campaignData.data.map((campaignValues, i) =>
                                                        <StyledTableRow key={i} onClick={() => {
                                                            handleOnClickDrawer(campaignValues['campaign_id']);
                                                            setDrawerData(campaignValues)
                                                        }} hover sx={{ cursor: "pointer" }} >
                                                            <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>{campaignValues.name}</StyledTableCell>
                                                            <StyledTableCell align='center' sx={{
                                                                fontSize: "14px", fontWeight: '700'

                                                            }}>
                                                                {
                                                                    campaignValues.analytics &&
                                                                    <Chip
                                                                        sx={{
                                                                            fontSize: "700", width: '90px', color: "#fff",
                                                                            backgroundColor: campaignValues['status'] === 'active' ? '#38C68B' :
                                                                                campaignValues['status'] === 'paused' ? '#9491AD' :
                                                                                    campaignValues['status'] === 'draft' ? '#FFC107' :
                                                                                        campaignValues['status'] === 'Error' ? '#EF6D6D' :
                                                                                            '#38C68B',
                                                                        }}
                                                                        label={campaignValues.status}
                                                                        color={
                                                                            campaignValues['status'] === 'active' ? 'primary' :
                                                                                campaignValues['status'] === 'paused' ? 'default' :
                                                                                    campaignValues['status'] === 'draft' ? 'default' :
                                                                                        campaignValues['status'] === 'Error' ? 'error' :
                                                                                            'success'
                                                                        }
                                                                    />
                                                                }
                                                            </StyledTableCell>
                                                            {/* here is a problem we have swaped the unique count with open count */}
                                                            <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>{campaignValues.analytics && campaignValues.analytics.sent_total}</StyledTableCell>
                                                            <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>{campaignValues.analytics && campaignValues.analytics.open_count}</StyledTableCell>
                                                            <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>{campaignValues.analytics && campaignValues.analytics.reply_count}</StyledTableCell>
                                                            <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>{campaignValues.analytics && campaignValues.analytics.unique_opens}</StyledTableCell>
                                                        </StyledTableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </StyledTableContainer>
                                    </Box> :
                                    <Box>
                                        <StyledTableContainer>
                                            <Table sx={{ minWidth: 650, borderCollapse: "separate", borderSpacing: "0 10px", }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align='center'>SENDER EMAIL</StyledTableCell>
                                                        <StyledTableCell align='center'>CONTACTED</StyledTableCell>
                                                        <StyledTableCell align='center'>OPENED</StyledTableCell>
                                                        <StyledTableCell align='center'>REPLIED</StyledTableCell>
                                                        <StyledTableCell align='center'>UNIQUE OPEN</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {accountData.data && accountData.data.map((data, i) =>
                                                        <StyledTableRow key={i} >
                                                            <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>{accountData && data.email}</StyledTableCell>
                                                            <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>{accountData && data.analytics && data.analytics.sent_total}</StyledTableCell>
                                                            <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>{accountData && data.analytics && data.analytics.open_count}</StyledTableCell>
                                                            <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>{accountData && data.analytics && data.analytics.reply_count}</StyledTableCell>
                                                            <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>{accountData && data.analytics && data.analytics.unique_opens}</StyledTableCell>
                                                        </StyledTableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </StyledTableContainer>
                                    </Box>
                        }
                    </Box>
                </Box>
            }

            <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    )
}

export default Analytics