import React from 'react'
import {
    Box, Typography, Table, TableBody,
    TableHead, TableRow, CircularProgress, Card, Grid
} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import SendIcon from "@mui/icons-material/Send";
import ReplyIcon from "@mui/icons-material/Reply";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { FileOpen } from '@mui/icons-material';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { StyledTableCell, StyledTableContainer, StyledTableRow } from '../../stylings/styles';


const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: "#FAFAFA",
//         fontSize: "12px",
//         fontWeight: "700"
//     },
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 14,
//     },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     // hide last border
//     '&:last-child td, &:last-child th': {
//         border: 0,
//         fontWeight:"700"
//     },
// }));

const Analytics = (props) => {
    const navigate = useNavigate()
    const [analyticsData, setAnalyticsData] = React.useState({})
    const [pageLoaded, setPageLoaded] = React.useState(false);
    React.useEffect(() => {
        fetch(`${REACT_APP_SERVER_URL}/get_analytics`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "campaign": {
                    'campaign_id': props.id
                }
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else if (response.status === 202) {
                console.log("token expired")
                console.log("token expired")
                navigate('/login', { state: { sessionExpired: true } })
            }
            else {
                console.log("some error")
            }
        })
            .then(data => {
                setAnalyticsData(data['data'])
                setPageLoaded(true);
            })
            .catch(error => console.log(error))
    }, [])

    return (
        <>
            {
                !pageLoaded &&
                <Box sx={{ display: "flex", justifyContent: 'center', mt: 2 }}>
                    <CircularProgress />
                </Box>
            }
            {
                pageLoaded &&
                <Grid container sx={{ backgroundColor: "#fff", borderRadius: 3, }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ height: "auto", display: "flex", flexDirection: 'column', p: 2 }}>
                        <Box sx={{ width: '100%', height: "auto", mt: 2, display: "flex", flexDirection: 'row' }}>
                            <Card sx={{ width: "50%", borderRadius: "10px", boxShadow: "0 2px 11px 0px #0000001a", p: 2, mx: 2 }}>
                                <Typography align='center' sx={{ fontWeight: '600' }}>Total Sent</Typography>
                                <Box sx={{ position: 'relative', display: 'flex', justifyContent: "center" }}>
                                    <CircularProgress variant="determinate" value={(457100 / 5000)} size={100} thickness={5} sx={{ color: "#7860DA", my: 2 }} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: "16px", fontWeight: "700" }}>
                                            {analyticsData["total"]["sent"]}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <Card sx={{ width: "50%", borderRadius: "10px", boxShadow: "0 2px 11px 0px #0000001a", p: 2, mx: 2 }}>
                                <Typography align='center' sx={{ fontWeight: '600' }}>Opened</Typography>
                                <Box sx={{ position: 'relative', display: 'flex', justifyContent: "center" }}>
                                    <CircularProgress variant="determinate" value={(457100 / 5000)} size={100} thickness={5} sx={{ color: "#38C68B", my: 2 }} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: "16px", fontWeight: "700" }}>
                                            {analyticsData["total"]["opened"]}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                        <Box sx={{ width: '100%', height: "auto", mt: 2, display: "flex", flexDirection: 'row' }}>
                            <Card sx={{ width: "50%", borderRadius: "10px", boxShadow: "0 2px 11px 0px #0000001a", p: 2, mx: 2 }}>
                                <Typography align='center' sx={{ fontWeight: '600' }}>Replied</Typography>
                                <Box sx={{ position: 'relative', display: 'flex', justifyContent: "center" }}>
                                    <CircularProgress variant="determinate" value={(457100 / 5000)} size={100} thickness={5} sx={{ color: "#35BCF7", my: 2 }} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: "16px", fontWeight: "700" }}>
                                            {analyticsData["total"]["replied"]}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <Card sx={{ width: "50%", borderRadius: "10px", boxShadow: "0 2px 11px 0px #0000001a", p: 2, mx: 2 }}>
                                <Typography align='center' sx={{ fontWeight: '600' }}>Bounced</Typography>
                                <Box sx={{ position: 'relative', display: 'flex', justifyContent: "center" }}>
                                    <CircularProgress variant="determinate" value={(457100 / 5000)} size={100} sx={{ color: "#eb4034", my: 2 }} thickness={5} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: "16px", fontWeight: "700" }}>
                                            {analyticsData["total"]["bounced"]}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <StyledTableContainer sx={{ borderRadius: '10px', px: 2 }}>
                            <Table sx={{ borderCollapse: "separate", borderSpacing: "0 10px", }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>STEPS</StyledTableCell>
                                        <StyledTableCell>SENT</StyledTableCell>
                                        <StyledTableCell>OPENED</StyledTableCell>
                                        <StyledTableCell>REPLIED</StyledTableCell>
                                        <StyledTableCell>BOUNCED</StyledTableCell>
                                        <StyledTableCell>UNIQUE OPENS</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.keys(analyticsData).map((indexType, i) => (
                                            <StyledTableRow key={i} sx={{
                                                '&:hover': {
                                                    backgroundColor: '#F4F4F9',
                                                },
                                            }}>
                                                <StyledTableCell sx={{ whiteSpace: 'nowrap', }}>{indexType === 'total' ? "Total" : `Step ${indexType}`}</StyledTableCell>
                                                <StyledTableCell sx={{ whiteSpace: 'nowrap', }}>
                                                    <SendIcon
                                                        sx={{
                                                            fontSize: "20px",
                                                            color: "grey",
                                                            mr: "1px",
                                                            transform:
                                                                "rotate(-45deg)translateY(-0.0rem)",
                                                        }}>
                                                    </SendIcon>
                                                    {analyticsData[indexType]['sent']}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ transform: 'translateX(0.25rem)' }}>
                                                    <FileOpen
                                                        sx={{
                                                            fontSize: "20px",
                                                            color: "grey",
                                                            mr: "5px",
                                                            transform: 'translateY(0.25rem)'
                                                        }}
                                                    ></FileOpen>
                                                    {analyticsData[indexType]['opened']}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ transform: 'translateX(0.25rem)' }}>
                                                    <ReplyIcon
                                                        sx={{
                                                            fontSize: "20px",
                                                            color: "grey",
                                                            mr: "5px",
                                                            transform: 'translateY(0.25rem)'
                                                        }}>
                                                    </ReplyIcon>
                                                    {analyticsData[indexType]['replied']}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ transform: 'translateX(0.75rem)' }}>
                                                    <ThumbDownOffAltIcon
                                                        sx={{
                                                            fontSize: "20px",
                                                            color: "grey",
                                                            mr: "5px",
                                                            transform: 'translateY(0.25rem)'
                                                        }}
                                                    ></ThumbDownOffAltIcon>
                                                    {analyticsData[indexType]['bounced']}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ transform: 'translateX(1.5rem)' }}>
                                                    <LockOpenIcon
                                                        sx={{
                                                            fontSize: "20px",
                                                            color: "grey",
                                                            transform: 'translateY(0.25rem)'
                                                        }}
                                                    ></LockOpenIcon>
                                                    {analyticsData[indexType]['unique_opens']}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    }

                                </TableBody>
                            </Table>
                        </StyledTableContainer>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default Analytics