
import { useState } from 'react';
import React from 'react'

import InfiniteScroll from 'react-infinite-scroll-component';
import { RemoveScrollBar } from 'react-remove-scroll-bar';
import cookie from 'cookiejs';
import jwt from 'jwt-decode';


import { Box, Button, CircularProgress, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material'
import { StyledTableCell, StyledTableContainer, StyledTableRow } from '../../stylings/styles'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DraftsIcon from '@mui/icons-material/Drafts';



const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Invoices = () => {
  const user = jwt(cookie.get("token"));
  const [InvoicesLoading, setInvoicesLoading] = useState(false);
  const [allInvoices, setAllInvoices] = useState([]);
  const [lastpage, setLastpage] = useState();
  const [DataLoading, setDataLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState();
  const [hasMore, sethasMore] = useState(true);
  const [page, setPage] = useState(2);

  React.useEffect(() => {
    invoices();
  }, [])

  function invoices() {
    // setCheckInvoives(true);
    // setCheckPlans(false);
    setInvoicesLoading(true);
    fetch(`${REACT_APP_SERVER_URL}/invoices/?page=1`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include",
      method: 'GET',
      headers: { 'email': user.email }
    }).then(response => {
      return response.json();
    })
      .then(data => {
        console.log(data);
        if (data.status === 401) {
          // setErrorMessage(data.msg);
          // setErrorSnackbarOpen(true);
          setInvoicesLoading(false);
          return;
        }
        setInvoicesLoading(false);
        setAllInvoices(data.invoices);
        setLastpage(data.last_page);
      })
      .catch(error => console.log(error))
  }

  function Invoices() {
    setDataLoading(true);
    fetch(`${REACT_APP_SERVER_URL}/invoices/?page=${page}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include",
      method: 'GET',
      headers: { 'email': user.email }
    }).then(response => {
      return response.json();
    })
      .then(data => {
        setDataLoading(false);
        console.log(data);
        let temp = allInvoices.concat(data.invoices);
        setAllInvoices(temp);
        setCurrentPage(data.current_page);

      })
      .catch(error => console.log(error))
  }

  async function infinteScroll(e) {
    console.log(document.documentElement.scrollHeight);
  }

  function load() {
    if (currentPage === lastpage) {
      sethasMore(false);
      return;
    }
    else {
      setPage(page + 1);
      Invoices();
    }

  }

  window.addEventListener("scroll", infinteScroll)

  function Date(data) {
    let a = data;
    let b = `${a[8] + a[9]}-${a[5] + a[6]}-${a[0] + a[1] + a[2] + a[3]}`;

    return b;
  }

  function redirect(data) {
    if (data === null) {
      // setErrorSnackbarOpen(true);
      // setErrorMessage('Status is not paid')
    }
  }

  const customScrollbarStyle = {
    scrollbarWidth: 'thin',
    scrollbarColor: '#D0CED5 transparent',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D0CED5',
      borderRadius: '6px',
    },
  };

  return (
    <section>
      <Box>
        <Box sx={{ marginTop: '10px', height: "100%", }}>
          {InvoicesLoading ?
            <Box sx={{ width: "100%", display: "center", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
            :

            <InfiniteScroll id='scroll' dataLength={allInvoices.length} next={load} hasMore={hasMore} height={1000} style={customScrollbarStyle} >
              {
                allInvoices.length !== 0 &&
                <Box>
                  <StyledTableContainer sx={{ borderRadius: '0', boxShadow: "0 2px 11px 0px #0000001a" }}>
                    <RemoveScrollBar />
                    <Table sx={{ minWidth: 650, }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align='left'>ID</StyledTableCell>
                          <StyledTableCell align='center'>STATUS</StyledTableCell>
                          <StyledTableCell align='center'>START DATE</StyledTableCell>
                          <StyledTableCell align='center'>END DATE</StyledTableCell>
                          <StyledTableCell align='center'>INVOICE</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {
                          allInvoices && allInvoices.map((data, i) => (
                            <StyledTableRow key={i} sx={{
                              boxShadow: "none", borderRadius: "0", borderTop: "1px solid #F0F0F4",
                              '&:hover': {
                                borderLeft: '3px solid #7953FF',
                              },
                            }}>
                              <StyledTableCell align='left' sx={{ fontSize: "14px", fontWeight: '700' }}>{data.id}</StyledTableCell>

                              <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>
                                {data.status === 'paid' ? <CheckCircleIcon
                                  sx={{ color: '#38C68B' }}></CheckCircleIcon> : data.status === 'draft' || 'open' ? <DraftsIcon></DraftsIcon> : <CancelIcon
                                    sx={{
                                      color: '#EF6D6D'
                                    }}></CancelIcon>}
                              </StyledTableCell>

                              <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>{Date((data.periodStart).slice(0, 10))}</StyledTableCell>
                              <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>{Date((data.periodEnd).slice(0, 10))}</StyledTableCell>
                              <StyledTableCell align='center' hover sx={{ fontSize: "14px", fontWeight: '700', cursor: "pointer", color: 'blue' }}>
                                <Button href={data.url} target='blank' onClick={(e) => { redirect(data.url) }}>
                                  <DownloadForOfflineIcon sx={{ color: "rgba(61, 23, 198, 1)" }}></DownloadForOfflineIcon>
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        }


                      </TableBody>
                    </Table>
                  </StyledTableContainer>
                </Box>
              }
              {
                allInvoices.length === 0 && <Typography sx={{ textAlign: 'center', fontSize: '25px', marginTop: '10px', marginBottom: '10px' }}>
                  No invoices present
                </Typography>
              }
              {
                DataLoading && <Box sx={{ width: "100%", display: "center", justifyContent: "center", fontWeight: 'bold' }}>
                  Loading...
                </Box>
              }
              {
                currentPage === lastpage && <Box sx={{ width: "100%", display: "center", justifyContent: "center", fontWeight: 'bold' }}>
                  All content loaded
                </Box>
              }
            </InfiniteScroll>
          }
        </Box>

        {/* < Stack >
                {
                  campaignTableData.map((data) => (
                    <Box onClick={() => handleCampaignClick(data['campaign_id'], data['status'])} key={data} sx={{ cursor: "pointer", my: 1, p: 2, boxShadow: "0px 2px 11px rgba(0,0,0,0.07)", display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                      <Typography >
                        {data['name']}
                      </Typography>
                      <Typography>
                        {data['status']}
                      </Typography>
                    </Box>
                  ))
                }
              </Stack > */}
      </Box>
    </section>
  )
}

export default Invoices