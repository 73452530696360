import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import {
    Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle, Snackbar, Alert, Table, TableBody,
    TableHead, OutlinedInput, MenuItem
} from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Delete } from '@mui/icons-material';
import { StyledSelectOpt, StyledTableCell, StyledTableContainer, StyledTableRow, StyledTextField, colorTheme } from '../../stylings/styles';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: "#FAFAFA",
//         fontSize: "12px",
//         fontWeight: "700"
//     },
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 14,
//     },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     // hide last border

//     '&:last-child td, &:last-child th': {
//         border: 0,
//     },
// }));

const TeamsFunctions = (props) => {
    const navigate = useNavigate();
    const [isSomethingChanged, setIsSomethingChanged] = React.useState(false);
    const [workspaceData, setWorkspaceData] = React.useState([])
    const [successMsg, setsuccessMsg] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [errorMsg, seterrorMsg] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [deleteEmailDialougeOpen, setdDleteEmailDialougeOpen] = React.useState(false)
    const [deleteEmail, setdDeleteEmail] = React.useState("")

    const handleChange = (val, email) => {
        fetch(`${REACT_APP_SERVER_URL}/update_workspace_user`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "idWorkspace": props.id,
                "updateUserEmail": email,
                "updateUserRole": val
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else if (response.status === 202) {
                console.log("token expired")
                navigate('/login', { state: { sessionExpired: true } })
            }
            else {
                console.log("some error")
                return response.json();
            }
        })
            .then(data => {
                if (data.status === 401) {
                    setErrorSnackbarOpen(true);
                    seterrorMsg(data.msg)
                    return;
                }
                setSuccessSnackbarOpen(true);
                setsuccessMsg(data.msg);
                setIsSomethingChanged(!isSomethingChanged);
            })
            .catch((error) => {
                setErrorSnackbarOpen(true);
                seterrorMsg(error.msg)
                console.log(error)
            })
    };
    useEffect(() => {
        fetch(`${REACT_APP_SERVER_URL}/get_workspace_user/${props.id}`, {
            headers: {
                'Accept': 'application/json',
            },
            credentials: "include",
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else if (response.status === 202) {
                console.log("token expired")
                navigate('/login', { state: { sessionExpired: true } })
            }
            else {
                console.log("some error")
            }
        })
            .then(data => {
                setWorkspaceData(data.data)
            })
            .catch(error => console.log(error))
    }, [isSomethingChanged, navigate, props.id]);
    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbar = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSuccessSnackbarOpen(false);
    };
    const deleteEmailClicked = (email) => {
        setdDleteEmailDialougeOpen(true);
        setdDeleteEmail(email);
    }
    const deleteEmailHandle = (email) => {
        fetch(`${REACT_APP_SERVER_URL}/remove_workspace_user`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "idWorkspace": props.id,
                "removeUserEmail": email
            })
        }).then(response => {
            if (response.status === 200) {
                setIsSomethingChanged(!isSomethingChanged)
                return response.json()
            }
            else if (response.status === 202) {
                seterrorMsg(response.msg);
                setErrorSnackbarOpen(true);
                console.log("token expired")
                navigate('/login', { state: { sessionExpired: true } })
            }
            else {
                console.log("some error")
                return response.json()
            }
        })
            .then(data => {
                if (data.status === 401) {
                    seterrorMsg(data.msg);
                    setErrorSnackbarOpen(true);
                }
                setsuccessMsg(data.msg);
                setSuccessSnackbarOpen(true);
            })
            .catch(error => console.log(error))
        setdDleteEmailDialougeOpen(false)
    }
    return (
        <ThemeProvider theme={colorTheme}>
            <Dialog
                PaperProps={{
                    style: { borderRadius: "20px" }
                }}
                open={deleteEmailDialougeOpen}
            >
                <div style={{ width: "500px", height: "200px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <DialogTitle id="alert-dialog-title">
                        <p style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
                            Are you sure you want to delete account?
                        </p>
                    </DialogTitle>
                    <DialogActions sx={{ mb: 4, display: 'flex', justifyContent: 'center', gap: '20px' }}>
                        <Button onClick={() => deleteEmailHandle(deleteEmail)} variant="contained" color='success' sx={{ color: '#fff' }}>Delete</Button>
                        <Button onClick={() => { setdDleteEmailDialougeOpen(false); setdDeleteEmail("") }} variant="contained" color='error' sx={{ color: '#fff' }} disabled={false}>
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
            {
                <Box sx={{ pt: 2, width: '100%' }}>

                    {
                        workspaceData.length === 0 &&
                        <Box sx={{ display: "flex", justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    }
                    {
                        workspaceData.length !== 0 &&
                        <>
                            <Box>
                                <StyledTableContainer sx={{ p: 3 }}>
                                    <Table sx={{ width: '100%', minWidth: 650, borderCollapse: "separate", borderSpacing: "0 10px", }} aria-label="simple table">
                                        <TableHead>
                                            <StyledTableRow >
                                                <StyledTableCell align='left'>EMAIL</StyledTableCell>
                                                <StyledTableCell align='center'>ROLE</StyledTableCell>
                                                <StyledTableCell align='center'>ROLE CHANGE</StyledTableCell>
                                                <StyledTableCell align='center'>DELETE</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                workspaceData && workspaceData.map((data, i) => (
                                                    <StyledTableRow key={i}
                                                        sx={{
                                                            width: '8rem',
                                                            height: '4rem',
                                                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                                            color: (theme) =>
                                                                theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                                            p: 1,
                                                            m: 1,
                                                            borderRadius: 2,
                                                            textAlign: 'center',
                                                            fontSize: '0.875rem',
                                                            fontWeight: '700',
                                                        }}>
                                                        <StyledTableCell align='left' sx={{ fontSize: "14px", fontWeight: '700' }}>{data['userEmail']}
                                                            {
                                                                data['status'] === "pending" &&
                                                                <QueryBuilderIcon sx={{
                                                                    transform: 'translateY(0.5rem)',
                                                                    ml: 1
                                                                }} />
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>{data['role']}</StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            {
                                                                data['role'] === "owner" &&
                                                                <StyledTextField
                                                                    value={"Owner"}
                                                                    type="text"
                                                                    disabled
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    sx={{
                                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                                        {
                                                                            display: "none",
                                                                        },
                                                                        "& input[type=number]": {
                                                                            MozAppearance: "textfield",
                                                                        },
                                                                        width: '25%',
                                                                        textAlign: 'center'
                                                                    }}
                                                                />
                                                            }
                                                            {
                                                                data['role'] !== "owner" &&
                                                                <StyledSelectOpt
                                                                    sx={{ height: '6vh', width: '25%' }}
                                                                    labelId="demo-multiple-chip-label"
                                                                    id="demo-multiple-chip"
                                                                    required
                                                                    value={data['role']}
                                                                    onChange={(e) => {
                                                                        let val = e.target.value;
                                                                        console.log("ubexhbeuhj", val);
                                                                        handleChange(val, data['userEmail']);
                                                                    }}
                                                                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                                >
                                                                    <MenuItem
                                                                        value="admin"
                                                                    >
                                                                        Admin
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        value="editor"
                                                                    >
                                                                        Editor
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        value="view"
                                                                    >
                                                                        View
                                                                    </MenuItem>
                                                                </StyledSelectOpt>
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>
                                                            <Button sx={{ color: "#989898" }}
                                                                onClick={() => deleteEmailClicked(data['userEmail'])}
                                                            >
                                                                <LightTooltip title="Delete Button">
                                                                    <Delete />
                                                                </LightTooltip>
                                                            </Button>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            }

                                        </TableBody>
                                    </Table>
                                </StyledTableContainer>
                            </Box>
                        </>
                    }
                </Box>
            }
            <Snackbar
                open={errorSnackbarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={6000}
                onClose={handleErrorSnackbarClose}
            >
                <Alert
                    onClose={handleErrorSnackbarClose}
                    variant="filled"
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {errorMsg}
                </Alert>
            </Snackbar>

            <Snackbar
                open={successSnackbarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={6000}
                onClose={handleSuccessSnackbar}
            >
                <Alert
                    onClose={handleSuccessSnackbar}
                    variant="filled"
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    {successMsg}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    )
}

export default TeamsFunctions