
import React from 'react';
import './TermsAndConditions.css';
import {  Paper, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom'
import xCampaignLogo from "../../img/icons/xCampaignLogo.png";
import RingLogo from "../../img/svg/ring.png"


const TermsAndConditions = () => {
    return (

        <div>
            <div id='logoContainer'>
                <img src={xCampaignLogo} alt='logo' id='RingLogo1'></img>

            </div>
            <div id="headerPrivacy">
                <div id="headertext">
                    <Link exact to='/register' id='linkcomponent'>Home &gt; </Link>
                    <span>Terms and Condition</span>

                </div>
            </div>
            <div id='RingLogo1'>
                <img src={RingLogo} alt='logo' id='ringlogo1'>
                </img>

            </div>
            <div id="terms-container">
                <h1 id='topHead'>Terms and Conditions</h1>
                {/* <div id="topHead">Terms and Conditions</div> */}
                <Paper elevation={3} sx={{ padding: '20px' }}>
                    <h1 id='heading1'>Welcome to XCampaign.com</h1>

                    <section id="terms-section">
                        <h2 id='heading2'>Terms of Use</h2>
                        <p id='paraTag'>
                            These "Terms of Use" define the rules and regulations for using the XCampaign website.
                        </p>
                        <p id='paraTag'>
                            By using this website, we assume you accept these "Terms and Conditions" in its entirety.
                            If you disagree with these terms and conditions, you must not use this website.
                        </p>
                    </section>

                    <section id="terms-section">
                        <h2 id='heading2'>Overview</h2>
                        <p id='paraTag'>
                            Your contract with XCampaign is governed by the terms listed below. By accessing or using
                            this website, you acknowledge that you have read, comprehended, and agree to be bound by these
                            terms as well as any applicable laws and regulations.
                        </p>
                        <p id='paraTag'>
                            XCampaign has the right to modify these Terms of Use, as well as any other material on the website, at any time and without notice. XCampaign reserves the right, at any time and without notice, to make modifications or improvements to the items, services, or programs described on this site.
                        </p>
                    </section>
                    <section id='terms-section'>
                        <h2 id='heading2'>General</h2>
                        <p id='paraTag'>
                            This Privacy Policy explains what personal information XCampaign gathers or obtains while providing our services, how we use it, and what choices you have. A section of this website allows users to access information in specific areas. Any content from this site or any other social media may not be mirrored.
                            <p id='paraTag'>
                                If you fail to comply with these terms or any other rules on this site, your rights will be automatically terminated without notice, and you must immediately destroy all copies of downloaded content in your possession or custody.
                            </p>
                        </p>
                    </section>
                    <section id='terms-section'>
                        <h2 id='heading2'>
                            Disclaimer
                        </h2>
                        <p id='paraTag'>
                            You should use the most recent pages on this website to confirm the accuracy and completeness of information before making any decisions about services or goods discussed on this website. Because this website may contain technical flaws or typographical errors, we cannot guarantee that any uploaded material is accurate.
                        </p>
                        <p id='paraTag'>
                            If a competent legal body finds any term in this Terms of Use to be unenforceable in any way, the validity of the rest of this Terms of Use will not be affected if the unenforceability does not materially affect the parties' rights under this Terms of Use.
                        </p>
                    </section>
                    <section id='terms-section'>
                        <h2 id='heading2'>Confidential Information</h2>
                        <p id='paraTag'>
                            Confidential information is any nonpublic information that is supplied, revealed, or conveyed, regardless of the form or way it is provided, disclosed, or sent.
                        </p>
                        <p id='paraTag'>
                            XCampaign agrees, acknowledges, and commits to always keep the sensitive information confidential. XCampaign and you agree to keep the confidential information entirely confidential and not to use, make available, sell, distribute, disclose, publish, or make such confidential information accessible to any third party in any way, whether directly or indirectly.
                        </p>
                    </section>
                </Paper>

            </div>
            <div id='RingLogo1'>
                <img src={RingLogo} alt='logo' id='ringlogo2'>
                </img>

            </div>
            <div id="footerContainer">
                <footer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <hr></hr>

                    <Box sx={{ width: '50%' }}>
                        <Link exact to='/privacyAndpolicy' style={{ fontSize: '20px', color: "white", padding: '20px', }} id='link'>
                            Privacy Policy
                        </Link>
                        <Link exact to='/termsAndconditons' style={{ fontSize: '20px', color: "white" }} id='link'>
                            Terms & Conditions
                        </Link>
                    </Box>

                    <Box sx={{ width: '50%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        <Typography>  Copyright &#169; 2023 XGrowth Tech Pvt. Ltd.</Typography>
                    </Box>

                </footer>

            </div>
        </div>
    );
};

export default TermsAndConditions;
