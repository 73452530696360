import React, { useRef, useState } from 'react'
import { Box, Typography, Button, CircularProgress, Stack, TextField, Snackbar, Alert, Menu, MenuItem, Drawer, InputLabel, FormControl, Grid } from '@mui/material'
import { StyledTextField } from '../../../stylings/styles';
import Steps from './Steps'
import { useNavigate } from 'react-router-dom';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import DraftEditor from '../../TextEditor/DraftEditor';

import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { StyledButtonOutlined } from '../../../stylings/styles';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    borderRadius: '12px',
    boxShadow: 24,
    p: 4,
    display: 'flex'
};

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Sequence = (props) => {
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState(null);

    const divRef = useRef(null);

    const [successMessage, setSuccessMessage] = React.useState('');
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(null)
    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(null)
    const [homeLoading, setHomeLoading] = React.useState(false)
    const [stepsData, setStepsData] = React.useState([])
    const [isSomethingChanged, setIsSomethingChanged] = React.useState(false)
    const [selectedStep, setSelectedStep] = React.useState(0);
    const [currentvarient, setCurrentVarient] = React.useState(0);
    const [isvarientDeleted, setIsvarientDeleted] = React.useState(false);
    const [dataSaved, setDataSaved] = React.useState(false);
    const [sequenceLoading, setSequenceLoading] = React.useState(false)
    const [saveTemplateLoading, setSaveTemplateLoading] = React.useState(false)
    const [settingBarSwitch, setSettingBarSwitch] = React.useState(false)
    const [wait_time, setWait_time] = useState(0);
    const [age, setAge] = useState('');
    const [isContentChanged, setIsContentChanged] = React.useState(false)
    const [activeField, setActiveField] = useState('TextField');
    const [leadCheck, setLeadCheck] = React.useState([])
    const [MenuFireIconArray, setMenuFireIconArray] = React.useState(["firstname", "lastname", "company"])
    const [open, setOpen] = React.useState(false);
    const [template, setTemp] = useState([]);
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [index, setIndex] = useState('');
    const [loader, setLoader] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [currentValue, setCurrentValue] = useState('');
    const [templateDialog, setTemplateDialog] = React.useState(false)
    const [templateName, setTemplateName] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    function applyChange(e) {
        e.preventDefault();

        if (age === 1) {
            currentValue.open_add_time = parseInt(wait_time);
            currentValue.reply_add_time = -1;
            setSettingBarSwitch(false);
            return;
        }
        else if (age === 2) {
            currentValue.reply_add_time = parseInt(wait_time);
            currentValue.open_add_time = -1;
            setSettingBarSwitch(false);
            return;
        }
        else {
            currentValue.open_add_time = -1;
            currentValue.reply_add_time = -1;
            setSettingBarSwitch(false);
            return;
        }
    }

    function setValue(data) {
        if (data.open_add_time > -1) {
            setAge(1);
            setWait_time(data.open_add_time)
            return;
        }
        else if (data.reply_add_time > -1) {
            setAge(2);
            setWait_time(data.reply_add_time);
            return;
        }
        else {
            setAge(0);
            setWait_time(0);
        }
    }


    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorSnackbarOpen(false);
    }

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessSnackbarOpen(false);
    }

    React.useEffect(() => {
        funcFetch();
        setHomeLoading(true)
        fetch(`${REACT_APP_SERVER_URL}/get_sequence`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "campaign": {
                    'campaign_id': props.id
                }
            })
        }).then(response => {
            if (response.status === 200) {
                setHomeLoading(false)
                return response.json()
            }
            else if (response.status === 202) {
                console.log("token expired")
                navigate('/login', { state: { sessionExpired: true } })
            }
            else {
                console.log("some error")
            }
        })
            .then(data => {
                setValue(data.data.data[0]);
                setStepsData(data['data'])
            })
            .catch(error => console.log(error))
    }, [navigate, props.id])

    const handleStepChange = (newStep) => {
        setSelectedStep(newStep)
        setIsContentChanged(true)
    }

    const handleStepDataChange = (newData) => {
        let tempStepDataChange = stepsData
        tempStepDataChange['data'][selectedStep] = newData
        setStepsData(tempStepDataChange)
        setIsSomethingChanged(!isSomethingChanged)
    }

    const scrollToTarget = () => {
        // if (divRef.current) {
        //   divRef.current.scrollIntoView({ behavior: 'smooth' });
        // }
        // if (divRef.current) {
        //     divRef.current.scrollTop = divRef.current.scrollHeight;
        //   }
        if (divRef.current) {
            const container = divRef.current;
            container.scrollTo({
                top: container.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    const addStepsHandler = () => {
        if (stepsData['data'].length < 10) {
            let tempStepsData = { ...stepsData };
            let tempStepsEmptyJson = {
                "wait_time": 1,
                "open_add_time": -1,
                "reply_add_time": -1,
                "varient": [
                    {
                        "subject": '',
                        "body": '',
                    }
                ],
                "current_varient": 1,
                "extra_sequence": {},
                "other_sequence": {},
                "campaign_id": stepsData['campaign_id']
            };
            tempStepsData['data'].push(tempStepsEmptyJson);
            setStepsData(tempStepsData);
            setIsSomethingChanged(!isSomethingChanged);
            setTimeout(scrollToTarget, 500);
        } else {
            setErrorSnackbarOpen(true);
            setErrorMessage("Maximum number of steps reached (10)");
        }
    }

    const handleSubjectChange = (e) => {
        let tempStepsData = stepsData
        tempStepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1]['subject'] = e.target.value
        setStepsData(tempStepsData)
        setIsSomethingChanged(!isSomethingChanged)
    }

    const handleTextFieldFocus = () => {
        setActiveField('TextField');
    };
    // const handleDraftEditorFocus = () => {
    //     setActiveField('DraftEditor');
    //     console.log('jkhgfd');
    // };


    const sequenceSaveHandler = () => {
        if (stepsData.data[0].varient[0].subject === undefined || stepsData.data[0].varient[0].subject === null || stepsData.data[0].varient[0].subject === '') {
            setErrorSnackbarOpen(true);
            setErrorMessage("The subject of the first phase cannot be blank.")
            return;
        }
        const updatedStepsData = JSON.parse(JSON.stringify(stepsData));
        // updatedStepsData.data.forEach(step => {
        //     step.varient.forEach(variant => {
        //         variant.subject = variant.subject.replace(/{{|}}/g, "");
        //     });
        // });

        if (isvarientDeleted) {
            setSequenceLoading(true)
            fetch(`${REACT_APP_SERVER_URL}/sequence_update`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                method: 'POST',
                body: JSON.stringify({
                    "data": updatedStepsData,
                    "deleteSeq": true
                })
            }).then(response => {
                if (response.status === 200) {
                    setDataSaved(!dataSaved)
                    setSequenceLoading(false)
                    setSuccessSnackbarOpen(true)
                    setStepsData(updatedStepsData)
                    return response.json()
                }
                else if (response.status === 202) {
                    setSequenceLoading(false)
                    console.log("token expired")
                    navigate('/login', { state: { sessionExpired: true } })
                }
                else {
                    console.log("some error")
                    setSequenceLoading(false)
                    setErrorSnackbarOpen(true)
                    props.saveErrorHandler()
                    return response.json()
                }
            })
                .then(data => {
                    setErrorMessage(data.msg)
                    setSuccessMessage(data.msg)
                    console.log(data)
                })
                .catch(error => console.log(error))
            setIsvarientDeleted(false);
        } else {
            setSequenceLoading(true)
            fetch(`${REACT_APP_SERVER_URL}/sequence_update`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                method: 'POST',
                body: JSON.stringify({
                    "data": updatedStepsData
                })
            }).then(response => {
                if (response.status === 200) {
                    setDataSaved(!dataSaved)
                    setSequenceLoading(false)
                    setSuccessSnackbarOpen(true)
                    setStepsData(updatedStepsData)
                    return response.json()
                }
                else if (response.status === 202) {
                    setSequenceLoading(false)
                    console.log("token expired")
                    navigate('/login', { state: { sessionExpired: true } })
                }
                else {
                    console.log("some error")
                    setSequenceLoading(false)
                    setErrorSnackbarOpen(true)
                    props.saveErrorHandler()
                    return response.json()
                }
            })
                .then(data => {
                    setErrorMessage(data.msg)
                    setSuccessMessage(data.msg)
                    console.log(data)
                })
                .catch(error => console.log(error))
        }
    }

    const handleStepDelete = (index) => {
        console.warn("current", stepsData);
        setIsvarientDeleted(true);
        let tempStepData = stepsData;
        tempStepData['data'].splice(index, 1)
        currentStepHandler(index === 0 ? index : index - 1);
        setStepsData(tempStepData)
        console.warn("after deletiing", stepsData);
    }

    const currentStepHandler = (ind) => {
        console.log(ind);
        setSelectedStep(ind);
    }

    const varientDeletedFunciton = (flag) => {
        setIsvarientDeleted(true);
    }

    const handleClick = event => {
        ThorIconMenuItem();
        setAnchorEl(event.currentTarget);
    };

    const menuhandleClose = (a = "") => {
        let tempStepsData = stepsData
        const placeholder = a;
        if (activeField === 'TextField') {
            tempStepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1]['subject'] = tempStepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1]['subject'] + a;
            setStepsData(tempStepsData)
            setIsSomethingChanged(!isSomethingChanged)
            setAnchorEl(null);
            setMenuFireIconArray(["firstname", "lastname", "company"]);
        } else if (activeField === 'DraftEditor') {
            setIsContentChanged(false);
            tempStepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1]['body'] = tempStepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1]['body'] + placeholder;
            setStepsData(tempStepsData)
            setIsContentChanged(true)
            setIsSomethingChanged(!isSomethingChanged)
            setAnchorEl(null);
            setIsContentChanged(true);
            setMenuFireIconArray(["firstname", "lastname", "company"]);
        }
    };

    const settinghandleCliked = (i) => {
        setValue(stepsData['data'][i])
        setCurrentValue(stepsData['data'][i])
        setSettingBarSwitch(true)
    }

    const handleDragStart = (event, index) => {
        event.dataTransfer.setData("text/plain", index.toString());
    };

    const handleDragOver = (event, index) => {
        event.preventDefault();
    };

    const handleDrop = (event, targetIndex) => {
        event.preventDefault();
        const sourceIndex = parseInt(event.dataTransfer.getData("text/plain"));
        if (sourceIndex !== targetIndex) {
            const updatedStepsData = [...stepsData['data']];
            const [draggedStep] = updatedStepsData.splice(sourceIndex, 1);
            updatedStepsData.splice(targetIndex, 0, draggedStep);
            setStepsData({ ...stepsData, data: updatedStepsData });
        }
    };

    const handleReorder = (fromIndex, toIndex) => {
        const updatedStepsData = [...stepsData['data']];

        const [draggedStep] = updatedStepsData.splice(fromIndex, 1);
        const draggedVariants = draggedStep['varient'];

        updatedStepsData.splice(toIndex, 0, { ...draggedStep, varient: [...draggedVariants] });

        setStepsData({ ...stepsData, data: updatedStepsData });
    };

    const saveAsTemplate = async () => {
        setSaveTemplateLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/save_template`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "data": stepsData,
                "selectedStep": selectedStep,
                "currentvarient": currentvarient,
                "templateName": templateName
            })
        }).then(response => {
            if (response.status === 200) {
                setDataSaved(!dataSaved)
                setTemplateDialog(false);
                setTemplateName('');
                setSaveTemplateLoading(false)
                setSuccessSnackbarOpen(true)
                return response.json()
            }
            else if (response.status === 202) {
                setSaveTemplateLoading(false)
                setTemplateDialog(false);
                setTemplateName('');
                console.log("token expired")
                navigate('/login', { state: { sessionExpired: true } })
            }
            else {
                console.log("some error")
                setSaveTemplateLoading(false)
                setTemplateDialog(false);
                setTemplateName('');
                setErrorSnackbarOpen(true)
                props.saveErrorHandler()
                return response.json()
            }
        })
            .then(data => {
                setErrorMessage(data.msg)
                setSuccessMessage("Saved Template Successfully");
                console.log(data)
            })
            .catch(error => console.log(error))
    }

    const handleTemplateDialogClose = () => {
        setTemplateDialog(false)
    }

    async function funcFetch() {
        fetch(`${REACT_APP_SERVER_URL}/get_leads`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "campaign": {
                    'campaign_id': props.id
                }
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else if (response.status === 202) {
                console.log("token expired")
                navigate('/login', { state: { sessionExpired: true } })
            }
            else {
                console.log("some error")
            }
        })
            .then(data => {
                console.log(data.data.data[0], 'data.data.data[0]');
                setLeadCheck(data.data.data[0])
            })
            .catch(error => console.log(error))
    }

    function ThorIconMenuItem() {
        // let MenuFireIconArray = [];


        console.log('MenuFunction Hittin', leadCheck.extra_leadcontact);
        let extra_leadcontact = leadCheck.extra_leadcontact;
        if (extra_leadcontact === null) {
            console.log('Null value')
            return;
        }

        let extra_leadcontactCheck = Object.keys(extra_leadcontact);

        if (extra_leadcontactCheck.length === 0) {
            console.log('Empty')
            return;
        }
        else {
            console.log('Not empty')
            console.log(extra_leadcontactCheck)
            for (let i = 0; i < extra_leadcontactCheck.length; i++) {
                MenuFireIconArray.push(extra_leadcontactCheck[i])
            }
        }
        console.log(MenuFireIconArray, 'array')
    }

    function loadTemplate(e) {
        e.preventDefault();
        setLoader(true);
        handleOpen();
        fetch(`${REACT_APP_SERVER_URL}/get_template`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
            method: 'GET',
        }).then((res => {
            return res.json();
        })).then(data => {
            console.log(data);
            if (data.status === 200) {
                setLoader(false);
                setTemp(data.data);
                setIndex(0);
                setSubject(data.data[0].subject);
                setBody(htmlTotext(data.data[0].body));
            }
        })
    }

    function htmlTotext(html) {
        var plainText = html.replace(/<[^>]+>/g, '');
        return plainText;
    }

    return (
        <>
            <Box>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={open}>
                        {
                            loader ?
                                <Box sx={{ display: "flex", justifyContent: 'center' }}>
                                    <CircularProgress />
                                </Box> :
                                <Box sx={style}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ p: 1 }}>
                                            <Box sx={{
                                                maxHeight: "450px", px: 1, overflow: "scroll", '&::-webkit-scrollbar': {
                                                    width: '0.0em',
                                                },
                                            }}
                                            >

                                                <Stack spacing={2}>
                                                    {
                                                        template.map((data, index) => {
                                                            return (
                                                                <Card sx={{ minWidth: 275, cursor: 'pointer', border: '1px solid blue', borderRadius: '16px' }} onClick={(e) => {
                                                                    setIndex(index);
                                                                    setSubject(template[index].subject)
                                                                    setBody(htmlTotext(template[index].body))
                                                                }}>
                                                                    <CardContent>
                                                                        <Typography sx={{ fontWeight: 'bolder' }}>{data.name}</Typography>
                                                                        {/* <Typography>{data.subject}</Typography>
                                                                <Typography sx={{ fontWeight: 'bolder' }}>Body:</Typography>
                                                                <Typography>{htmlTotext(data.body)}</Typography> */}
                                                                    </CardContent>
                                                                </Card>
                                                            )
                                                        })
                                                    }
                                                </Stack>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ p: 1, color: "rgb(199, 197, 197)", minHeight: "450px", }}>
                                            <Box sx={{ p: 2, border: "1px solid #E7E7E7", backgroundColor: "#f7f7f9", borderRadius: "20px", }}>
                                                {
                                                    <>
                                                        <Box
                                                            sx={{ width: "100%", display: "flex", alignItems: "center", borderBottom: "1px solid rgb(199, 197, 197)" }}
                                                        >
                                                            <Typography sx={{ display: "flex", gap: '6px', fontSize: '14px', alignItems: 'center' }}>
                                                                Subject
                                                            </Typography>
                                                            <TextField
                                                                value={subject}
                                                                fullWidth
                                                                sx={{ border: 'none', "& fieldset": { border: 'none' }, }}
                                                                InputProps={{
                                                                    style: {
                                                                        color: '#4F4F4F',
                                                                        fontSize: '14px',
                                                                        border: 'none',
                                                                    },
                                                                    disableUnderline: true,
                                                                }}
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <TextField
                                                                value={body}
                                                                fullWidth
                                                                sx={{ border: 'none', "& fieldset": { border: 'none' }, height: '270px' }}
                                                                InputProps={{
                                                                    style: {
                                                                        color: '#4F4F4F',
                                                                        fontSize: '14px',
                                                                        border: 'none',
                                                                    },
                                                                    disableUnderline: true,
                                                                }}
                                                            />
                                                        </Box>
                                                        <Button variant='contained' onClick={(e) => {
                                                            handleClose();
                                                            stepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1]['subject'] = template[index].subject;
                                                            stepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1]['body'] = template[index].body;
                                                        }
                                                        }>Use</Button>
                                                    </>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                        }
                    </Fade>
                </Modal>
            </Box >
            <Drawer
                anchor={'right'}
                open={settingBarSwitch}
                onClose={() => setSettingBarSwitch(false)}
            >
                <Box sx={{ width: "40rem" }}>
                    <Box sx={{ width: "100%", pt: 4 }}>
                        <Box sx={{ p: 6, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Card sx={{ minWidth: 275, mb: 2, p: 0 }}>
                                {/*   <CardContent sx={{borderBottom:"2px dotted black"}}>
                                    <Typography sx={{ fontSize: 14, display: "flex",alignItems:"center" }} color="text.secondary" gutterBottom size="small">
                                        If a lead &nbsp;
                                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                            <InputLabel id="demo-select-small-label">Age</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={"new"}
                                                label="Age"
                                                onChange={""}
                                                sx={{ width: "5rem", }}

                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Typography>

                                </CardContent> */}

                                <CardContent>
                                    <Typography sx={{ fontSize: 14, display: "flex", alignItems: "center", }} color="text.secondary" gutterBottom size="small">
                                        If a lead &nbsp;
                                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                            <InputLabel id="demo-select-small-label"></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={age}
                                                onChange={handleChange}
                                                sx={{ width: "12rem", }}
                                            >
                                                <MenuItem value={0}>None</MenuItem>
                                                <MenuItem value={1}>Opens this email</MenuItem>
                                                <MenuItem value={2}>Replies to this email</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Typography>

                                </CardContent>
                            </Card>
                            {age === 0 ? null : (
                                <Card sx={{ minWidth: 275, height: "50px", mb: 2 }}>
                                    <CardContent>
                                        <Typography sx={{ fontSize: 14, display: "flex", alignItems: "center" }} color="text.secondary" gutterBottom>
                                            <AccessTimeFilledIcon /> &nbsp; Day to wait before next step:&nbsp;
                                            <input type='number'
                                                onChange={(e) => { setWait_time(e.target.value) }}
                                                value={wait_time}
                                                min={0}
                                                max={7}
                                                style={{ width: "40px", m: 0, border: '1px solid gray', borderRadius: '3px', "& fieldset": { border: 'none' }, }}
                                                fullWidth
                                            />
                                        </Typography>

                                    </CardContent>
                                </Card>
                            )}
                            <Button onClick={applyChange} variant="contained" sx={{ bottom: "40px", width: '10px', mt: 8 }} disabled={sequenceLoading}>
                                Apply
                            </Button>
                            {/* <Card sx={{ minWidth: 275, height: "50px", mb: 2 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Word of the Day
                                    </Typography>

                                </CardContent>
                                <CardActions>
                                    <Button size="small">Learn More</Button>
                                </CardActions>
                            </Card> */}
                        </Box>
                    </Box>
                </Box>
            </Drawer >
            {(homeLoading && stepsData.length === 0) ?
                <Box sx={{ display: "flex", justifyContent: 'center' }}>
                    <CircularProgress />
                </Box> :
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    {sequenceLoading ?
                        <Box sx={{
                            width: "100%",
                            height: "500px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>

                            <CircularProgress size={25} />&nbsp;&nbsp;&nbsp;Submitting.......
                        </Box>
                        :
                        <Grid container>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ p: 1 }}>
                                <Box sx={{
                                    maxHeight: "450px", px: 1, overflow: "scroll", '&::-webkit-scrollbar': {
                                        width: '0.0em',
                                    },
                                }}
                                    ref={divRef}
                                >
                                    {
                                        <Stack spacing={2}>
                                            {
                                                stepsData && stepsData['data']?.map((data, index) => (
                                                    <div key={index} draggable
                                                        onDragStart={(event) => handleDragStart(event, index)}
                                                        onDragOver={handleDragOver}
                                                        onDrop={(event) => handleDrop(event, index)} // Use the index as the targetIndex
                                                    >
                                                        <Steps
                                                            id={index} // Add a unique id to each Steps component
                                                            length={stepsData['data'].length}
                                                            data={data}
                                                            index={index} // Pass the index as a prop
                                                            selected={selectedStep}
                                                            handleStepDataChange={handleStepDataChange}
                                                            handleStepChange={handleStepChange}
                                                            deleteIndex={stepsData['data'].length - 1}
                                                            settinghandleCliked={settinghandleCliked}
                                                            handleStepDelete={handleStepDelete}
                                                            isvarientDeleted={varientDeletedFunciton}
                                                            handleReorder={handleReorder}
                                                            setCurrentVarient={setCurrentVarient}
                                                        />
                                                    </div>
                                                ))
                                            }
                                            <span id='new'></span>
                                        </Stack>
                                    }
                                    {/* <div ref={divRef} /> */}
                                </Box>
                                <StyledButtonOutlined
                                    onClick={addStepsHandler}
                                    variant='outlined' fullWidth sx={{ textTransform: 'capitalize', fontSize: '14px', color: "#7D7D7D", my: 2, border: "1px solid gray" }}>
                                    + Add follow up
                                </StyledButtonOutlined>

                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ p: 1, color: "rgb(199, 197, 197)", minHeight: "450px", }}>
                                <Button onClick={loadTemplate} variant="contained" sx={{ width: '25%', float: 'right', marginRight: '16px', marginTop: '8px' }}>
                                    Select Template
                                </Button>
                                <Box sx={{ p: 2, border: "1px solid #E7E7E7", backgroundColor: "#f7f7f9", borderRadius: "20px", }}>
                                    {
                                        (!homeLoading && stepsData.length !== 0) &&
                                        <>
                                            <Box
                                                sx={{ width: "100%", display: "flex", alignItems: "center", borderBottom: "1px solid rgb(199, 197, 197)" }}
                                            >
                                                <Typography sx={{ display: "flex", gap: '6px', fontSize: '14px', alignItems: 'center' }}>
                                                    <HelpOutlineOutlinedIcon sx={{ fontSize: '15px' }} /> Subject
                                                </Typography>
                                                <TextField
                                                    value={stepsData['data'][selectedStep] && stepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1] ? stepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1]['subject'] : ""}
                                                    onChange={handleSubjectChange}
                                                    onFocus={handleTextFieldFocus}
                                                    fullWidth
                                                    sx={{ border: 'none', "& fieldset": { border: 'none' }, }}
                                                    InputProps={{
                                                        style: {
                                                            color: '#4F4F4F',
                                                            fontSize: '14px',
                                                            border: 'none',
                                                        },
                                                        disableUnderline: true,
                                                    }}
                                                    placeholder='Leave Empty To Use Previous Subject'
                                                />
                                                <Button
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    // sx={{m:1}}
                                                    style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px', marginRight: "10px", }}
                                                    onClick={handleClick}
                                                >
                                                    <FlashOnIcon sx={{ width: "25px" }} />
                                                </Button>
                                                <Button onClick={() => {setTemplateDialog(true)}} variant="outlined" sx={{ width: '40%' }}>
                                                    Save As Template
                                                </Button>


                                                {/* <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={() => menuhandleClose("")}
                                                >
                                                    <MenuItem onClick={() => menuhandleClose(" {{firstname}}")} sx={{
                                                        borderBottom: '1px solid #E7E7E7',
                                                        '&:hover': {
                                                            borderLeft: '3px solid #7953FF',
                                                        }
                                                    }}
                                                    >First Name</MenuItem>
                                                    <MenuItem onClick={() => menuhandleClose(" {{lastname}}")} sx={{
                                                        borderBottom: '1px solid #E7E7E7',
                                                        '&:hover': {
                                                            borderLeft: '3px solid #7953FF',
                                                        }
                                                    }}>Last Name</MenuItem>
                                                    <MenuItem onClick={() => menuhandleClose("{{company}}")} sx={{
                                                        borderBottom: '1px solid #E7E7E7',
                                                        '&:hover': {
                                                            borderLeft: '3px solid #7953FF',
                                                        }
                                                    }}>Company</MenuItem>
                                                </Menu> */}


                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={() => menuhandleClose("")}
                                                >
                                                    {MenuFireIconArray.map((option) => (
                                                        <MenuItem
                                                            key={option}
                                                            onClick={() => menuhandleClose(`{{${option}}}`)}
                                                            sx={{
                                                                borderBottom: '1px solid #E7E7E7',
                                                                '&:hover': {
                                                                    borderLeft: '3px solid #7953FF',
                                                                },
                                                            }}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Menu>



                                                {/* <ContactMailIcon onClick={} sx={{ color: "rgb(199, 197, 197)", ml: 1 }} /> */}
                                            </Box>


                                            <Box sx={{
                                                pb: 3, px: 2, pt: 1, overFlow: "auto", maxHeight: "475px",
                                                "&::-webkit-scrollbar": {
                                                    width: "5px",
                                                    height: "2px",
                                                },
                                                "&::-webkit-scrollbar-thumb": {
                                                    backgroundColor: "rgba(26, 26, 26, 0.1)",
                                                    borderRadius: "6px",
                                                },
                                            }}>
                                                {/* <QuillEditor value={stepsData['data'][selectedStep] && stepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1] ? stepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1]['body'] : ""}
                                                    onChange={handleEmailBodyChange} /> */}
                                                {/* <TinymceEditor onContentChanged={isContentChanged} setIsContentChanged={setIsContentChanged} value={stepsData['data'][selectedStep] && stepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1] ? stepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1]['body'] : ""}
                                                    onChange={handleEmailBodyChange} /> */}
                                                {/* <CKEditor onContentChanged={isContentChanged} setIsContentChanged={setIsContentChanged} value={stepsData['data'][selectedStep] && stepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1] ? stepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1]['body'] : ""}
                                                    onChange={handleEmailBodyChange} /> */}
                                                <DraftEditor onContentChanged={isContentChanged} setIsContentChanged={setIsContentChanged} value={stepsData['data'][selectedStep] && stepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1] ? stepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1]['body'] : ""}
                                                    // onChange={handleEmailBodyChange} 
                                                    stepsData={stepsData}
                                                    setStepsData={setStepsData}
                                                    selectedStep={selectedStep}
                                                    setActiveField={setActiveField}
                                                />
                                                {/* <FroalaEditor onContentChanged={isContentChanged} setIsContentChanged={setIsContentChanged} value={stepsData['data'][selectedStep] && stepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1] ? stepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1]['body'] : ""}
                                                    onChange={handleEmailBodyChange} /> */}
                                            </Box>
                                        </>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    }
                </Box>
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={templateDialog}
            >
                <Stack gap={1} justifyContent="center" alignItems="center">
                    <Box sx={{ color: "black", width: "620px", p: 3, height: "300px", display: "flex", justifyContent: "space-around" }}>
                        <Box sx={{ width: "510px", height: "250px", borderRadius: "30px", backgroundColor: "white", display: "flex", justifyContent: "space-around", flexDirection: "column", p: 2 }}>
                            <Typography
                                fontSize="24px" sx={{ fontWeight: '700', }}
                            >Name Your Template</Typography>

                            <StyledTextField
                                label="New Template"
                                onChange={(e) => setTemplateName(e.target.value)}
                                fullWidth
                                sx={{ backgroundColor: "rgb(247, 245, 245)", border: "none", outline: "none", borderRadius: "10px" }}
                            />
                            <Button
                                onClick={saveAsTemplate}
                                sx={{
                                    backgroundColor: "#481BEB", color: "white", borderRadius: '6px',
                                    width: "180px", border: "1px solid", "&:hover": {
                                        backgroundColor: 'white',
                                        color: "#fff",
                                        border: "1px solid"
                                    }
                                }}
                            >
                                {
                                    saveTemplateLoading && <CircularProgress size={25} />
                                }
                                {
                                    !saveTemplateLoading && 'Add Template'
                                }
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "40px",
                                height: "40px",
                                backgroundColor: "white",
                                borderRadius: "50%",
                            }}
                        >
                            <Button onClick={handleTemplateDialogClose}
                                sx={{ borderRadius: "50%", backgroundColor: "none", color: '#4F4F4F' }}
                            >X</Button>
                        </Box>
                    </Box>
                </Stack>
            </Backdrop>
            {
                !homeLoading &&
                <Button onClick={sequenceSaveHandler} variant="contained" sx={{}} disabled={sequenceLoading}>
                    Save
                </Button>
            }

            <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </>

    )
}

export default Sequence