import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CardContent from "@mui/material/CardContent";
import ContactMailIcon from "@mui/icons-material/ContactMail";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

function WarmupTamplet() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [templates, setTemplates] = useState([]);


  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  useEffect(() => {
    templateFunc();
    allTemplates();
  }, []);

  function allTemplates() {
    setLoading(true);
    fetch(`${REACT_APP_SERVER_URL}/warmuptemplates`, {
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setTemplates(data.data);
      })
      .catch((error) => console.log(error));
  }

  function templateFunc() {
    setLoading(true);
    fetch(`${REACT_APP_SERVER_URL}/warmuptemplate`, {
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
      method: "GET",
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          return response.json();
        } else if (response.status === 202) {
          setLoading(false);
          console.log("token expired");
        } else {
          setLoading(false);
          console.log("some error");
        }
      })
      .then((data) => {
        setData(data.data.id);
        setName(data.data.name);
        setSubject(data.data.meta_data.subject);
        setBody(data.data.meta_data.body);
      })
      .catch((error) => console.log(error));
  }

  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorSnackbarOpen(false);
  };
  const handleSuccessSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbarOpen(false);
  };

  const CreateWarmupFunction = () => {
    setLoading(true);
    fetch(`${REACT_APP_SERVER_URL}/warmup_templateAdd`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        id: data,
        name: name,
        subject: subject,
        text: body,
      }),
    })
      .then((resp) => {
        setLoading(false);
        return resp.json();
      })
      .then((d) => {
        if (d.status === 200) {
          setSuccessSnackbarOpen(true);
          setsuccessMsg(d.msg);
        } else {
          setErrorSnackbarOpen(true);
          seterrorMsg(d.msg);
        }
      })
      .catch((error) => console.log(error));
  };

  const changeWarmFunction = () => {
    setLoading(true);
    fetch(`${REACT_APP_SERVER_URL}/warmuptemplateupdate`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        id: data,
        name: name,
        subject: subject,
        text: body,
      }),
    })
      .then((resp) => {
        setLoading(false);
        return resp.json();
      })
      .then((d) => {
        if (d.status === 200) {
          setSuccessSnackbarOpen(true);
          setsuccessMsg(d.msg);
        } else {
          setErrorSnackbarOpen(true);
          seterrorMsg(d.msg);
        }
      })
      .catch((error) => console.log(error));
  };

  const DeleteWarmupFunction = () => {
    setLoading(true);
    fetch(`${REACT_APP_SERVER_URL}/warmup_templatedelete`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        id: data,
      }),
    })
      .then((resp) => {
        setLoading(false);
        return resp.json();
      })
      .then((d) => {
        if (d.status === 200) {
          setSuccessSnackbarOpen(true);
          setsuccessMsg(d.msg);
        } else {
          setErrorSnackbarOpen(true);
          seterrorMsg(d.msg);
        }
      })
      .catch((error) => console.log(error));
  };

  function getTemplate(index) {
    let temp = templates;
    setName(temp[index].name);
    setSubject(temp[index].meta_data.subject);
    setBody(temp[index].meta_data.body);
    setData(temp[index].id);

    let btn = document.getElementById("create");
    btn.style.visibility = "hidden";
  }

  function emptyTemplate() {
    setName("");
    setSubject("");
    setBody("");
    setData("");
    let btn = document.getElementById("create");
    btn.style.visibility = "visible";

  }

  return (
    <>
      <Box sx={{ float: "left", width: "10%" }}>
        <Button
          sx={{
            border: "1px solid blue",
            borderRadius: "6px",
            marginBottom: "8px",
          }}
          onClick={emptyTemplate}
        >
          Create template
        </Button>
        {templates.map((i, j) => {
          return (
            <CardContent
              sx={{
                cursor: "pointer",
                border: "1px solid gray",
                borderRadius: "6px",
                marginBottom: "8px",
              }}
              onClick={(e) => {
                getTemplate(j);
              }}
            >
              <Typography
                sx={{
                  fontSize: 16,
                }}
                color="text.secondary"
                gutterBottom
              >
                Template {j + 1}
              </Typography>
            </CardContent>
          );
        })}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {" "}
        {loading ? (
          <CircularProgress />
        ) : (
          <Box
            sx={{
              color: "rgb(199, 197, 197)",
              borderRadius: "20px",
              minHeight: "450px",
              width: "70%",
              border: "1px solid #E7E7E7",
              backgroundColor: "#f7f7f9",
              p: 1,
              pb: 0,
              marginLeft: "30%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  ml: 2,
                  pr: 2,
                }}
              >
                ID: {data}
              </Typography>
              Name:
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                // fullWidth
                sx={{
                  border: "none",
                  "& fieldset": {
                    border: "none",
                  },
                  backgroundColor: "white",
                }}
              />{" "}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  ml: 2,
                  pr: 2,
                  display: "flex",
                }}
              >
                Subject:
              </Typography>{" "}
              <TextField
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                fullWidth
                sx={{
                  border: "none",
                  "& fieldset": {
                    border: "none",
                  },
                }}
              />{" "}
              <ContactMailIcon
                sx={{
                  color: "rgb(199, 197, 197)",
                }}
              />{" "}
            </Box>{" "}
            <TextField
              value={body}
              onChange={(e) => setBody(e.target.value)}
              fullWidth
              multiline
              rows="13"
              sx={{
                border: "none",
                borderTop: "1px solid #E7E7E7",
                "& fieldset": {
                  border: "none",
                },
                padding: "7.5px 4px",
              }}
            />{" "}
            <Button
              sx={{
                float: "right",
              }}
              onClick={changeWarmFunction}
              id="submit"
            >
              Submit
            </Button>
            <Button
              sx={{
                float: "right",
                visibility: "hidden",
              }}
              onClick={CreateWarmupFunction}
              id="create"
            >
              Create
            </Button>
            <Button
              sx={{
                float: "right",
              }}
              onClick={DeleteWarmupFunction}
              id="delete"
            >
              Delete Template
            </Button>
          </Box>
        )}
        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleErrorSnackbarClose}
        >
          <Alert
            onClose={handleErrorSnackbarClose}
            variant="filled"
            severity="error"
            sx={{
              width: "100%",
            }}
          >
            {errorMsg}{" "}
          </Alert>{" "}
        </Snackbar>{" "}
        <Snackbar
          open={successSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSuccessSnackbarClose}
        >
          <Alert
            onClose={handleSuccessSnackbarClose}
            variant="filled"
            severity="success"
            sx={{ width: "100%" }}
          >
            {successMsg}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}

export default WarmupTamplet;