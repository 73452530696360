import { Button, Card, Grid, CircularProgress, Snackbar, Alert } from '@mui/material';
import React, { useState } from 'react';
import { StyledTextField } from '../../../stylings/styles';
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL
// import { TextField, Button, Grid } from '@material-ui';

const MyForm = (props) => {
    const [firstName, setFirstName] = useState(props.editFormData ? props.editFormData['Fname'] : '');
    const [lastName, setLastName] = useState(props.editFormData ? props.editFormData['Lname'] : '');
    const [phone, setPhone] = useState(props.editFormData ? props.editFormData['phone'] : '');
    const [website, setWebsite] = useState(props.editFormData ? props.editFormData['website'] : "");
    const [company, setCompany] = useState(props.editFormData ? props.editFormData['conmapny'] : '');
    const [email, setEmail] = useState(props.editFormData ? props.editFormData['email'] : '');
    const [submitLoding, setSubmitLoding] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false)
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(null)

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handleWebsiteChange = (event) => {
        setWebsite(event.target.value);
    };
    const handleCompanyChange = (event) => {
        setCompany(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorSnackbarOpen(false);
    }
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessSnackbarOpen(false);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitLoding(true)
        const data = {
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            website: website,
            company: company,
            email: email
        }
        // Handle form submission here
        fetch(`${REACT_APP_SERVER_URL}/update_lead`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "campaign": {
                    'campaign_id': props.editFormData['campaign_idcampaign'],
                    "data": data
                }
            })
        }).then(response => {
            if (response.status === 200) {
                console.log(response)
                setSubmitLoding(false)
                props.setSuccessSnackbarOpen(true)
                props.setEditButtonClicked(false)
                return response.json()
            }
            else {
                setSubmitLoding(false)
                setErrorSnackbarOpen(true)
                props.setEditButtonClicked(false)
                return response.json()

            }
        })
            .then(data => {
                setErrorMessage(data.msg)
                props.setSuccessMessage(data.msg)
            })
            .catch(error => console.log(error))
    };

    return (
        <>
            <Card sx={{ maxWidth: "500px", p: 3, margin: "auto" }}>
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <StyledTextField
                                label="Email"
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                fullWidth
                                required
                                inputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                label="First Name"
                                value={firstName}
                                onChange={handleFirstNameChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                label="Last Name"
                                value={lastName}
                                onChange={handleLastNameChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                label="contact no."
                                value={phone}
                                onChange={handlePhoneChange}
                                fullWidth

                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                label="website"
                                value={website}
                                onChange={handleWebsiteChange}
                                fullWidth

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                label="Company"
                                value={company}
                                onChange={handleCompanyChange}
                                fullWidth

                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                color="error"
                                style={{ color: 'white', }}
                                variant="contained"
                                onClick={() => props.setEditButtonClicked(false)}
                                fullWidth
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                fullWidth
                                disabled={submitLoding}
                            >
                                {
                                    submitLoding && <CircularProgress size={25} />
                                }
                                {
                                    !submitLoding && "Submit"
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Card>
            <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default MyForm;
