import React from 'react'
import { ThemeProvider } from "@mui/material/styles";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BoltIcon from '@mui/icons-material/Bolt';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Grid, Card, Button, Tab, Tabs, Switch, Typography, Divider, Stack, CircularProgress, Snackbar, Alert } from '@mui/material'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cookie from 'cookiejs';
import jwt from 'jwt-decode';
import { StyledButtonOutlined, colorTheme } from '../../stylings/styles';
import { makeStyles } from '@mui/styles';
import Invoices from './Invoices';

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const useStyles = makeStyles({
    customStyleOnTab: {
        color: "#4F4F4F",
        fontWeight: 700,
    },
    customStyleOnActiveTab: {
        background: "none",
    },
    activeTab: {
        fontWeight: 700,
        backgroundColor: "#fff",
        borderRadius: "6px",
        color: "#481BEB",
    },
});

const Subscriptions = () => {
    const user = jwt(cookie.get("token"));
    const navigate = useNavigate();
    const classes = useStyles();
    const [tabValue, setTabValue] = React.useState(0);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [priceValidation, setPriceValidation] = useState("/month");
    const [toggle, setToggle] = useState(false);
    const [SubscriptionsTime, setSubscriptionsTime] = useState("monthly");
    const [homeLoading, setHomeLoading] = useState(false);
    const [data, setData] = useState([]);
    const [existData, setExistData] = useState([]);
    const [checked, setChecked] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false)
    const [successMessage, setSuccessMessage] = React.useState('');
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue)
    }

    if (user.admin) {
        navigate("/login")
    }



    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorSnackbarOpen(false);
    }
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessSnackbarOpen(false);
    }

    React.useEffect(() => {

        setHomeLoading(true)
        fetch(`${REACT_APP_SERVER_URL}/planDetails`, {
            headers: {
                'Accept': 'application/json',
            },
            credentials: "include",
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                setHomeLoading(false)
                return response.json()
            } else {
                setHomeLoading(false)
                return response.json()
            }
        }).then(d => {
            console.log(d);
            if (d.status === 200) {
                setExistData(d.data)
                if (d.data[0].reoccurring === true) {
                    setChecked(true);
                }
            } else if (d.status === 201) {
                setExistData(d.data)
            } else {
                setErrorMessage(d.msg);
                setErrorSnackbarOpen(true);
            }
        })

    }, [])

    React.useEffect(() => {
        setHomeLoading(true)
        fetch(`${REACT_APP_SERVER_URL}/getprice`, {
            headers: {
                'Accept': 'application/json',
            },
            credentials: "include",
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                setHomeLoading(false)
                return response.json()
            }
            else if (response.status === 202) {
                setHomeLoading(false)
                console.log("token expired")

            }
            else {
                setHomeLoading(false)
                console.log("some error")
            }
        })
            .then(data => {
                if (data.status === 401) {
                    setErrorMessage(data.msg);
                    setErrorSnackbarOpen(true);
                }
                console.warn("this is geting ", data);
                setData(data.data)
            })
            .catch(error => console.log(error))
    }, [])

    const priceFunction = (e) => {
        console.warn(e);
        setPaymentLoading(true)
        fetch(`${REACT_APP_SERVER_URL}/payment`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify(e)
        }).then(response => {
            if (response.status === 200) {
                setPaymentLoading(false);
                console.log("Success")
                return response.json()
            }
            else if (response.status === 202) {
                console.log("token expired")
            }
            else {
                console.log("some error")
            }
        })
            .then(data => {
                setPaymentLoading(false);
                window.location.replace(data.data);
                console.warn("data collected", data.data)
            })
            .catch(error => console.log(error));
    }

    const handleChange = (e) => {
        console.log(e.target.checked)
        setChecked(e.target.checked);
        fetch(`${REACT_APP_SERVER_URL}/reoccuring`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                reoccur: e.target.checked
            })
        })
            .then((resp) => {
                return resp.json();
            })
            .then((d) => {
                if (d.status === 200) {
                    setSuccessSnackbarOpen(true);
                    if (e.target.checked === true) {
                        setSuccessMessage('Auto payment turned on');
                    }
                    else {
                        setSuccessMessage('Auto payment turned off');
                    }
                } else {
                    console.log('Not Okay')
                    setErrorSnackbarOpen(true)
                    setErrorMessage('Something went wrong ')
                }
            })
            .catch((error) => {
                console.log(error);
                setErrorSnackbarOpen(true)
                setErrorMessage('Something went wrong ')
            })

    }

    function Date(data) {
        let a = data;
        let b = `${a[8] + a[9]}-${a[5] + a[6]}-${a[0] + a[1] + a[2] + a[3]}`;

        return b;
    }

    return (
        <ThemeProvider theme={colorTheme}>
            {

                homeLoading && data ? <>
                    <Box sx={{ display: "flex", justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                </> : <Box>
                    <Box sx={{ width: "100%", borderRadius: "10px", backgroundColor: "#f7f7f9", display: "flex", gap: "10px", }}>
                        <Tabs value={tabValue}
                            variant="fullWidth"
                            onChange={handleTabChange}
                            classes={{ indicator: classes.customStyleOnActiveTab }}
                            indicatorColor={'#607d8b'}
                            centered
                        >
                            <Tab
                                disableRipple sx={{ padding: '10px 10px' }}
                                label={<div style={{ fontSize: '14px', textTransform: "none", display: "flex", alignItems: "center", justifyContent: 'center', width: "100px", height: "50px" }}
                                    className={tabValue === 0 ? classes.activeTab : classes.customStyleOnTab}><span>PLAN</span></div>}
                            />
                            <Tab
                                disableRipple sx={{ padding: '10px 10px' }}
                                label={<div style={{ fontSize: '14px', textTransform: "none", display: "flex", alignItems: "center", justifyContent: 'center', width: "100px", height: "50px" }}
                                    className={tabValue === 1 ? classes.activeTab : classes.customStyleOnTab}><span>INVOICE</span></div>}
                            />
                        </Tabs>
                        {/* <StyledButtonOutlined variant='contained' sx={{ fontSize: '14px', borderRadius: "6px", color: "#481BEB", backgroundColor: '#fff', padding: '10px 50px', boxShadow: "none", fontWeight: '700', 
                            "&:hover": {
                                backgroundColor: "#E7E7E7",
                                boxShadow: 'none',
                            },
                        }}>
                            Plan
                        </StyledButtonOutlined> */}
                        {/* <StyledButtonOutlined variant='contained' sx={{ fontSize: '14px', borderRadius: "6px", color: "#4F4F4F", backgroundColor: 'transparent', padding: '10px 50px', boxShadow: "none", fontWeight: '700', 
                            "&:hover": {
                                backgroundColor: "#E7E7E7",
                                boxShadow: 'none',
                            },
                        }}>
                            Invoices
                        </StyledButtonOutlined> */}
                    </Box>

                    {
                        tabValue === 0 &&
                        <Box sx={{ pt: 2 }}>
                            {
                                existData.length !== 0 &&
                                <Box sx={{ width: "100%", height: "100%", backgroundColor: "#fafafa", boxShadow: "5px 5px 10px #f2f0f0", borderRadius: '10px', p: 4 }}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: "700", pb: 1 }}>Current Plan</Typography>

                                    {existData.map((data, i) => (
                                        <Box key={i} sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                                            <Card sx={{ width: "15x0px", height: "85px", boxShadow: "none", borderRadius: "10px", display: "flex", justifyContent: 'center', alignItems: "center", px: 2, my: 1 }}>
                                                <Grid container alignItems="center" display="flex" direction="column" gap="10px">
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Typography sx={{ width: "100px", fontSize: "12px", color: "#7D7D7D" }}>User Email</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", justifyContent: 'end' }}>
                                                        <Box sx={{ backgroundColor: "rgba(56, 198, 139, 0.1)", borderRadius: "8px", height: '40px', px: 2, display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", }}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#38C68B" }}>{data.email}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                            <Card sx={{ width: "15x0px", height: "85px", boxShadow: "none", borderRadius: "10px", display: "flex", justifyContent: 'center', alignItems: "center", px: 2, my: 1 }}>
                                                <Grid container alignItems="center" display="flex" direction="column" gap="10px">
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Typography sx={{ width: "100px", fontSize: "12px", color: "#7D7D7D" }}>Max Email</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", justifyContent: 'end' }}>
                                                        <Box sx={{ backgroundColor: "rgba(56, 198, 139, 0.1)", borderRadius: "8px", height: '40px', px: 2, display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", }}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#38C68B" }}>{data.maxEmail}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                            <Card sx={{ width: "15x0px", height: "85px", boxShadow: "none", borderRadius: "10px", display: "flex", justifyContent: 'center', alignItems: "center", px: 2, my: 1 }}>
                                                <Grid container alignItems="center" display="flex" direction="column" gap="10px">
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Typography sx={{ width: "100px", fontSize: "12px", color: "#7D7D7D" }}>Max Lead</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", justifyContent: 'end' }}>
                                                        <Box sx={{ backgroundColor: "rgba(56, 198, 139, 0.1)", borderRadius: "8px", height: '40px', px: 2, display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", }}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#38C68B" }}>{data.maxLead}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                            <Card sx={{ width: "15x0px", height: "85px", boxShadow: "none", borderRadius: "10px", display: "flex", justifyContent: 'center', alignItems: "center", px: 2, my: 1 }}>
                                                <Grid container alignItems="center" display="flex" direction="column" gap="10px">
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Typography sx={{ width: "100px", fontSize: "12px", color: "#7D7D7D" }}>End Date</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", justifyContent: 'end' }}>
                                                        <Box sx={{ backgroundColor: "rgba(56, 198, 139, 0.1)", borderRadius: "8px", height: '40px', px: 2, display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", }}>
                                                            <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#38C68B" }}>{Date((data.endDate).slice(0, 10))}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                            <Card sx={{ width: "15x0px", height: "85px", boxShadow: "none", borderRadius: "10px", display: "flex", justifyContent: 'center', alignItems: "center", px: 2, my: 1 }}>
                                                <Grid container alignItems="center" display="flex" direction="column" gap="10px">
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <Typography sx={{ width: "100px", fontSize: "12px", color: "#7D7D7D" }}>Reoccurring</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", justifyContent: 'end' }}>
                                                        <Box sx={{ backgroundColor: "rgba(56, 198, 139, 0.1)", borderRadius: "8px", height: '40px', width: "80px", display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", }}>
                                                            <Switch
                                                                checked={checked}
                                                                onChange={handleChange}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                color='success' sx={{ float: "left" }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Box>
                                    ))}
                                </Box>
                            }
                            {
                                existData.length === 0 && <Typography sx={{ fontSize: "35px", fontWeight: "400", textAlign: 'center', background: 'blue', color: 'white' }}>No existing plans</Typography>
                            }

                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", my: 4 }}>
                                <Typography sx={{ fontSize: "1.1rem", fontWeight: "700", color: '#272727' }}>
                                    Simple, transparent pricing that grows with you. Try any plan free for 30 days.
                                </Typography>
                                <Box sx={{ fontSize: "16px", fontWeight: 500, display: "flex", justifyContent: "center", alignItems: "center", my: 1 }}>
                                    <Switch onClick={() => {
                                        if (!toggle) {
                                            setSubscriptionsTime('yearly');
                                            setPriceValidation("/year");
                                        } else {
                                            setSubscriptionsTime('monthly');
                                            setPriceValidation("/month");
                                        }
                                        setToggle(!toggle);
                                    }} color='success' sx={{ float: "left", color: '#38C68B' }} />
                                    <Typography sx={{ color: "#7D7D7D", float: "left", mr: 1 }}>
                                        Annual Pricing
                                    </Typography>
                                    <Typography sx={{ color: "#38C68B", float: "left" }}>
                                        (save 20%)
                                    </Typography>
                                </Box>
                            </Box>
                            {paymentLoading ?
                                <Box sx={{ width: "100%", display: "flex", gap: '10px', alignItems: 'center', justifyContent: "center" }}>
                                    <CircularProgress /> Payment Page loading...
                                </Box>
                                :
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                        <Card sx={{ display: "flex", justifyContent: "start", flexDirection: 'column', p: 4, boxShadow: "0 2px 11px 0px #0000001a", borderRadius: '36px', }}>
                                            <Typography sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px", fontWeight: "700", width: "40px", height: "40px", backgroundColor: "rgba(242, 219, 5,0.2)", border: "1px solid rgb(227, 208, 14)", borderRadius: "50%", color: "rgb(227, 208, 14)" }}>
                                                <AutoAwesomeIcon />
                                            </Typography>
                                            <Typography sx={{ color: "#481BEB", fontSize: "1.3rem", fontWeight: "500", mt: 1, }}>
                                                Startup
                                            </Typography>
                                            <Typography sx={{ fontSize: "45px", fontWeight: "700", color: '#272727', fontFamily: "'HK Nova', sans-serif", }}>
                                                Free
                                            </Typography>
                                            <Typography sx={{ fontSize: "14px", fontWeight: "400", color: "#7D7D7D", }}>
                                                For personal use only
                                            </Typography>
                                            <Divider sx={{ my: 2 }} />
                                            <Stack spacing={1}>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Unlimited editors
                                                </Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Onboarding support
                                                </Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Custom branding
                                                </Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Unlimited projects
                                                </Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Up to 10 integrations
                                                </Typography>
                                            </Stack>
                                            <StyledButtonOutlined variant='outlined' sx={{ fontSize: '14px', fontWeight: '700', fontFamily: "'HK Nova', sans-serif", mt: 3, padding: '10px' }}>
                                                Get Started
                                            </StyledButtonOutlined>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                        <Card sx={{ display: "flex", justifyContent: "start", flexDirection: 'column', p: 4, boxShadow: "0 2px 11px 0px #0000001a", borderRadius: '36px', }}>
                                            <Typography sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px", fontWeight: "700", width: "40px", height: "40px", backgroundColor: "rgba(242, 0, 0,0.2)", border: "1px solid red", borderRadius: "50%", color: "red" }}>
                                                <FavoriteIcon />
                                            </Typography>
                                            <Typography sx={{ color: "#481BEB", fontSize: "1.3rem", fontWeight: "500", mt: 1, }}>
                                                {data[SubscriptionsTime] && data[SubscriptionsTime][1] && data[SubscriptionsTime][1]["name"]}
                                            </Typography>
                                            <Typography sx={{ fontSize: "45px", fontWeight: "700", color: '#272727', fontFamily: "'HK Nova', sans-serif", display: "flex", alignItems: "center" }}>
                                                ${data[SubscriptionsTime] && data[SubscriptionsTime][0] && data[SubscriptionsTime][0]["unit_amount"]}
                                                <Typography sx={{ color: '#989898', }}>
                                                    {priceValidation}
                                                </Typography>
                                            </Typography>
                                            <Typography sx={{ fontSize: "14px", fontWeight: "400", color: "#7D7D7D", }}>
                                                For personal use only
                                            </Typography>
                                            <Divider sx={{ my: 2 }} />
                                            <Stack spacing={1}>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Unlimited editors
                                                </Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Onboarding support
                                                </Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Custom branding
                                                </Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Unlimited projects
                                                </Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Up to 10 integrations
                                                </Typography>
                                            </Stack>
                                            <Button
                                                onClick={() => priceFunction(data[SubscriptionsTime][0])}
                                                variant='contained'
                                                sx={{ backgroundColor: "#481BEB", fontSize: '14px', fontWeight: '700', fontFamily: "'HK Nova', sans-serif", mt: 3, padding: '10px' }}
                                            >
                                                Get Started
                                            </Button>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                        <Card sx={{ display: "flex", justifyContent: "start", flexDirection: 'column', p: 4, boxShadow: "0 2px 11px 0px #0000001a", borderRadius: '36px', }}>
                                            <Typography sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px", fontWeight: "700", width: "40px", height: "40px", backgroundColor: "rgba(0, 0, 225,0.2)", border: "1px solid blue", borderRadius: "50%", color: "blue" }}>
                                                <BoltIcon />
                                            </Typography>
                                            <Typography sx={{ color: "#481BEB", fontSize: "1.3rem", fontWeight: "500", mt: 1, }}>
                                                {data[SubscriptionsTime] && data[SubscriptionsTime][0] && data[SubscriptionsTime][0]["name"]}
                                            </Typography>
                                            <Typography sx={{ fontSize: "45px", fontWeight: "700", color: '#272727', fontFamily: "'HK Nova', sans-serif", display: "flex", alignItems: "center" }}>
                                                ${data[SubscriptionsTime] && data[SubscriptionsTime][1] && data[SubscriptionsTime][1]["unit_amount"]}
                                                <Typography sx={{ color: '#989898', }}>
                                                    {priceValidation}
                                                </Typography>
                                            </Typography>

                                            <Typography sx={{ fontSize: "14px", fontWeight: "400", color: "#7D7D7D", }}>
                                                For personal use only
                                            </Typography>
                                            <Divider sx={{ my: 2, color: '#E7E7E7' }} />
                                            <Stack spacing={1}>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Unlimited editors
                                                </Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Onboarding support
                                                </Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Custom branding
                                                </Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Unlimited projects
                                                </Typography>
                                                <Typography sx={{ fontSize: "16px", fontWeight: "400", color: '#272727', fontFamily: "'Inter', sans-serif" }}>
                                                    <DoneIcon sx={{ color: "#3D17C6", marginRight: 1 }} /> Up to 10 integrations
                                                </Typography>
                                            </Stack>

                                            <StyledButtonOutlined onClick={() => priceFunction(data[SubscriptionsTime][1])} variant='outlined' sx={{ fontSize: '14px', fontWeight: '700', fontFamily: "'HK Nova', sans-serif", mt: 3, padding: '10px' }}>
                                                Get Started
                                            </StyledButtonOutlined>
                                        </Card>
                                    </Grid>
                                </Grid>}
                        </Box>
                    }
                    {
                        tabValue === 1 &&
                        <Box sx={{ minHeight: "400px", p: 0, mt: 3, }}>
                            <Invoices id={'1'} />
                        </Box>
                    }
                </Box>}
            <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    )
}

export default Subscriptions