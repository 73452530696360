import React, { useState } from "react";
import {
  Box, Stack, Table, Typography, Avatar, TableBody, IconButton, Paper, InputBase, TableHead, TableRow, Chip, Grid, FormControl, TextField, Button, Dialog, DialogContent, DialogTitle, DialogActions, Snackbar, Alert, Card, Switch, Container, CircularProgress, MenuItem, InputLabel, Select,
} from "@mui/material";
import { styled, ThemeProvider } from '@mui/material/styles';
import SendIcon from "@mui/icons-material/Send";
import ErrorIcon from "@mui/icons-material/Error";
import InboxIcon from "@mui/icons-material/Inbox";
import ReplyIcon from "@mui/icons-material/Reply";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import InfoIcon from "@mui/icons-material/Info";
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate, useLocation } from "react-router-dom";
import { withStyles } from "@mui/styles";
import google from "../../img/icons/google.png";
import imap_smtp from "../../img/icons/imap_smtp.png";
import filterIcon from "../../img/icons/filter.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import { StyledButtonOutlined, StyledTableCell, StyledTableContainer, StyledTableRow, StyledTextField, StyledTypography, colorTheme } from "../../stylings/styles";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const REACT_APP_GOOGLEAUTH_KEY = process.env.REACT_APP_GOOGLEAUTH_KEY;

const StyledBox = styled(Box)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#2196F3",
  },
}));
const StyledButton = withStyles({
  root: {
    backgroundColor: "#fff",
    border: "1px solid #E7E7E7",
    "&:hover": {
      backgroundColor: "#fff",
      boxShadow: "0px 8px 40px rgba(4, 0, 49, 0.16)",
    },
  },
})(Button);

const IconWithText = ({ text, color, figure }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    {text === "Health" && <StopRoundedIcon style={{ color }} />}
    <Typography
      sx={{
        fontSize: "12px",
        fontWeight: "500",
        color: "#181818",
      }}
    >
      {text === "Health" ? 'Good' : figure}
    </Typography>
  </div>
);

// const mdTheme = createTheme({
//   palette: {
//     success: {
//       main: "#38C68B",
//     },
//   },
// });

const EmailAccounts = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [visible, setVisible] = useState(false);
  const [filtervalue, setFiltervalue] = useState("All");
  const [searchValue, setSearchValue] = React.useState("");
  const [filterValue, setFilterValue] = React.useState([]);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [createdAtDate, setCreatedAtDate] = React.useState("dd-mm-yyyy");
  const [settingAnalyticsData, setSettingAnalyticsData] = React.useState(null);
  const [settingDrawer, setSettingDrawer] = React.useState(false);
  const [settingDrawerData, setSettingDrawerData] = React.useState(null);
  const [settingProfileData, setSettingProfileData] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  const [addNewOpen, setAddNewOpen] = React.useState(false);
  const [googleOauth, setGoogleOauth] = React.useState(false);
  const [imap, setImap] = React.useState(false);
  const [imapSteps, setImapSteps] = React.useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [smtpHost, setSmtpHost] = React.useState("");
  const [smtpPort, setSmtpPort] = React.useState(587);
  const [imapHost, setImapHost] = React.useState("");
  const [imapPort, setImapPort] = React.useState(993);
  const [newDataAdd, setNewDataAdd] = React.useState(false);
  const [smtpUsername, setSmtpUsername] = React.useState("");
  const [smtpPassword, setSmtpPassword] = React.useState("");
  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(
    state === null ? false : state.status !== 200
  );
  const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(
    state === null ? false : state.status === 200
  );

  const [homeLoading, setHomeLoading] = React.useState(false);
  const [change, setChange] = React.useState(false);
  const [cronjobToggle, setCronjobToggle] = React.useState(false);
  const [addNewErrorMessage, setAddNewErrorMessage] = React.useState(
    state === null ? "" : state.msg
  );
  const [addNewSuccessMessage, setAddNewSuccessMessage] = React.useState(
    state === null ? "" : state.msg
  );
  const [googleOauthSteps, setGoogleOauthSteps] = React.useState(0);
  const [googleConnectTypes, setGoogleConnectTypes] = React.useState("oAuth");
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [removeAccountDialog, setRemoveAccountDialog] = React.useState(false);
  const [accounctDeleteSuccess, setAccounctDeleteSuccess] =
    React.useState(false);
  console.log(state);
  const [errorMsg, seterrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");

  React.useEffect(() => {
    setHomeLoading(true);
    fetch(`${REACT_APP_SERVER_URL}/warmuptest`, {
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
      method: "GET",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 202) {
          console.log("token expired");
          navigate("/login", { state: { sessionExpired: true } });
        } else {
          console.log("some error");
        }
      })
      .then((data) => {
        setHomeLoading(false);
        setTableData(data);
        console.log(data);
      })
      .catch((error) => console.log(error));
  }, [newDataAdd, navigate]);

  const handleStartingVolume = (e) => {
    let tempSettingData = settingDrawerData;
    tempSettingData.starting_volume = parseInt(e.target.value);
    setSettingDrawerData(tempSettingData);
    setChange(!change);
  };

  function getStatusDetails(e) {
    let msg = settingAnalyticsData.msg;
    setErrorSnackbarOpen(true);
    seterrorMsg(msg);
  }
  const handleEndingingVolume = (e) => {
    let tempSettingData = settingDrawerData;
    tempSettingData.max_par_day = parseInt(e.target.value);
    setSettingDrawerData(tempSettingData);
    setChange(!change);
  };

  const handleReplyPercentage = (e) => {
    let tempSettingData = settingDrawerData;
    tempSettingData.reply_rate = parseInt(e.target.value);
    setSettingDrawerData(tempSettingData);
    setChange(!change);
  };

  const handleIncreasePerDay = (e) => {
    let tempSettingData = settingDrawerData;
    tempSettingData.increase_per_day = parseInt(e.target.value);
    setSettingDrawerData(tempSettingData);
    setChange(!change);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackbarOpen(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorSnackbarOpen(false);
  };
  const handleOpen = () => {
    setAddNewOpen(true);
  };
  const handleClose = () => {
    setAddNewOpen(false);
    setGoogleOauth(false);
    setImap(false);
    setLastName("");
    setFirstName("");
    setEmail("");
    setPassword("");
    setSmtpUsername("");
    setSmtpPassword("");
    setSmtpHost("");
    setSmtpPort(587);
    setImapHost("");
    setImapPort(993);
    setGoogleOauthSteps(0);
    setImapSteps(0);
  };
  const handleSelectAnotherProvider = () => {
    setGoogleOauth(false);
    setImap(false);
    setEmail("");
    setPassword("");
    setSmtpUsername("");
    setSmtpPassword("");
    setSmtpHost("");
    setSmtpPort(587);
    setImapHost("");
    setImapPort(993);
    setGoogleOauthSteps(0);
    setImapSteps(0);
  };
  const addNewDataSubmitHandler = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    if (imap) {
      fetch(`${REACT_APP_SERVER_URL}/Addcampaign`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        credentials: "include",
        body: new URLSearchParams({
          fname: firstName,
          lname: lastName,
          email: email,
          smtp_user: smtpUsername,
          smtp_password: smtpPassword,
          password: password,
          smtp_host: smtpHost,
          smtp_port: smtpPort,
          imap_host: imapHost,
          imap_port: imapPort,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            setNewDataAdd(!newDataAdd);
            setSuccessSnackbarOpen(true);
            setsuccessMsg("accound added");
            setButtonLoading(false);
            handleClose();
            return response.json();
          } else if (response.status === 202) {
            console.log("token expired");
            navigate("/login", { state: { sessionExpired: true } });
          } else {
            setButtonLoading(false);
            handleClose();
            setErrorSnackbarOpen(true);
            return response.json();
          }
        })
        .then((data) => {
          if (data) {
            console.log(data);
            setAddNewErrorMessage(data.msg);
          }
        })
        .catch((err) => console.error(err));
    }
    if (googleOauth) {
      fetch(`${REACT_APP_SERVER_URL}/Addcampaign`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        credentials: "include",
        body: new URLSearchParams({
          fname: firstName,
          lname: lastName,
          email: email,
          smtp_user: smtpUsername,
          smtp_password: smtpPassword,
          password: password,
          smtp_host: "smtp.gmail.com",
          smtp_port: smtpPort,
          imap_host: "imap.gmail.com",
          imap_port: imapPort,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            setNewDataAdd(!newDataAdd);
            setSuccessSnackbarOpen(true);
            setButtonLoading(false);
            handleClose();
            return response.json();
          } else if (response.status === 202) {
            console.log("token expired");
            navigate("/login", { state: { sessionExpired: true } });
          } else {
            setButtonLoading(false);
            handleClose();
            setErrorSnackbarOpen(true);
            return response.json();
          }
        })
        .then((data) => {
          if (data) {
            console.log(data);
            setAddNewErrorMessage(data.msg);
          }
        })
        .catch((err) => console.error(err));
    }
  };
  const toggleSettingDrawerOpen = (id) => {
    var settingsIndex = getSettingIndex(id);
    var profileIndex = getProfileIndex(id);
    var analyticsIndex = getAnalyticsIndex(id);

    if (tableData?.warmup_analytics[settingsIndex].status === "Error") {
      setVisible(true);
    } else {
      setVisible(false);
    }

    setSettingDrawerData(tableData?.warmup_setting[settingsIndex]);
    const dateString = tableData?.warmup_profile[profileIndex].createdAt;
    if (dateString) {
      const date = new Date(dateString);
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1; // Months are zero-based, so we add 1
      const day = date.getUTCDate();
      const formattedDate = `${day.toString().padStart(2, "0")}-${month
        .toString()
        .padStart(2, "0")}-${year}`;
      setCreatedAtDate(formattedDate);
      console.log(formattedDate);
    } else {
      setCreatedAtDate("dd-mm-yyyy");
    }

    setSettingProfileData(tableData?.warmup_profile[profileIndex]);

    if (settingProfileData !== null) {
      settingProfileData.fname = settingProfileData.fname
        ? settingProfileData.fname
        : "";
      settingProfileData.lname = settingProfileData.lname
        ? settingProfileData.lname
        : "";
    }

    setSettingAnalyticsData(tableData?.warmup_analytics[analyticsIndex]);
    setSettingDrawer(true);
  };
  console.log(settingDrawerData, settingProfileData, settingAnalyticsData);

  const toggleSettingDrawerClose = () => {
    if (
      (settingProfileData.fname).length >= 3 &&
      (settingProfileData.lname).length >= 0 &&
      settingDrawerData.increase_per_day >=
      process.env.REACT_APP_increase_per_day_lower_limit &&
      settingDrawerData.increase_per_day <=
      process.env.REACT_APP_increase_per_day_upper_limit &&
      settingDrawerData.reply_rate >=
      process.env.REACT_APP_reply_rate_lower_limit &&
      settingDrawerData.reply_rate <=
      process.env.REACT_APP_reply_rate_upper_limit &&
      settingDrawerData.starting_volume >=
      process.env.REACT_APP_startingVolume_lower_limit &&
      settingDrawerData.starting_volume <=
      process.env.REACT_APP_startingVolume_upper_limit &&
      settingDrawerData.max_par_day >
      process.env.REACT_APP_endingVolume_lower_limit &&
      settingDrawerData.max_par_day <=
      process.env.REACT_APP_endingVolume_upper_limit
    ) {
      setButtonLoading(true);
      fetch(`${REACT_APP_SERVER_URL}/warmup_setting`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        credentials: "include",
        body: new URLSearchParams({
          sender_idsender: settingDrawerData.sender_idsender,
          fname: settingProfileData.fname,
          lname: settingProfileData.lname,
          endingVolume: settingDrawerData.max_par_day,
          startingVolume: settingDrawerData.starting_volume,
          increase_per_day: settingDrawerData.increase_per_day,
          reply_rate: settingDrawerData.reply_rate,
          current_day_limit: settingDrawerData.current_day_limit,
        }),
      })
        .then((response) => {
          console.log(response, "<<Responese Msg");
          if (response.status === 200) {
            setButtonLoading(false);
            setSettingDrawer(false);
            setSuccessSnackbarOpen(true);
            setsuccessMsg("Profile Updated");
            return response.json();
          } else if (response.status === 202) {
            console.log("token expired");
            navigate("/login", { state: { sessionExpired: true } });
          }
        })
        .catch((err) => console.error(err));
    } else {
      setErrorSnackbarOpen(true);
      seterrorMsg("Invalid Forms Check Again the Input Fields.");
      return;
    }
  };
  const removeAccountHandler = () => {
    setButtonLoading(true);
    setAccounctDeleteSuccess(true);
    fetch(`${REACT_APP_SERVER_URL}/deleteaccount`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ email: settingDrawerData["sender_email"] }),
    })
      .then((response) => {
        if (response.status === 200) {
          setSettingDrawer(false);
          setNewDataAdd(!newDataAdd);
          removeAccountDialog(false);
          setButtonLoading(false);
          setAccounctDeleteSuccess(false);
          setSuccessSnackbarOpen(true);
          setsuccessMsg("Account deleted");
          return response.json();
        } else if (response.status === 202) {
          console.log("token expired");
          navigate("/login", { state: { sessionExpired: true } });
        } else {
          setButtonLoading(false);
          setAccounctDeleteSuccess(false);
          removeAccountDialog(false);
        }
      })
      .catch((err) => console.error(err));
  };
  const toggleCronjobSetting = (email, active, index) => {
    if (active === 1) {
      console.log("stop api");
      fetch(`${REACT_APP_SERVER_URL}/cronejob-stop/${email}`, {
        headers: {
          Accept: "application/json",
        },
        method: "GET",
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) {
            let tempTableData = tableData;
            tempTableData.warmup_setting[index].enable = 0;
            tempTableData.warmup_analytics[index].status = "paused";
            setTableData(tempTableData);
            setCronjobToggle(!cronjobToggle);
            return response.json();
          } else if (response.status === 202) {
            console.log("token expired");
            navigate("/login", { state: { sessionExpired: true } });
          }
        })
        .catch((err) => console.error(err));
    } else {
      console.log("start api");
      fetch(`${REACT_APP_SERVER_URL}/cronejob-start/${email}`, {
        headers: {
          Accept: "application/json",
        },
        method: "GET",
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) {
            let tempTableData = tableData;
            tempTableData.warmup_setting[index].enable = 1;
            tempTableData.warmup_analytics[index].status = "Active";
            setTableData(tempTableData);
            setCronjobToggle(!cronjobToggle);
            return response.json();
          } else if (response.status === 202) {
            console.log("token expired");
            navigate("/login", { state: { sessionExpired: true } });
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const getProfileIndex = (sender_idsender) => {
    var ind = -1;
    for (ind = 0; ind < tableData.warmup_profile?.length; ind++) {
      if (sender_idsender === tableData.warmup_profile[ind].idsender) break;
    }
    return ind;
  };

  const getSettingIndex = (sender_idsender) => {
    var ind = -1;
    for (ind = 0; ind < tableData.warmup_setting?.length; ind++) {
      if (sender_idsender === tableData.warmup_setting[ind].sender_idsender)
        break;
    }
    return ind;
  };
  const getAnalyticsIndex = (sender_idsender) => {
    var ind = -1;
    for (ind = 0; ind < tableData.warmup_analytics?.length; ind++) {
      if (sender_idsender === tableData.warmup_analytics[ind].sender_idsender)
        break;
    }
    return ind;
  };
  const googleOauthHandler = () => {
    fetch(`${REACT_APP_SERVER_URL}/googlereact`, {
      headers: {
        Accept: "application/json",
      },
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 202) {
          console.log("token expired");
          navigate("/login", { state: { sessionExpired: true } });
        } else {
          setErrorSnackbarOpen(true);
          return response.json();
        }
      })
      .then((data) => window.open(data.data, "_self"))
      .catch((err) => console.error(err));
  };
  const smtpDataCheckHandler = () => {
    setButtonLoading(true);
    fetch(`${REACT_APP_SERVER_URL}/AddaccountSMTP`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        email: email,
        smtp_user: smtpUsername,
        smtp_password: smtpPassword,
        smtp_host: smtpHost,
        smtp_port: smtpPort,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          setButtonLoading(false);
          imapForwardStepsHandler();
          return response.json();
        } else if (response.status === 202) {
          console.log("token expired");
          navigate("/login", { state: { sessionExpired: true } });
        } else {
          setButtonLoading(false);
          return response.json();
        }
      })
      .then((data) => {
        console.log(data);
        if (data.status === 201) {
          setErrorSnackbarOpen(true);
          seterrorMsg(data.msg);
        }
      })
      .catch((err) => console.error(err));
  };
  const googleForwardStepsHandler = () => {
    setGoogleOauthSteps(googleOauthSteps + 1);
  };
  const googleBackStepsHandler = () => {
    setGoogleOauthSteps(googleOauthSteps - 1);
  };
  const googleConnectTypeChangeHandler = (type) => {
    setGoogleConnectTypes(type);
  };
  const imapForwardStepsHandler = () => {
    setImapSteps(imapSteps + 1);
  };
  const imapBackStepsHandler = () => {
    setImapSteps(imapSteps - 1);
  };
  const clickToCopy = (e) => {
    navigator.clipboard
      .writeText(REACT_APP_GOOGLEAUTH_KEY)
      .then(() => {
        // alert("successfully copied");
        setSuccessSnackbarOpen(true);
        setsuccessMsg('Successfully copied');
      })
      .catch(() => {
        console.log("something went wrong");
      });
  };
  if (homeLoading === true)
    return (
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );

  const filterClose = () => {
    setFilterOpen(!filterOpen);
  };
  const filter = (value) => {
    setFilterValue(value);
  };

  const handleChange = (e) => {
    if (e.target.value === 'All') {
      setFiltervalue('All');
      setFilterValue('');
      return;
    }
    else {
      setFiltervalue(e.target.value);
      setFilterValue(e.target.value);
    }
  };

  return (
    <ThemeProvider theme={colorTheme}>
      <Dialog open={filterOpen} onClose={filterClose}>
        <Box sx={{ width: "300px", height: "300px", textAlign: "center" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography p={1}>Status:</Typography>
            <Button onClick={() => filter("Pause")}>Paused</Button>
            <Button onClick={() => filter("Active")}>Active</Button>
            <Button onClick={() => filter("Error")}>Error</Button>
          </Box>
        </Box>
      </Dialog>
      {!settingDrawer && (
        <>
          <Box sx={{ backgroundColor: "#fff", borderRadius: 3 }}>
            <Box sx={{ width: "100%", height: "4rem" }}>
              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' spacing={2} alignItems='center' >
                  <Paper
                    component="form"
                    sx={{
                      p: 1,
                      display: "flex",
                      alignItems: "center",
                      height: "45px",
                      borderRadius: '6px',
                      boxShadow: "none",
                      border: "none",
                      color: '#989898',
                      backgroundColor: '#F7F7F9',
                      fontSize: '14px', width: '550px',
                    }}
                  >
                    <SearchIcon sx={{ color: "#000000b0", mr: 1, fontWeight: '400' }} />
                    <InputBase
                      sx={{ flex: 1, border: "none", width: '100%', }}
                      placeholder="Search; keyword, title of campaign"
                      onChange={(e) => setSearchValue(e.target.value)}
                      inputProps={{
                        "aria-label": "search for projects", sx: {
                          "&::placeholder": {
                            color: "#989898",
                            fontSize: "14px",
                            fontWeight: '400',
                            fontFamily: "'Inter, Sans-serif"
                          },
                        }
                      }}
                    />
                  </Paper>
                  {/* <Button
                    variant="outlined"
                    sx={{ color: "grey", borderColor: "grey" }}
                    onClick={() => setFilterOpen(true)}
                  >
                    <FilterAltOffIcon
                      style={{ fontSize: "20px", paddingRight: "5px" }}
                    />
                    Filter
                  </Button> */}
                  <FormControl sx={{ width: '100px' }} size="small">
                    <InputLabel id="demo-simple-select-autowidth-label">
                      <Stack direction='row' alignItems='center' spacing={1} sx={{ fontSize: '14px' }}><img src={filterIcon} alt="icon" width='18px' /> <span>Filter</span></Stack>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={filtervalue}
                      onChange={handleChange}
                      autoWidth
                      label="Filter"
                      inputProps={{ IconComponent: () => null, sx: { display: 'flex', alignItems: 'center', }, }}
                      sx={{ height: "45px", pr: 0 }}
                    >
                      <MenuItem
                        value={"paused"}
                        sx={{
                          width: "150px",
                          color: "green",
                          display: "flex",
                          alignItems: 'center',
                          // flexDirection: "column", 
                          fontSize: '14px',
                          '&[aria-selected="true"]': {
                            fontSize: '12px',
                          },
                        }}
                      >
                        <PauseCircleIcon sx={{ transform: "", fontSize: '20px', position: 'relative', top: '-1px' }} />
                        &nbsp;Paused
                      </MenuItem>

                      <MenuItem
                        value={"Active"}
                        sx={{
                          width: "150px",
                          color: "green",
                          display: "flex",
                          alignItems: 'center',
                          // flexDirection: "column",
                          fontSize: '12px'
                        }}
                      >
                        <DoneAllIcon sx={{ transform: "", fontSize: '20px', position: 'relative', top: '-1px' }} />
                        &nbsp; Active
                      </MenuItem>

                      <MenuItem
                        value={"Error"}
                        sx={{
                          width: "150px",
                          color: "purple",
                          display: "flex",
                          alignItems: 'center',
                          // flexDirection: "column",
                          fontSize: '12px'
                        }}
                      >
                        <ErrorIcon sx={{ transform: "", fontSize: '20px', position: 'relative', top: '-1px' }} />
                        &nbsp; Error
                      </MenuItem>

                      <MenuItem
                        value={"All"}
                        sx={{
                          width: "150px",
                          color: "black",
                          display: "flex",
                          alignItems: 'center',
                          fontSize: '12px',
                        }}
                      >
                        <AppsOutlinedIcon sx={{ transform: "", fontSize: '20px', position: 'relative', top: '-1px' }} />
                        &nbsp; All
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                {/* <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    Open Menu
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={menuhandleClose}
                                >
                                    <MenuItem onClick={() => menuhandleClose(0, 0)}>Paused</MenuItem>
                                    <MenuItem onClick={() => menuhandleClose(1, 0)}>Active</MenuItem>
                                    <MenuItem onClick={() => menuhandleClose(0, 1)}>Error</MenuItem>
                                </Menu> */}
                <>
                  <Button
                    variant="contained"
                    onClick={handleOpen}
                    sx={{ textTransform: "none", fontSize: "14px", fontWeight: "700", backgroundColor: "#481BEB", width: '150px' }}
                  >
                    + Add new
                  </Button>
                  <Dialog
                    onClose={handleClose}
                    open={addNewOpen}
                    maxWidth="md"
                    PaperProps={{
                      style: {
                        borderRadius: "20px",
                        pt: 3,
                        pb: 3,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        "&::-webkit-scrollbar": {
                          width: "4px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#481BEB",
                          borderRadius: "4px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                          background: "#2b2b2b",
                        },
                        overflow: "auto",
                      }}
                    >
                      <DialogTitle onClose={handleClose}>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "18px",
                            textAlign: "center",
                          }}
                        >
                          Connect a new Email account
                        </Typography>
                      </DialogTitle>
                      <DialogContent sx={{
                        "&::-webkit-scrollbar": {
                          width: "2px",
                          height: '2px',
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#481BEB",
                          borderRadius: "4px",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                          background: "#2b2b2b",
                        },
                        overflow: "auto",
                      }}>
                        {!imap && !googleOauth ? (
                          <Container maxWidth="sm">
                            <StyledButton
                              sx={{
                                p: 2,
                                px: 2,
                                mt: 2,
                                mb: 4,
                              }}
                              onClick={() => setGoogleOauth(true)}
                              fullWidth
                            >
                              <Box>
                                <Grid
                                  container
                                  spacing={2}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid item xs={0} sm={0} md={3} lg={3} xl={3}>
                                    <img
                                      src={google}
                                      alt="google"
                                      style={{ width: "40px" }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={9}
                                    lg={9}
                                    xl={9}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        color: "#9491AD",
                                      }}
                                    >
                                      Google
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        color: "#000",
                                      }}
                                    >
                                      Google / G-Suite
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </StyledButton>
                            <StyledButton
                              sx={{
                                p: 2,
                                mb: 2,
                              }}
                              onClick={() => setImap(true)}
                              fullWidth
                            >
                              <Box>
                                <Grid
                                  container
                                  spacing={3}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid item xs={0} sm={0} md={3} lg={3} xl={3}>
                                    <img src={imap_smtp} alt="imap_smtp" />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={9}
                                    lg={9}
                                    xl={9}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        color: "#9491AD",
                                      }}
                                    >
                                      Any provider
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        color: "#000",
                                      }}
                                    >
                                      IMAP / SMTP
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </StyledButton>
                          </Container>
                        ) : null}
                        {imap ? (
                          <Box>
                            {imapSteps === 0 && (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    mb: 4,
                                  }}
                                >
                                  <img
                                    src={imap_smtp}
                                    alt="imap_smtp"
                                    sx={{ float: "left", width: "25px" }}
                                  />
                                  <Box sx={{ ml: 2 }}>
                                    <Typography
                                      sx={{
                                        fontSize: "18px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Connect any provider
                                    </Typography>
                                    <Typography>IMAP / SMTP</Typography>
                                  </Box>
                                </Box>
                                <Container maxWidth="sm" sx={{ my: 2 }}>
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={6}
                                      xl={6}
                                    >
                                      <TextField
                                        label="First Name"
                                        type="text"
                                        required={true}
                                        fullWidth
                                        value={firstName}
                                        onChange={(e) =>
                                          setFirstName(e.target.value)
                                        }
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={6}
                                      xl={6}
                                    >
                                      <TextField
                                        label="Last Name"
                                        required
                                        fullWidth
                                        value={lastName}
                                        onChange={(e) =>
                                          setLastName(e.target.value)
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                  <TextField
                                    fullWidth
                                    type="email"
                                    label="Email"
                                    required
                                    onChange={(e) => {
                                      setSmtpUsername(e.target.value);
                                      setEmail(e.target.value);
                                    }}
                                    sx={{ my: 2 }}
                                  />
                                  <Button
                                    disabled={
                                      firstName === "" ||
                                      lastName === "" ||
                                      email === ""
                                    }
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                      backgroundColor: "#38C68B",
                                      p: 1,
                                      mt: 2,
                                    }}
                                    onClick={imapForwardStepsHandler}
                                  >
                                    Next
                                  </Button>
                                </Container>
                              </>
                            )}
                            {imapSteps === 1 && (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    mb: 4,
                                  }}
                                >
                                  <img
                                    src={imap_smtp}
                                    alt="imap_smtp"
                                    sx={{ float: "left", width: "25px" }}
                                  />
                                  <Box sx={{ ml: 2 }}>
                                    <Typography
                                      sx={{
                                        fontSize: "18px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Connect to
                                    </Typography>
                                    <Typography>SMTP</Typography>
                                  </Box>
                                </Box>
                                <Container maxWidth="sm">
                                  <TextField
                                    fullWidth
                                    label="Username"
                                    required
                                    value={smtpUsername}
                                    onChange={(e) =>
                                      setSmtpUsername(e.target.value)
                                    }
                                    sx={{}}
                                  />
                                  <TextField
                                    fullWidth
                                    type="password"
                                    label="SMTP Password"
                                    onChange={(e) =>
                                      setSmtpPassword(e.target.value)
                                    }
                                    required
                                    sx={{ my: 2 }}
                                  />
                                  <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={6}
                                      xl={6}
                                    >
                                      <TextField
                                        required
                                        label="SMTP Host"
                                        value={smtpHost}
                                        fullWidth
                                        onChange={(e) =>
                                          setSmtpHost(e.target.value)
                                        }
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={6}
                                      xl={6}
                                    >
                                      <TextField
                                        required
                                        label="SMTP Port"
                                        type="number"
                                        value={smtpPort}
                                        fullWidth
                                        onChange={(e) =>
                                          setSmtpPort(e.target.value)
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      color="warning"
                                      sx={{
                                        p: 1, mt: 2, mr: 2, color: "#481BEB", borderColor: '#E7E7E7', fontSize: '12px', fontFamily: "'Inter', sans-serif",
                                        "&:hover": {
                                          borderColor: "#E7E7E7",
                                        },
                                      }}
                                      onClick={imapBackStepsHandler}
                                    >
                                      Back
                                    </Button>
                                    <Button
                                      disabled={
                                        buttonLoading ||
                                        smtpUsername === "" ||
                                        smtpPassword === "" ||
                                        smtpHost === "" ||
                                        smtpPort === null
                                      }
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#38C68B",
                                        p: 1,
                                        mt: 2,
                                      }}
                                      onClick={smtpDataCheckHandler}
                                    >
                                      {buttonLoading && (
                                        <CircularProgress size={25} />
                                      )}
                                      {!buttonLoading && "Next"}
                                    </Button>
                                  </Box>
                                </Container>
                              </>
                            )}
                            {imapSteps === 2 && (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    mb: 4,
                                  }}
                                >
                                  <img
                                    src={imap_smtp}
                                    alt="imap_smtp"
                                    sx={{ float: "left", width: "25px" }}
                                  />
                                  <Box sx={{ ml: 2 }}>
                                    <Typography
                                      sx={{
                                        fontSize: "18px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Connect to
                                    </Typography>
                                    <Typography>IMAP</Typography>
                                  </Box>
                                </Box>
                                <Container maxWidth="sm">
                                  <TextField
                                    fullWidth
                                    type="email"
                                    label="Email"
                                    required
                                    value={email}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{}}
                                  />
                                  <TextField
                                    fullWidth
                                    type="password"
                                    label="Password"
                                    required
                                    value={password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                    sx={{ my: 2 }}
                                  />
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={6}
                                      xl={6}
                                    >
                                      <TextField
                                        required
                                        label="IMAP Host"
                                        fullWidth
                                        value={imapHost}
                                        onChange={(e) =>
                                          setImapHost(e.target.value)
                                        }
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={6}
                                      xl={6}
                                    >
                                      <TextField
                                        required
                                        label="IMAP Port"
                                        type="number"
                                        value={imapPort}
                                        fullWidth
                                        onChange={(e) =>
                                          setImapPort(e.target.value)
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      color="warning"
                                      sx={{ p: 1, mt: 2, mr: 2 }}
                                      onClick={imapBackStepsHandler}
                                    >
                                      Back
                                    </Button>
                                    <Button
                                      disabled={
                                        buttonLoading ||
                                        email === "" ||
                                        password === "" ||
                                        imapHost === "" ||
                                        imapPort === null
                                      }
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#38C68B",
                                        p: 1,
                                        mt: 2,
                                      }}
                                      onClick={addNewDataSubmitHandler}
                                    >
                                      {buttonLoading && (
                                        <CircularProgress size={25} />
                                      )}
                                      {!buttonLoading && "Submit"}
                                    </Button>
                                  </Box>
                                </Container>
                              </>
                            )}
                          </Box>
                        ) : // <Box sx={{ height: 'auto', width: "auto", p: 2 }}>
                          //     <TextField
                          //         required
                          //         label='Email'
                          //         type='email'
                          //         fullWidth
                          //         onChange={(e) => setEmail(e.target.value)}
                          //         sx={{ mb: 2 }}
                          //     />
                          //     <TextField
                          //         required
                          //         label='Password'
                          //         type='password'
                          //         fullWidth
                          //         onChange={(e) => setPassword(e.target.value)}
                          //         sx={{ mb: 2 }}
                          //     />
                          //     <Grid container spacing={2} sx={{ mb: 2 }}>
                          //         <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          //             <TextField
                          //                 required
                          //                 label='SMTP Host'
                          //                 fullWidth
                          //                 onChange={(e) => setSmtpHost(e.target.value)}
                          //             />
                          //         </Grid>
                          //         <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          //             <TextField
                          //                 required
                          //                 label='SMTP Port'
                          //                 type='number'
                          //                 fullWidth
                          //                 onChange={(e) => setSmtpPort(e.target.value)}
                          //             />
                          //         </Grid>
                          //     </Grid>
                          //     <Grid container spacing={2}>
                          //         <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          //             <TextField
                          //                 required
                          //                 label='IMAP Host'
                          //                 fullWidth
                          //                 onChange={(e) => setImapHost(e.target.value)}
                          //             />
                          //         </Grid>
                          //         <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          //             <TextField
                          //                 required
                          //                 label='IMAP Port'
                          //                 type='number'
                          //                 fullWidth
                          //                 onChange={(e) => setImapPort(e.target.value)}
                          //             />
                          //         </Grid>
                          //     </Grid>
                          null}
                        {googleOauth ? (
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 4,
                              }}
                            >
                              <img
                                src={google}
                                alt="google"
                                style={{ width: "40px" }}
                              />
                              <Box sx={{ ml: 2 }}>
                                <Typography
                                  sx={{ fontSize: "18px", fontWeight: "700" }}
                                >
                                  Connect your google account
                                </Typography>
                                <Typography>Gmail / G-suite</Typography>
                              </Box>
                            </Box>
                            <Box>
                              {googleOauthSteps === 0 && (
                                <Container maxWidth="sm">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontSize: "16px", fontWeight: 700 }}
                                    >
                                      First, let's enable IMAP access for your
                                      Google account.
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{ fontSize: "16px", fontWeight: "500" }}
                                  >
                                    <ol>
                                      <li>On your computer, open Gmail.</li>
                                      <li>
                                        Click the gear icon in the top right
                                        corner
                                      </li>
                                      <li>Click all settings</li>
                                      <li>
                                        Click the{" "}
                                        <a
                                          href="https://mail.google.com/mail/u/0/#settings/fwdandpop"
                                          style={{
                                            color: "blue",
                                            textDecoration: "none",
                                            fontWeight: 700,
                                          }}
                                        >
                                          Forwarding and POP/IMAP
                                        </a>
                                      </li>
                                      <li>
                                        In the "IMAP access" section, select
                                        Enable IMAP.
                                      </li>
                                      <li>Click Save Changes.</li>
                                    </ol>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={googleForwardStepsHandler}
                                    >
                                      IMAP has been enabled
                                    </Button>
                                  </Box>
                                </Container>
                              )}
                              {googleOauthSteps === 1 && (
                                <>
                                  <Typography
                                    align="center"
                                    color="primary"
                                    sx={{ fontWeight: "700", fontSize: "18px" }}
                                  >
                                    Select a connection option
                                  </Typography>
                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                      p: 4,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      onClick={() =>
                                        googleConnectTypeChangeHandler("oAuth")
                                      }
                                    >
                                      <StyledBox
                                        sx={{
                                          borderRadius: "10px",
                                          boxShadow:
                                            "0px 2px 10px rgba(0, 0, 0, 0.1)",
                                          height: "fit-content",
                                          width: "250px",
                                          cursor: 'pointer',
                                          p: 4,
                                          backgroundColor:
                                            googleConnectTypes === "oAuth"
                                              ? "#2196F3"
                                              : "fff",
                                          color:
                                            googleConnectTypes === "oAuth"
                                              ? "#fff"
                                              : "000",
                                        }}
                                      >
                                        <Typography
                                          align="center"
                                          sx={{
                                            fontSize: "16px",
                                            fontWeight: "700",
                                            mb: 2,
                                          }}
                                        >
                                          Option 1: oAuth
                                        </Typography>
                                        <Box>
                                          <Stack sx={{}} spacing={2}>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <CheckCircleIcon sx={{ mr: 1 }} />
                                              Easier to setup
                                            </Typography>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <CheckCircleIcon sx={{ mr: 1 }} />
                                              More stable and less disconnects
                                            </Typography>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <CheckCircleIcon sx={{ mr: 1 }} />
                                              Available for GSuite accounts
                                            </Typography>
                                          </Stack>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            mt: 2,
                                          }}
                                        >
                                          <Chip
                                            label="Recommended"
                                            sx={{
                                              backgroundColor: "#38C68B",
                                              color: "#fff",
                                            }}
                                          />
                                        </Box>
                                      </StyledBox>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                      onClick={() =>
                                        googleConnectTypeChangeHandler(
                                          "app-password"
                                        )
                                      }
                                    >
                                      <StyledBox
                                        sx={{
                                          borderRadius: "10px",
                                          boxShadow:
                                            "0px 2px 10px rgba(0, 0, 0, 0.1)",
                                          height: "fit-content",
                                          width: "250px",
                                          cursor: 'pointer',
                                          p: 4,
                                          backgroundColor:
                                            googleConnectTypes !== "oAuth"
                                              ? "#2196F3"
                                              : "fff",
                                          color:
                                            googleConnectTypes !== "oAuth"
                                              ? "#fff"
                                              : "000",
                                        }}
                                      >
                                        <Typography
                                          align="center"
                                          sx={{
                                            fontSize: "16px",
                                            fontWeight: "700",
                                            mb: 2,
                                          }}
                                        >
                                          Option 2: App Password
                                        </Typography>
                                        <Box>
                                          <Stack sx={{}} spacing={2}>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <CheckCircleIcon sx={{ mr: 1 }} />
                                              Available for personal accounts
                                            </Typography>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <ErrorIcon
                                                sx={{ mr: 1 }}
                                                color="warning"
                                              />
                                              Require 2-factor authentication
                                            </Typography>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <ErrorIcon
                                                sx={{ mr: 1 }}
                                                color="warning"
                                              />
                                              More prone to disconnects
                                            </Typography>
                                          </Stack>
                                        </Box>
                                      </StyledBox>
                                    </Grid>
                                  </Grid>
                                  <Box>
                                    <Button
                                      variant="contained"
                                      sx={{ float: "left" }}
                                      onClick={googleBackStepsHandler}
                                    >
                                      Back
                                    </Button>
                                    <Button
                                      variant="contained"
                                      sx={{ float: "right" }}
                                      onClick={googleForwardStepsHandler}
                                    >
                                      Next
                                    </Button>
                                  </Box>
                                </>
                              )}
                              {googleOauthSteps === 2 && (
                                <Container maxWidth="sm">
                                  {googleConnectTypes === "oAuth" && (
                                    <>
                                      <Box sx={{ mb: 2 }}>
                                        <Typography
                                          align="center"
                                          sx={{
                                            fontSize: "18px",
                                            fontWeight: "700",
                                            color: "#555",
                                          }}
                                        >
                                          Allow xCampaign to access your Google
                                          workspace
                                        </Typography>
                                        <Typography
                                          align="center"
                                          sx={{
                                            fontSize: "18px",
                                            fontWeight: "700",
                                          }}
                                          color="primary"
                                        >
                                          <a
                                            target="blank"
                                            href="https://admin.google.com/u/1/ac/owl/list?tab=configuredApps"
                                          >
                                            See tutorial video
                                          </a>
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            my: 1,
                                          }}
                                        >
                                          1: Go to your Google Workspace Admin
                                          Panel
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          2: Click "Add App" and then select
                                          "OAuth App Name or Client ID"
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography
                                          sx={{
                                            fontSize: "16px",
                                            fontWeight: "700",
                                          }}
                                        >
                                          3: Use the following Client-ID to
                                          search for xCampaign:
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          position: "relative",
                                          backgroundColor: "#eeeeee",
                                          // height: "50px",
                                          // display: "flex",
                                          // alignItems: "center",
                                          borderRadius: "10px",
                                          wordWrap: "break-word",
                                          p: 2,
                                          my: 2
                                        }}
                                      >
                                        <Button
                                          sx={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            fontSize: "12px",
                                          }}
                                          onClick={clickToCopy}
                                        >
                                          Copy Text
                                        </Button>
                                        <span>{REACT_APP_GOOGLEAUTH_KEY}</span>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          mt: 2,
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          onClick={googleBackStepsHandler}
                                          sx={{ mr: 2 }}
                                          color="info"
                                        >
                                          Back
                                        </Button>
                                        <Button
                                          onClick={googleOauthHandler}
                                          variant="contained"
                                          disabled={buttonLoading}
                                          sx={{ backgroundColor: "#38C68B" }}
                                        >
                                          {buttonLoading && (
                                            <CircularProgress size={25} />
                                          )}
                                          {!buttonLoading && "Connect"}
                                        </Button>
                                      </Box>
                                    </>
                                  )}
                                  {googleConnectTypes !== "oAuth" && (
                                    <>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                          <StyledTextField
                                            label="First Name"
                                            value={firstName}
                                            fullWidth
                                            onChange={(e) =>
                                              setFirstName(e.target.value)
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                          <StyledTextField
                                            label="Last Name"
                                            value={lastName}
                                            fullWidth
                                            onChange={(e) =>
                                              setLastName(e.target.value)
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                      <StyledTextField
                                        fullWidth
                                        type="email"
                                        label="email"
                                        value={email}
                                        onChange={(e) => {
                                          setSmtpUsername(e.target.value);
                                          setEmail(e.target.value);
                                        }}
                                        sx={{ my: 2 }}
                                      />
                                      <StyledTextField
                                        fullWidth
                                        type="password"
                                        value={password}
                                        onChange={(e) => {
                                          setSmtpPassword(e.target.value);
                                          setPassword(e.target.value);
                                        }}
                                        label="App Password"
                                      />
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          mt: 2,
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          onClick={googleBackStepsHandler}
                                          sx={{ mr: 2 }}
                                          color="info"
                                        >
                                          Back
                                        </Button>
                                        <Button
                                          variant="contained"
                                          disabled={
                                            buttonLoading ||
                                            email === "" ||
                                            firstName === "" ||
                                            lastName === ""
                                          }
                                          onClick={addNewDataSubmitHandler}
                                          sx={{ backgroundColor: "#38C68B" }}
                                        >
                                          {buttonLoading && (
                                            <CircularProgress size={25} />
                                          )}
                                          {!buttonLoading && "Connect"}
                                        </Button>
                                      </Box>
                                    </>
                                  )}
                                </Container>
                              )}
                            </Box>
                            {/* <Box>
                                                            Allow xCampaign to access your Google workspace
                                                            <a href="https://admin.google.com/u/1/ac/owl/list?tab=configuredApps">See tutorial video</a>
                                                            Go to your Google Workspace Admin Panel
                                                            Click "Add App" and then select "OAuth App Name or Client ID"
                                                        </Box>
                                                        <Box>Use the following Client-ID to search for xCampaign:</Box>
                                                        <Box sx={{ backgroundColor: "#eeeeee", height: "50px", display: "flex", alignItems: "center", borderRadius: "10px", p: 2 }}>
                                                            {REACT_APP_GOOGLEAUTH_KEY}
                                                        </Box>
                                                        <Button onClick={googleOauthHandler}>Connect</Button> */}
                          </Box>
                        ) : null}
                      </DialogContent>
                      <DialogActions
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {(imap || googleOauth) && (
                          <Box
                            sx={{
                              width: "100%",
                              fontWeight: "700",
                              display: "flex",
                              alignItems: "center",
                              fontSize: "14px",
                            }}
                          >
                            <IconButton onClick={handleSelectAnotherProvider}>
                              <ArrowBackIcon />
                            </IconButton>
                            Select another provider
                          </Box>
                        )}
                        <Box sx={{ my: 3 }}>
                          <Button
                            sx={{
                              py: 1, color: "#481BEB", borderColor: '#E7E7E7', fontSize: '12px', fontFamily: "'Inter', sans-serif",
                              "&:hover": {
                                borderColor: "#E7E7E7",
                              },
                            }}
                            variant="outlined"
                            onClick={handleClose}
                          >
                            cancel
                          </Button>
                        </Box>
                      </DialogActions>
                    </Box>
                  </Dialog>
                </>
              </Stack>
            </Box>
            <Box sx={{}}>
              <StyledTableContainer>
                <Table sx={{ minWidth: 650, borderCollapse: "separate", borderSpacing: "0 10px", }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>EMAIL</StyledTableCell>
                      <StyledTableCell>STATUS</StyledTableCell>
                      <StyledTableCell>SENT</StyledTableCell>
                      <StyledTableCell>INBOX</StyledTableCell>
                      <StyledTableCell>SPAM</StyledTableCell>
                      <StyledTableCell>REPLIED</StyledTableCell>
                      <StyledTableCell>HEALTH</StyledTableCell>
                      <StyledTableCell align="center">ACTION</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {tableData?.warmup_analytics
                      ?.filter((x) => x["status"].includes(filterValue))
                      .filter((x) =>
                        x["sender_email"]
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                      )
                      .map((row, index) => (
                        <StyledTableRow key={index} >
                          <StyledTableCell sx={{ fontSize: '16px !important', fontWeight: "700 !important", }}>
                            {row["sender_email"]}
                          </StyledTableCell>

                          <StyledTableCell>
                            <Chip sx={{
                              fontWeight: "600", fontSize: '12px', fontFamily: "'Inter', sans-serif", color: '#fff',
                              backgroundColor: tableData?.warmup_setting[
                                getSettingIndex(row["sender_idsender"])
                              ].enable === 1 &&
                                tableData?.warmup_setting[
                                  getSettingIndex(row["sender_idsender"])
                                ].errors === 0
                                ? "#38C68B"
                                : tableData?.warmup_setting[
                                  getSettingIndex(row["sender_idsender"])
                                ].enable === 0 &&
                                  tableData?.warmup_setting[
                                    getSettingIndex(row["sender_idsender"])
                                  ].errors === 0
                                  ? "#9491AD"
                                  : "#EF6D6D",
                            }}
                              label={
                                tableData?.warmup_setting[
                                  getSettingIndex(row["sender_idsender"])
                                ].enable === 1 &&
                                  tableData?.warmup_setting[
                                    getSettingIndex(row["sender_idsender"])
                                  ].errors === 0
                                  ? "Active"
                                  : tableData?.warmup_setting[
                                    getSettingIndex(row["sender_idsender"])
                                  ].enable === 0 &&
                                    tableData?.warmup_setting[
                                      getSettingIndex(row["sender_idsender"])
                                    ].errors === 0
                                    ? "Paused"
                                    : "Error"
                              }
                              color={
                                tableData?.warmup_setting[
                                  getSettingIndex(row["sender_idsender"])
                                ].enable === 1 &&
                                  tableData?.warmup_setting[
                                    getSettingIndex(row["sender_idsender"])
                                  ].errors === 0
                                  ? "success"
                                  : tableData?.warmup_setting[
                                    getSettingIndex(row["sender_idsender"])
                                  ].enable === 0 &&
                                    tableData?.warmup_setting[
                                      getSettingIndex(row["sender_idsender"])
                                    ].errors === 0
                                    ? "default"
                                    : "error"
                              }
                            />
                          </StyledTableCell>

                          <StyledTableCell>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: '5px', }}  >
                              <SendIcon sx={{ fontSize: "16px", color: "#BEBEBE", marginTop: '4px', transform: "rotate(-45deg)translateY(-0.3rem)", }} />
                              {row["email_sent"]}
                            </div>
                          </StyledTableCell>

                          <StyledTableCell>
                            <div style={{ display: "flex", alignItems: "center", gap: '4px', }}>
                              <InboxIcon sx={{ fontSize: "16px", color: "#BEBEBE", }} />
                              {row["inbox"]}
                            </div>
                          </StyledTableCell>

                          <StyledTableCell>
                            <div style={{ display: "flex", alignItems: "center", gap: '4px', }}>
                              <ErrorIcon sx={{ fontSize: "16px", color: "#BEBEBE", }} />
                              {row["spam"]}
                            </div>
                          </StyledTableCell>

                          <StyledTableCell>
                            <div style={{ display: "flex", alignItems: "center", gap: '4px', }}>
                              <ReplyIcon sx={{ fontSize: "16px", color: "#BEBEBE", transform: "rotateY(180deg)" }} />
                              {row["replied"]}
                            </div>
                          </StyledTableCell>

                          <StyledTableCell>
                            {row["email_sent"] === 0 && (
                              <div style={{ display: "flex", alignItems: "center", color: "#BEBEBE" }}>
                                <StopRoundedIcon sx={{ fontSize: "30px", }} />
                                NA
                              </div>
                            )}
                            {(row["spam"] * 100) / row["email_sent"] > 15 && (
                              <div style={{ display: "flex", alignItems: "center", color: "#EF6D6D", }}>
                                <StopRoundedIcon sx={{ fontSize: "30px", }} />
                                Bad
                              </div>
                            )}
                            {(row["spam"] * 100) / row["email_sent"] >= 5 &&
                              (row["spam"] * 100) / row["email_sent"] <= 10 && (
                                <div style={{ display: "flex", alignItems: "center", color: "#EFA10D", }}>
                                  <StopRoundedIcon sx={{ fontSize: "30px", }} />
                                  Fair
                                </div>
                              )}
                            {(row["spam"] * 100) / row["email_sent"] < 5 && (
                              <div style={{ display: "flex", alignItems: "center", color: "#38C68B", }}>
                                <StopRoundedIcon sx={{ fontSize: "30px", }} />
                                Good
                              </div>
                            )}
                          </StyledTableCell>

                          <StyledTableCell>
                            <div style={{ display: "flex", alignItems: "center", }}>
                              <Tooltip title="Play/Pause Button">
                                <IconButton
                                  disabled={
                                    tableData?.warmup_setting[
                                      getSettingIndex(row["sender_idsender"])
                                    ].errors >= 1
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleCronjobSetting(
                                      tableData?.warmup_setting[
                                        getSettingIndex(row["sender_idsender"])
                                      ].sender_email,
                                      tableData?.warmup_setting[
                                        getSettingIndex(row["sender_idsender"])
                                      ].enable,
                                      getSettingIndex(row["sender_idsender"])
                                    );
                                  }}
                                >
                                  {
                                    tableData?.warmup_setting[
                                      getSettingIndex(row["sender_idsender"])
                                    ].enable === 0 ||
                                      tableData?.warmup_setting[
                                        getSettingIndex(row["sender_idsender"])
                                      ].errors >= 1
                                      ? <PlayCircleFilledWhiteIcon />
                                      : <PauseCircleIcon />
                                  }
                                </IconButton>
                              </Tooltip>

                              <IconButton onClick={() => toggleSettingDrawerOpen(row["sender_idsender"])} >
                                <SettingsIcon sx={{ color: "#989898", }} />
                              </IconButton>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>


              {/* <section>
                <Stack>
                  <Pagination count={10} variant="outlined" shape="rounded" />
                </Stack>
              </section> */}

              <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                open={successSnackbarOpen}
                autoHideDuration={6000}
                onClose={handleSuccessSnackbarClose}
              >
                <Alert
                  onClose={handleSuccessSnackbarClose}
                  variant="filled"
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  {addNewSuccessMessage}
                </Alert>
              </Snackbar>
              <Snackbar
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                open={errorSnackbarOpen}
                autoHideDuration={6000}
                onClose={handleErrorSnackbarClose}
              >
                <Alert
                  onClose={handleErrorSnackbarClose}
                  variant="filled"
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {addNewErrorMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Box>
        </>
      )}
      {settingDrawer && (
        <>
          <Box>
            <StyledButtonOutlined
              onClick={() => {
                setSettingDrawer(false);
                setNewDataAdd(!newDataAdd);
              }}
              color="warning"
              variant="outlined"
              sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
            >
              <ArrowBackIcon />
              Exit without saving
            </StyledButtonOutlined>
          </Box>

          <Box
            sx={{
              backgroundColor: "#F7F7F9",
              mt: 6,
              width: "100%",
              borderRadius: "10px",
              p: 1.5,
            }}
          >
            <Stack flexWrap='wrap' direction='row' justifyContent={{ lg: 'space-between', md: 'space-between', sm: 'center' }} gap="20px" sx={{

            }}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
                sx={{
                  display: "flex",
                  // justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ borderRadius: "16px", backgroundColor: "#7860DA", float: "left", mr: 2, pt: 0.7, }} >
                  G
                </Avatar>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: "500",
                    float: "left",
                    mr: 2,
                  }}
                >
                  {settingDrawerData?.sender_email}
                </Typography>
                <Chip
                  label={
                    <Typography sx={{ fontWeight: "600", fontSize: "12px" }}>
                      {settingDrawerData.enable === 1 &&
                        settingDrawerData.errors === 0
                        ? "Active"
                        : settingDrawerData.enable === 0 &&
                          settingDrawerData.errors === 0
                          ? "Paused"
                          : "Error"}
                    </Typography>
                  }
                  color={
                    settingDrawerData.enable === 1 &&
                      settingDrawerData.errors === 0
                      ? "success"
                      : settingDrawerData.enable === 0 &&
                        settingDrawerData.errors === 0
                        ? "default"
                        : "error"
                  }
                  sx={{
                    width: "64px", fontWeight: "600", fontSize: '12px', fontFamily: "'Inter', sans-serif", color: '#fff',
                    backgroundColor: settingDrawerData.enable === 1 &&
                      settingDrawerData.errors === 0
                      ? "#38C68B"
                      : settingDrawerData.enable === 0 &&
                        settingDrawerData.errors === 0
                        ? "#9491AD"
                        : "#EF6D6D"
                  }}
                />
                <Grid>
                  {visible && (
                    <InfoIcon
                      sx={{ marginLeft: "20px" }}
                      onClick={(e) => {
                        getStatusDetails(settingDrawerData.sender_email);
                      }}
                    ></InfoIcon>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
                sx={{
                  display: "flex",
                  // justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "400", color: "#181818" }}>
                  Activities
                </Typography>
                {[
                  { 0: "email_sent", 1: "Sent", 3: "#38C68B" },
                  { 0: "inbox", 1: "Inbox", 3: "#5570E8" },
                  { 0: "spam", 1: "Spam", 3: "#EF6D6D" },
                  { 0: "replied", 1: "Health", 3: "#38C68B" },
                ].map((func, ind) => (
                  <Card
                    key={func[1]}
                    sx={{
                      boxShadow: "none",
                      borderRadius: "6px",
                      mx: 1,
                      px: 2.5,
                      py: 1.5,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {func[1] !== "Health" ? (
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: func[3],
                        }}
                      >
                        {settingAnalyticsData[func[0]]}
                      </Typography>
                    ) : (
                      <IconWithText text={func[1]} color={func[3]} />
                    )}
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#7D7D7D",
                      }}
                    >
                      {func[1]}
                    </Typography>
                  </Card>
                ))}
              </Grid>
            </Stack>
          </Box>

          <Box sx={{ width: "100%", mt: 1 }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#272727",
                float: "left",
                mr: 1,
              }}
            >
              Account added on:
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#989898",
                float: "left",
              }}
            >
              {createdAtDate}
            </Typography>
          </Box>
          <Grid container sx={{ pt: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ pr: 5 }}>
              <Typography sx={{ fontSize: "16px", fontWeight: "700", pb: 3 }}>
                Edit Account
              </Typography>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <StyledTypography>First Name*</StyledTypography>
                  <StyledTextField
                    fullWidth
                    required
                    placeholder="First Name"
                    value={settingProfileData.fname}
                    onChange={(e) => {
                      let tempSettingData = settingProfileData;
                      tempSettingData.fname = e.target.value;
                      setSettingProfileData(tempSettingData);
                      setChange(!change);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <StyledTypography>Last Name</StyledTypography>
                  <StyledTextField
                    fullWidth
                    required
                    placeholder="Last Name"
                    value={settingProfileData.lname}
                    onChange={(e) => {
                      let tempSettingData = settingProfileData;
                      tempSettingData.lname = e.target.value;
                      setSettingProfileData(tempSettingData);
                      setChange(!change);
                    }}
                  />
                </Grid>
                {settingProfileData ? null : settingProfileData.lname}
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <StyledTypography>Starting Volume* (1-10)</StyledTypography>
                  <StyledTextField
                    fullWidth
                    required
                    onChange={handleStartingVolume}
                    type="number"
                    value={settingDrawerData?.starting_volume}
                    inputProps={{
                      min: "0",
                      max: "10",
                    }}
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <StyledTypography>Ending Volume* (10-200)</StyledTypography>
                  <StyledTextField
                    fullWidth
                    required
                    type="number"
                    value={settingDrawerData?.max_par_day}
                    inputProps={{
                      min: "11",
                      max: "200",
                    }}
                    onChange={handleEndingingVolume}
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <StyledTypography>Reply Percent* (0-50)</StyledTypography>
                  <StyledTextField
                    fullWidth
                    required
                    value={settingDrawerData?.reply_rate}
                    type="number"
                    inputProps={{
                      min: "0",
                      max: "50",
                    }}
                    onChange={handleReplyPercentage}
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <StyledTypography>Increase per day* (1-50)</StyledTypography>
                  <StyledTextField
                    fullWidth
                    required
                    value={settingDrawerData?.increase_per_day}
                    type="number"
                    onChange={handleIncreasePerDay}
                    inputProps={{
                      min: "1",
                      max: "50",
                    }}
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <StyledTypography>Warmup Tag</StyledTypography>
                  <StyledTextField
                    fullWidth
                    required
                    placeholder="Tag Number"
                    value={settingDrawerData?.filter_tag}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <StyledTypography>Today's Volume</StyledTypography>
                  <StyledTextField
                    fullWidth
                    required
                    value={settingDrawerData?.current_day_limit}
                    type="number"
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <StyledTypography sx={{ mt: 2 }}>Tracking Domain</StyledTypography>
              <StyledTextField
                fullWidth
                required
                value={settingDrawerData?.custom_tracking}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              sx={{ pl: 5, borderLeft: "1px solid #E7E7E7" }}
            >
              <StyledTypography sx={{ fontSize: "16px", fontWeight: "700", pb: 3 }}>
                Preference
              </StyledTypography>
              <Card
                sx={{
                  p: 2,
                  boxShadow: '0px 2px 11px 0px #00000012',
                  borderRadius: '10px',
                  mb: 2,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <StyledTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  Perform Deliverability Test for this domain
                </StyledTypography>
                <Switch color="success" sx={{ ml: "auto", color: '#38C68B' }} />
              </Card>
              <Card
                sx={{
                  p: 2,
                  boxShadow: '0px 2px 11px 0px #00000012',
                  borderRadius: '10px',
                  mb: 2,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <StyledTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  Exclude this Id from campaign, if deliverability reputation is
                  not good
                </StyledTypography>
                <Switch color="success" sx={{ ml: "auto", }} />
              </Card>
              <Card
                sx={{
                  p: 2,
                  boxShadow: '0px 2px 11px 0px #00000012',
                  borderRadius: '10px',
                  mb: 2,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <StyledTypography sx={{ fontSize: "14px", fontWeight: "600" }}>
                  AI enabled warmup
                </StyledTypography>
                <Switch color="success" sx={{ ml: "auto", color: '#38C68B' }} />
              </Card>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mt: 5,
            }}
          >
            <StyledButtonOutlined
              onClick={() => setRemoveAccountDialog(true)}
              variant="outlined"
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#7D7D7D",
                textTransform: "none",
                boxShadow: "none",
                fontFamily: "'HK Nova', sans-serif",
                mr: 2,
              }}
            >
              Remove the account
            </StyledButtonOutlined>
            <Button
              onClick={toggleSettingDrawerClose}
              disabled={buttonLoading}
              variant="contained"
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                fontFamily: "'HK Nova', sans-serif",
                backgroundColor: buttonLoading ? "#e7e7e7" : "#481BEB",
                textTransform: "none",
                boxShadow: "none",
              }}
            >
              {!buttonLoading && "Save and Close"}
              {buttonLoading && <CircularProgress size={25} />}
            </Button>
          </Box>
          <Dialog
            PaperProps={{
              style: { borderRadius: "20px" },
            }}
            open={removeAccountDialog}
          >
            {!accounctDeleteSuccess ? (
              <div
                style={{
                  maxWidth: "500px",
                  minHeight: "270px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  px: 3,
                  position: "relative",
                }}
              >
                <DialogTitle id="alert-dialog-title">
                  <p
                    style={{
                      color: "#EF6D6D",
                      fontWeight: "700",
                      fontSize: "20px",
                    }}
                  >
                    Are you sure you want to delete account?
                  </p>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "16px",
                      textAlign: "center",
                      color: '#141B3A',
                    }}
                  >
                    {settingDrawerData?.sender_email}
                  </Typography>
                </DialogTitle>
                <DialogContent
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      textAlign: "center",
                      width: "100%",
                      color: '#4F4F4F',
                      lineHeight: "24px",
                    }}
                  >
                    This will permanently remove from Campaign, Master Inbox and
                    all the associated information linked with this account
                  </Typography>
                </DialogContent>
                <DialogActions sx={{ mb: 4 }}>
                  <Button
                    onClick={() => setRemoveAccountDialog(false)}
                    variant="contained"
                    sx={{ backgroundColor: '#38C68B', color: '#fff', fontSize: '12px', px: 4 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={removeAccountHandler}
                    variant="contained"
                    disabled={buttonLoading}
                    sx={{ backgroundColor: '#EF6D6D', color: '#fff', fontSize: '12px', px: 4 }}
                  >
                    {buttonLoading && <CircularProgress size={25} />}
                    {!buttonLoading && "Delete"}
                  </Button>
                </DialogActions>
              </div>
            ) : (
              <div
                style={{
                  maxWidth: "500px",
                  width: '100%',
                  // minHeight: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: '5px 30px',
                }}
              >
                <DialogTitle id="alert-dialog-title">
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      textAlign: "center",
                      mt: 1,
                    }}
                  >
                    {settingDrawerData?.sender_email}
                  </Typography>
                  <p
                    style={{
                      color: "#EF6D6D",
                      fontWeight: "700",
                      fontFamily: "'HK Nova' sans-serif",
                      fontSize: "20px",
                      margin: '5px 0'
                    }}
                  >
                    Account Deleted Successfully
                  </p>
                </DialogTitle>

                <DialogActions sx={{}}>
                  <Button
                    onClick={() => setRemoveAccountDialog(false)}
                    variant="contained"
                    sx={{ backgroundColor: '#481BEB', color: '#fff', fontSize: '12px', px: 4, mb: 3, }}
                  >
                    Close
                  </Button>
                </DialogActions>
              </div>
            )}
          </Dialog>
        </>
      )}
      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert
          onClose={handleErrorSnackbarClose}
          variant="filled"
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert
          onClose={handleSuccessSnackbarClose}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default EmailAccounts;
