import React from 'react'
import {
  Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle, Typography, Stack, Snackbar, Alert, Table, TableBody, IconButton, Paper,
  InputBase, TableHead, TableRow, Chip, Backdrop,
} from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CampaignFunctions from './CampaignFunctions';
import { styled } from '@mui/material/styles';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import EditIcon from '@mui/icons-material/Edit';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DraftsIcon from '@mui/icons-material/Drafts';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { Delete } from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ErrorIcon from '@mui/icons-material/Error';
import Select from '@mui/material/Select';
import { StyledTableCell, StyledTableContainer, StyledTableRow, StyledTextField, colorTheme } from '../../stylings/styles';
import filterIcon from "../../img/icons/filter.png";
import SendIcon from "@mui/icons-material/Send";
import InboxIcon from "@mui/icons-material/Inbox";
import StopRoundedIcon from '@mui/icons-material/StopRounded';




const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#FAFAFA",
//     fontSize: "12px",
//     fontWeight: "700"
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   // hide last border

//   '&:last-child td, &:last-child th': {
//     border: 0,
//   },
// }));
const Campaign = () => {
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = React.useState("")
  const [filterValue, setFilterValue] = React.useState("")
  const [filterOpen, setFilterOpen] = React.useState(false)
  const [campaignTableData, setCampaignTableData] = React.useState([])
  const [homeLoading, setHomeLoading] = React.useState(false)
  const [selectedCampaign, setSelectedCampaign] = React.useState(null)
  const [campaignName, setCampaignName] = React.useState("")
  const [campaignId, setCampaignId] = React.useState(null)
  const [campaignStatus, setCampaignStatus] = React.useState(null)
  const [campaignDialog, setCampaignDialog] = React.useState(false)
  const [newCampaign, setNewCampaign] = React.useState(false)
  const [deleteCampaignDialougeOpen, setdDleteCampaignDialougeOpen] = React.useState(false)
  const [renameCampaignDialougeOpen, setRenameCampaignDialougeOpen] = React.useState(false)
  const [rename, setRename] = React.useState({ "name": "", "id": "" })
  const [deleteId, setdDeleteId] = React.useState("")
  const [errorMessage, setErrorMessage] = React.useState('');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('');
  const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false)
  const [isSomethingChanged, setIsSomethingChanged] = React.useState(false)
  React.useEffect(() => {
    setHomeLoading(true)
    fetch(`${REACT_APP_SERVER_URL}/fetch_campaign`, {
      headers: {
        'Accept': 'application/json',
      },
      credentials: "include",
      method: 'GET',
    }).then(response => {
      if (response.status === 200) {
        setHomeLoading(false)
        return response.json()
      }
      else if (response.status === 202) {
        setHomeLoading(false)
        console.log("token expired")
        navigate('/login', { state: { sessionExpired: true } })
      }
      else {
        setHomeLoading(false)
        console.log("some error")
      }
    })
      .then(data => {
        console.warn(data);
        setCampaignTableData(data["data"]['data'])
      })
      .catch(error => console.log(error))
  }, [campaignStatus, newCampaign, isSomethingChanged, navigate])

  console.log("campaignId ", campaignId);

  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorSnackbarOpen(false);
  }
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSnackbarOpen(false);
  }
  const handleCampaignClick = (id, status, name) => {
    setSelectedCampaign(name)
    setCampaignId(id)
    setCampaignStatus(status)
  }
  const handleCampaignDialogClose = () => {
    setCampaignDialog(false)
  }
  const newCampaignSubmitHandler = (e) => {
    if (campaignName === "") {
      setErrorMessage("Please Enter the name for the new campaign")
      setErrorSnackbarOpen(true);
    } else {
      setHomeLoading(true)
      fetch(`${REACT_APP_SERVER_URL}/create_campaign`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: "include",
        method: 'POST',
        body: JSON.stringify({ "name": campaignName })
      }).then(response => {
        if (response.status === 200) {
          setHomeLoading(false)
          handleCampaignDialogClose()
          return response.json()
        }
        else if (response.status === 202) {
          setHomeLoading(false)
          console.log("token expired")
          navigate('/login', { state: { sessionExpired: true } })
        }
        else {
          setHomeLoading(false)
          console.log("some error")
        }
      })
        .then(data => {
          setCampaignId(data.campaign_id)
          setNewCampaign(!newCampaign)
          console.log(data)
        })
        .catch(error => console.log(error))

    }

  }

  const deleteCampaignClicked = (id) => {
    setdDleteCampaignDialougeOpen(true);
    setdDeleteId(id);
  }
  const deleteCampaignHandle = (id) => {
    setHomeLoading(true)
    fetch(`${REACT_APP_SERVER_URL}/delete_campaign`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include",
      method: 'POST',
      body: JSON.stringify({
        "campaign": {
          'campaign_id': id
        }
      })
    }).then(response => {
      if (response.status === 200) {
        setHomeLoading(false)
        setIsSomethingChanged(!isSomethingChanged)
        return response.json()
      }
      else if (response.status === 202) {
        setHomeLoading(false)
        setErrorMessage(response.msg);
        setErrorSnackbarOpen(true);
        console.log("token expired")
        navigate('/login', { state: { sessionExpired: true } })
      }
      else {
        setHomeLoading(false)
        setErrorMessage(response.msg);
        setErrorSnackbarOpen(true);
        console.log("some error")
      }
    })
      .then(data => {
        setHomeLoading(false)
        setSuccessMessage(data.msg);
        setSuccessSnackbarOpen(true);
        console.log(data)
      })
      .catch(error => console.log(error))
    setdDleteCampaignDialougeOpen(false)
  }
  const handleToggleCampaignStatus = (status, id, name) => {
    if (status !== undefined && id !== undefined && name !== undefined) {
      if (status === 'active') {
        fetch(`${REACT_APP_SERVER_URL}/pause_campaign`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: "include",
          method: 'POST',
          body: JSON.stringify({
            "campaign": {
              'campaign_id': id
            }
          })
        }).then(response => {
          if (response.status === 200) {
            setIsSomethingChanged(!isSomethingChanged)
            return response.json()
          }
          else if (response.status === 202) {
            console.log("token expired")
            navigate('/login', { state: { sessionExpired: true } })
          }
          else {
            console.log("some error")
            return response.json()
          }
        })
          .then(data => {
            if (data.status === 200) {
              setSuccessSnackbarOpen(true);
              setSuccessMessage(data.msg);
            }
            else if (data.status === 201 || data.status === 202) {
              setErrorSnackbarOpen(true);
              setSuccessMessage(data.msg);
            }
          })
          .catch(error => console.log(error))
      }
      else {
        console.log('yes')
        fetch(`${REACT_APP_SERVER_URL}/campaign_launch`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: "include",
          method: 'POST',
          body: JSON.stringify({
            "campaign": {
              'campaign_id': id
            }
          })
        }).then(response => {
          if (response.status === 200) {
            setIsSomethingChanged(!isSomethingChanged)
            return response.json()
          }
          else if (response.status === 202) {
            console.log("token expired")
            navigate('/login', { state: { sessionExpired: true } })
          }
          else {
            console.log("some error")
            return response.json()
          }
        })
          .then(data => {
            if (data.status === 200) {
              setSuccessSnackbarOpen(true);
              setSuccessMessage(data.msg);
            } if (data.status === 201) {
              setErrorSnackbarOpen(true);
              setErrorMessage(data.msg);
            }
          })
          .catch(error => console.log(error))
      }
    }
    else {
      if (campaignStatus === 'active') {
        fetch(`${REACT_APP_SERVER_URL}/pause_campaign`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: "include",
          method: 'POST',
          body: JSON.stringify({
            "campaign": {
              'campaign_id': campaignId
            }
          })
        }).then(response => {
          if (response.status === 200) {
            setCampaignStatus('paused')
            return response.json()
          }
          else if (response.status === 202) {
            console.log("token expired")
            navigate('/login', { state: { sessionExpired: true } })
          }
          else {
            console.log("some error")
            return response.json()
          }
        })
          .then(data => {
            if (data.status === 200) {
              setSuccessSnackbarOpen(true);
              setSuccessMessage(data.msg);
            }
            else if (data.status === 201 || data.status === 202) {

            }

          })
          .catch(error => console.log(error))
      }
      else {
        fetch(`${REACT_APP_SERVER_URL}/campaign_launch`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: "include",
          method: 'POST',
          body: JSON.stringify({
            "campaign": {
              'campaign_id': campaignId
            }
          })
        }).then(response => {
          if (response.status === 200) {
            setCampaignStatus('active')
            return response.json()
          }
          else if (response.status === 202) {
            console.log("token expired")
            navigate('/login', { state: { sessionExpired: true } })
          }
          else {
            console.log("some error")
            return response.json()
          }
        })
          .then(data => {
            if (data.status === 200) {
              setSuccessSnackbarOpen(true);
              setSuccessMessage(data.msg);
            } if (data.status === 201) {
              setErrorSnackbarOpen(true);
              setErrorMessage(data.msg);
            }
          })
          .catch(error => console.log(error))
      }
    }
  }

  const filterClose = () => {
    setFilterOpen(!filterOpen)
  }
  const saveErrorHandler = () => {
    if (campaignStatus === 'active')
      setCampaignStatus('paused')
  }
  if (homeLoading) return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  )

  const renameDialougeOpen = (name = "", id = "") => {
    setRename({ "name": name, "id": id })
    setRenameCampaignDialougeOpen(true)
  }

  const renameCampaignHandle = () => {
    setRenameCampaignDialougeOpen(false)
    setHomeLoading(true)
    if (rename) {
      fetch(`${REACT_APP_SERVER_URL}/rename_campaign`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: "include",
        method: 'POST',
        body: JSON.stringify({
          "campaign": {
            "campaign_id": rename.id,
            "name": rename.name
          }
        })
      }).then(response => {
        if (response.status === 200) {
          setSuccessMessage("Change Campaign name Successfully")
          setIsSomethingChanged(!isSomethingChanged)
          setHomeLoading(false)
          setSuccessSnackbarOpen(true)
        }
        else if (response.status === 202) {
          console.log("token expired")
          navigate('/login', { state: { sessionExpired: true } })

        }
        else {
          setErrorMessage("Something went Wrong")
          setErrorSnackbarOpen(true)
          setHomeLoading(false)
        }
      })
        .catch(error => {
          setErrorMessage(error)
          setIsSomethingChanged(!isSomethingChanged)
          setErrorSnackbarOpen(true)
          setHomeLoading(false)
        })
    }
  }
  function filter(value) {
    setFilterValue(value);
    // setFilterOpen(!filterOpen);
  }

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  return (
    <ThemeProvider theme={colorTheme}>
      <Dialog
        PaperProps={{
          style: { borderRadius: "20px" }
        }}
        open={deleteCampaignDialougeOpen}
      >
        <div style={{ width: "500px", height: "200px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <DialogTitle id="alert-dialog-title">
            <p style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
              Are you sure you want to delete account?
            </p>
          </DialogTitle>
          <DialogActions sx={{ mb: 4, float: "right" }}>
            <Button onClick={() => deleteCampaignHandle(deleteId)} variant="contained" sx={{ color: "#fff" }} color='success'>Delete</Button>
            <Button onClick={() => { setdDleteCampaignDialougeOpen(false); setdDeleteId("") }} variant="contained" sx={{ color: "#fff" }} color='error' disabled={false}>
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        PaperProps={{
          style: { borderRadius: "20px" }
        }}
        open={renameCampaignDialougeOpen}
      >
        <DialogTitle id="alert-dialog-title">
          <StyledTextField sx={{ width: "300px", }} value={rename.name} onChange={e => setRename({ ...rename, name: e.target.value })} variant="outlined" />
        </DialogTitle>
        <DialogActions sx={{ mb: 4, display: 'flex', justifyContent: 'center', color: "#fff" }}>
          <Button onClick={() => renameCampaignHandle()} variant="contained" color='success' sx={{ color: "#fff" }} >Rename</Button>
          <Button onClick={() => { setRenameCampaignDialougeOpen(false); setRename({ "name": "", "id": "" }); }} variant="contained" color='error' sx={{ color: "#fff" }} disabled={false}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={filterOpen}
        onClose={filterClose}
      >
        <Box sx={{ width: "200px", height: "300px", textAlign: "center" }} >
          <Typography>
            <Button onClick={() => filter("paused")}
              sx={{ width: "150px", color: "grey" }}
            >
              <PauseCircleIcon />&nbsp;Paused
            </Button>
          </Typography>
          <Typography>
            <Button onClick={() => filter("completed")} sx={{ width: "150px", color: "green" }}>
              <DoneAllIcon />&nbsp;Completed
            </Button>
          </Typography>
          <Typography>
            <Button onClick={() => filter("draft")} sx={{ width: "150px", color: "purple" }}>
              <DraftsIcon />&nbsp;Draft
            </Button>
          </Typography>
          <Typography>
            <Button onClick={() => filter("")} sx={{ width: "150px", color: "black" }}>
              <FilterNoneIcon />&nbsp; None
            </Button>
          </Typography>

        </Box>
      </Dialog>
      {campaignId !== null &&
        <Box sx={{ width: '100%', pb: 5 }}>
          <Button sx={{ float: "right", backgroundColor: '#481BEB' }} disabled={campaignId === null} variant='contained' onClick={handleToggleCampaignStatus}>
            {
              campaignStatus === 'active' &&
              "Pause Campaign"
            }
            {
              (campaignStatus === 'paused' || campaignStatus === 'draft' || campaignStatus === 'completed' || campaignStatus === 'Error' || campaignStatus === 'error') &&
              "Launch Campaign"
            }
            {
              campaignStatus === null && "Launch Campaign"
            }
          </Button>
          <Box sx={{ display: "flex", alignItems: "center", gap: '15px' }}>
            <IconButton sx={{ float: "left", width: '48px', height: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '11px', boxShadow: '0 2px 11px 0px #0000001a' }} disabled={campaignId === null} variant='contained' onClick={() => handleCampaignClick(null, null)}>
              <ChevronLeftIcon />
            </IconButton>
            <Typography sx={{ fontSize: '18px', fontWeight: 700, float: "left" }}>{selectedCampaign}</Typography>
          </Box>
        </Box>
      }
      {
        (campaignId === null) &&
        <Box>
          <Box>
            <Stack direction='row' justifyContent='space-between'>
              <Stack direction='row' spacing={2} alignItems='center'>
                <Paper
                  component="form"
                  sx={{
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    height: "45px",
                    borderRadius: '6px',
                    boxShadow: "none",
                    border: "none",
                    color: '#989898',
                    backgroundColor: '#F7F7F9',
                    fontSize: '14px', maxWidth: '550px', width: '100%'
                  }}
                >
                  <SearchIcon sx={{ color: "#000000b0", mr: 1, fontWeight: '400' }} />

                  <InputBase
                    sx={{ flex: 1, border: "none", width: '100%', }}
                    placeholder="Search; keyword, title of campaign"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    inputProps={{
                      "aria-label": "search for projects", sx: {
                        "&::placeholder": {
                          color: "#989898",
                          fontSize: "14px",
                          fontWeight: '400',
                          fontFamily: "'Inter, Sans-serif"
                        },
                      }
                    }}
                  />
                </Paper>
                {/* <Button variant='outlined' onClick={() => setFilterOpen(true)}>Filter</Button> */}

                <FormControl sx={{ width: '130px' }} size="small">
                  <InputLabel id="demo-simple-select-autowidth-label">
                    <Stack direction='row' alignItems='center' spacing={1} sx={{ fontSize: '14px' }}><img src={filterIcon} alt="icon" width='18px' /> <span>Filter</span></Stack>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={filterValue}
                    onChange={(e) => filter(e.target.value)}
                    autoWidth
                    label="Filter"
                    inputProps={{ IconComponent: () => null, sx: { display: 'flex', alignItems: 'center', }, }}
                    sx={{ height: "45px", pr: 0, width: '130px' }}
                  >
                    <MenuItem value={"paused"} sx={{
                      width: "150px", color: "green", display: "flex", alignItems: 'center', fontSize: '14px',
                      '&[aria-selected="true"]': {
                        fontSize: '12px',
                      },
                    }}><PauseCircleIcon sx={{ transform: "", fontSize: '20px', position: 'relative', top: '-1px' }} />&nbsp;Paused</MenuItem>
                    <MenuItem value={"completed"} sx={{
                      width: "150px", color: "blue", display: "flex", alignItems: 'center', fontSize: '14px',
                      '&[aria-selected="true"]': {
                        fontSize: '12px',
                      },
                    }}><DoneAllIcon sx={{ transform: "", fontSize: '20px', position: 'relative', top: '-1px' }} />&nbsp;Completed</MenuItem>
                    <MenuItem value={"draft"} sx={{
                      width: "150px", color: "purple", display: "flex", alignItems: 'center', fontSize: '14px',
                      '&[aria-selected="true"]': {
                        fontSize: '12px',
                      },
                    }}><DraftsIcon sx={{ transform: "", fontSize: '20px', position: 'relative', top: '-1px' }} />&nbsp;Draft</MenuItem>
                    <MenuItem value={"Error"} sx={{
                      width: "150px", color: "red", display: "flex", alignItems: 'center', fontSize: '14px',
                      '&[aria-selected="true"]': {
                        fontSize: '12px',
                      },
                    }}><ErrorIcon sx={{ transform: "", fontSize: '20px', position: 'relative', top: '-1px' }} />&nbsp;Error</MenuItem>
                    <MenuItem value={""} sx={{
                      width: "150px", color: "black", display: "flex", alignItems: 'center', fontSize: '14px',
                      '&[aria-selected="true"]': {
                        fontSize: '14px'
                      },
                    }}>None</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Button variant='contained' onClick={() => setCampaignDialog(true)} sx={{ textTransform: "none", fontSize: "14px", fontWeight: "700", backgroundColor: "#481BEB", width: '110px' }}>+ Add New</Button>
            </Stack>
          </Box>
          {
            campaignTableData.length !== 0 &&
            <Box>
              <StyledTableContainer>
                <Table sx={{ minWidth: 650, borderCollapse: "separate", borderSpacing: "0 10px", }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='left'>NAME</StyledTableCell>
                      <StyledTableCell align='center'>STATUS</StyledTableCell>
                      <StyledTableCell align='center'>SENT</StyledTableCell>
                      <StyledTableCell align='center'>REPLY</StyledTableCell>
                      <StyledTableCell align='center'>OPENS</StyledTableCell>
                      <StyledTableCell align='center'>HEALTH</StyledTableCell>
                      <StyledTableCell align='center'>ACTION</StyledTableCell>
                      <StyledTableCell align='center'>DELETE</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      campaignTableData && campaignTableData.filter(x => x["status"].includes(filterValue)).filter(x => x["name"].toLowerCase().includes(searchValue.toLowerCase())).map((data, i) => (
                        <StyledTableRow key={i} hover sx={{ cursor: "pointer" }} >
                          <StyledTableCell onClick={() => handleCampaignClick(data['campaign_id'], data['status'], data['name'])} align='left' sx={{ fontSize: '16px !important', fontWeight: "700 !important", }}>{data['name']}</StyledTableCell>

                          <StyledTableCell onClick={() => handleCampaignClick(data['campaign_id'], data['status'], data['name'])} align='center'>
                            <Chip
                              sx={{
                                fontWeight: "600", fontSize: '12px', fontFamily: "'Inter', sans-serif", color: '#fff',
                                backgroundColor: data['status'] === 'active' ? '#38C68B' :
                                  data['status'] === 'paused' ? '#9491AD' :
                                    data['status'] === 'draft' ? '#FFC107' :
                                      data['status'] === 'Error' ? '#EF6D6D' :
                                        '#38C68B', // Default color
                              }}
                              label={data['status']}
                              color={
                                data['status'] === 'active' ? 'primary' :
                                  data['status'] === 'paused' ? 'default' :
                                    data['status'] === 'draft' ? 'default' :
                                      data['status'] === 'Error' ? 'error' :
                                        'success'
                              }
                            />
                          </StyledTableCell>

                          <StyledTableCell onClick={() => handleCampaignClick(data['campaign_id'], data['status'], data['name'])} align='center'>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: '5px', }}>
                              <SendIcon sx={{ fontSize: "16px", color: "#BEBEBE", marginTop: '4px', transform: "rotate(-45deg)translateY(-0.3rem)", }} />
                              {data['analytics'] && data['analytics']['sent_total']}
                            </div>
                          </StyledTableCell>

                          <StyledTableCell onClick={() => handleCampaignClick(data['campaign_id'], data['status'], data['name'])} align='center'>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: '5px', }}>
                              <InboxIcon sx={{ fontSize: "16px", color: "#BEBEBE", }} />
                              {data['analytics'] && data['analytics']['reply_count']}
                            </div>
                          </StyledTableCell>

                          <StyledTableCell onClick={() => handleCampaignClick(data['campaign_id'], data['status'], data['name'])} align='center'>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: '5px', }}>
                              <ErrorIcon sx={{ fontSize: "16px", color: "#BEBEBE", }} />
                              {data['analytics'] && data['analytics']['open_count']}
                            </div>
                          </StyledTableCell>

                          <StyledTableCell onClick={() => handleCampaignClick(data['campaign_id'], data['status'], data['name'])} align='center'>
                            <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", color: "#38C68B" }}>
                              <StopRoundedIcon sx={{ fontSize: "30px", }} />
                              {/* {data['analytics'] && data['analytics']['unique_opens']} */}
                              Good
                            </div>
                            {/* {data["unique_opens"] === 0 && (
                              <div style={{ display: "flex", alignItems: "center", color: "#BEBEBE" }}>
                                <StopRoundedIcon sx={{ fontSize: "30px", }} />
                                NA
                              </div>
                            )}
                            {(data["inbox"] * 100) / data["unique_opens"] < 70 && (
                              <div style={{ display: "flex", alignItems: "center", color: "#EF6D6D", }}>
                                <StopRoundedIcon sx={{ fontSize: "30px", }} />
                                Bad
                              </div>
                            )}
                            {(data["inbox"] * 100) / data["unique_opens"] >= 70 &&
                              (data["inbox"] * 100) / data["unique_opens"] < 90 && (
                                <div style={{ display: "flex", alignItems: "center", color: "#EFA10D", }}>
                                  <StopRoundedIcon sx={{ fontSize: "30px", }} />
                                  Fair
                                </div>
                              )}
                            {(data["inbox"] * 100) / data["unique_opens"] >= 90 && (
                              <div style={{ display: "flex", alignItems: "center", color: "#38C68B", }}>
                                <StopRoundedIcon sx={{ fontSize: "30px", }} />
                                Good
                              </div>
                            )} */}
                          </StyledTableCell>

                          <StyledTableCell onClick={() => handleCampaignClick(data['campaign_id'], data['status'], data['name'])} align='center'>
                            <Tooltip title="Play/Pause Button">

                              <IconButton sx={{ color: "#989898" }} onClick={(e) => {
                                e.stopPropagation()
                                handleToggleCampaignStatus(data['status'], data['campaign_id'], data['name'])
                              }
                              }>
                                {
                                  data['status'] === 'active' &&
                                  <PauseCircleIcon />
                                }
                                {
                                  (data['status'] === 'paused' || data['status'] === 'draft' || data['status'] === 'completed' || data['status'] === 'Error') &&
                                  <PlayCircleFilledWhiteIcon />
                                }
                              </IconButton>
                            </Tooltip>
                            <IconButton onClick={(e) => {
                              e.stopPropagation()
                              renameDialougeOpen(data['name'], data['campaign_id'])
                            }}>
                              <Tooltip title="Edit Button">
                                <EditIcon sx={{ color: "#989898", }} />
                              </Tooltip>
                            </IconButton>
                          </StyledTableCell>

                          <StyledTableCell align='center' sx={{ fontSize: "14px", fontWeight: '700' }}>
                            <Button sx={{ color: "#EF6D6D" }}
                              onClick={() => deleteCampaignClicked(data['campaign_id'])}
                            >
                              <LightTooltip title="Delete Button">
                                <Delete />
                              </LightTooltip>
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    }
                    {/* {campaignTableData && !([] === campaignTableData.filter(x => x["status"].includes(filterValue)).filter(x => x["name"].toLowerCase().includes(searchValue.toLowerCase())))?"afds":"faaaaa"} */}

                  </TableBody>
                </Table>
              </StyledTableContainer>
              {/* < Stack >
                {
                  campaignTableData.map((data) => (
                    <Box onClick={() => handleCampaignClick(data['campaign_id'], data['status'])} key={data} sx={{ cursor: "pointer", my: 1, p: 2, boxShadow: "0px 2px 11px rgba(0,0,0,0.07)", display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                      <Typography >
                        {data['name']}
                      </Typography>
                      <Typography>
                        {data['status']}
                      </Typography>
                    </Box>
                  ))
                }
              </Stack > */}
            </Box>
          }
        </Box>
      }
      {
        campaignId !== null &&
        <CampaignFunctions status={campaignStatus} id={campaignId} handleBackButtonClick={handleCampaignClick} saveErrorHandler={saveErrorHandler} />
      }

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={campaignDialog}
      >
        <Stack gap={1} justifyContent="center" alignItems="center">
          <Box sx={{ color: "black", width: "620px", p: 3, height: "300px", display: "flex", justifyContent: "space-around" }}>
            <Box sx={{ width: "510px", height: "250px", borderRadius: "30px", backgroundColor: "white", display: "flex", justifyContent: "space-around", flexDirection: "column", p: 2 }}>
              <Typography
                fontSize="24px" sx={{ fontWeight: '700', }}
              >Name Your campaign :)</Typography>

              <StyledTextField
                label="New Campaign"
                onChange={(e) => setCampaignName(e.target.value)}
                fullWidth
                sx={{ backgroundColor: "rgb(247, 245, 245)", border: "none", outline: "none", borderRadius: "10px" }}
              />
              <Button
                onClick={newCampaignSubmitHandler}
                sx={{
                  backgroundColor: "#481BEB", color: "white", borderRadius: '6px',
                  width: "180px", border: "1px solid", "&:hover": {
                    backgroundColor: 'white',
                    color: "#fff",
                    border: "1px solid"
                  }
                }}
              >
                +&nbsp;Add Campaign
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "40px",
                height: "40px",
                backgroundColor: "white",
                borderRadius: "50%",
              }}
            >
              <Button onClick={handleCampaignDialogClose}
                sx={{ borderRadius: "50%", backgroundColor: "none", color: '#4F4F4F' }}
              >X</Button>
            </Box>
          </Box>
        </Stack>
      </Backdrop>
      {/* <Dialog open={campaignDialog} onClose={handleCampaignDialogClose}  >
        
      </Dialog> */}


      <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
        <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
        <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  )
  //   return (
  //     {
  //       (campaignTableData.length === 0 && campaignId === null) &&
  //     <>
  //       <Container sx={{ p: 4, display: "flex", alignItems: "center", justifyContent: "center" }} maxWidth="md">
  //         <TextField
  //           label="name"
  //           onChange={(e) => setCampaignName(e.target.value)}
  //           fullWidth
  //         />
  //         <Button onClick={newCampaignSubmitHandler}>Submit</Button>
  //       </Container>
  //     </>
  //     }
  // {
  //   (campaignTableData.length !== 0 && campaignId === null) &&
  //     < Stack >
  //       {
  //         campaignTableData.map((data) => (
  //           <Box key={data} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
  //             <Typography>
  //               {data['name']}
  //             </Typography>
  //             <Typography>
  //               {data['status']}
  //             </Typography>
  //           </Box>
  //         ))
  //       }
  //     </Stack >
  // }
  // {
  //   <CampaignFunctions id={campaignId} />
  // }
  //   )
}

export default Campaign