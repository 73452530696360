import { useDropzone } from 'react-dropzone'
import Dialog from "@mui/material/Dialog";
import DialogContent from '@mui/material/DialogContent';
import DeleteIcon from '@mui/icons-material/Delete';
import LinearProgress from '@mui/material/LinearProgress';
import {
  Tab,
  Tabs,
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  DialogActions,
  Stack,
  Grid,
  TableContainer, TableHead, TableRow, Table,
  Snackbar,
  TableBody,
  TableCell,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import jwt from "jwt-decode";
import cookie from "cookiejs";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import { StyledTextField } from "../../stylings/styles";
import { makeStyles } from '@mui/styles';
import importIcon from '../../img/icons/importIcon.png';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { styled } from '@mui/material/styles';
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { tableCellClasses } from '@mui/material/TableCell';

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#fff",
    fontSize: "12px",
    fontWeight: "700",
    color: "#989898",
    fontFamily: "'Inter', sans-serif",
    border: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: "500",
    border: "none",
    color: "#141B3A",
    fontFamily: "'HK Nova', sans-serif",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderRadius: "10px",
  boxShadow: "0 2px 11px 0px #0000001a",
  marginBottom: "10px",
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

const StyledTableContainer = styled(TableContainer)({
  borderRadius: "10px",

  // Style the scroll bar
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "2px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#D0CED5",
    borderRadius: "6px",
  },
});

const StyledButtonOutlined = styled(Button)(({ theme }) => ({
  paddingY: theme.spacing(1),
  color: "#481BEB",
  borderColor: "#E7E7E7",
  fontSize: "12px",
  fontFamily: "'Inter', sans-serif",
  "&:hover": {
    borderColor: "#E7E7E7",
  },
}));

const useStyles = makeStyles({
  customStyleOnTab: {
    color: "#4F4F4F",
    fontWeight: 700,
  },
  customStyleOnActiveTab: {
    background: "none",
  },
  activeTab: {
    fontWeight: 700,
    backgroundColor: "#fff",
    borderRadius: "6px",
    color: "#481BEB",
  },
});

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function Setting(props) {
  const classes = useStyles();
  const user = jwt(cookie.get("token"));
  const [showPasswordToggle, setShowPasswordToggle] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [manualData, setManualData] = React.useState([]);
  const [csvFile, setCsvFile] = React.useState();
  const [manualEntry, setManualEntry] = React.useState(false);
  const [headers, setHeaders] = React.useState(null);
  const [rows, setRows] = React.useState(null);
  const [profile, setProfile] = useState(true);
  const [block, setBlock] = useState(false);
  const [pop, setPop] = useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [csvText, setCSVTxt] = React.useState("");
  const [typeMap, setTypeMap] = React.useState(null);
  const [isSomethingChanged, setIsSomethingChanged] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [leadsLoading, setLeadsLoading] = React.useState(false);
  const [blockList, setblockList] = useState([]);
  const [block_list_loading, setBlockLoading] = useState(true);
  const [block_csv, setBlockCSV] = useState([]);

  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessSnackbarOpen(false);
  }

  const addRowHandler = () => {
    let tempManualData = manualData
    tempManualData.push({
      email: "",
      user: user.email
    })
    setIsSomethingChanged(!isSomethingChanged)
    setManualData(tempManualData)
  }

  const handleTabChange = (e, newValue) => {
    if (newValue === 1) {
      setBlock(true);
      setProfile(false);
    }
    else {
      setBlock(false);
      setProfile(true);
    }
    setTabValue(newValue)
  }


  const handleClose = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setFirstName(user.firstname);
    setLastName(user.lastname);
    get_list();
  }, []);


  async function get_list() {
    setBlockLoading(true);
    fetch(`${REACT_APP_SERVER_URL}/get_list`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user": user.email
      },
      credentials: "include",
      method: "GET",
    }).then(res => {
      return res.json();
    }).then((data) => {
      setBlockLoading(false);
      if (data.status === 200) {
        setblockList(data.emails);
      }
      else {
        setErrorSnackbarOpen(true);
        seterrorMsg("Something went wrong");
        return;
      }
    })
  }

  const changeUserNameFunction = () => {
    setLoading(true);
    fetch(`${REACT_APP_SERVER_URL}/update-profile`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        fname: firstName,
        lname: lastName,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          console.log("Success");
          setsuccessMsg(response.msg);
          setChangePasswordSuccess(true);
          return response.json();
        } else if (response.status === 202) {
          setLoading(false);
          console.log("token expired");
          seterrorMsg(response.msg);
          setErrorSnackbarOpen(true);
        } else {
          console.log("some error");
          setLoading(false);
          setErrorSnackbarOpen(true);
          return response.json();
        }
      })
      .then((data) => {
        if (data.status == 500) {
          seterrorMsg(data.msg);
          return;
        }
        setLoading(false);
        setsuccessMsg(data.msg);
        setChangePasswordSuccess(true);
        console.warn("data collected", data);
        props.User(data.data);
      })
      .catch((error) => console.log(error));
  };

  function validatePW(str) {
    if (str.length <= 5) {
      return false;
    }

    if (!/[A-Z]/.test(str)) {
      return false;
    }
    if (!/[a-z]/.test(str)) {
      return false;
    }
    return true;
  }

  const changePasswordNameFunction = () => {
    setLoading(true);
    if (validatePW(newPassword) === true) {
      if (newPassword !== confirmPassword) {
        setErrorSnackbarOpen(true);
        seterrorMsg("Confirm password and new password must be same ");
        setLoading(false);
      } else {
        fetch(`${REACT_APP_SERVER_URL}/update-password`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include",
          method: "POST",
          body: JSON.stringify({
            password: newPassword,
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              setLoading(false);
              setOpen(false);
              console.log("Success");
              setsuccessMsg(response.msg);
              setChangePasswordSuccess(true);
              return response.json();
            } else if (response.status === 202) {
              setLoading(false);
              console.log("token expired");
              seterrorMsg(response.msg);
              setErrorSnackbarOpen(true);
            } else {
              console.log("some error");
              setLoading(false);
              seterrorMsg(response.msg);
              setErrorSnackbarOpen(true);
            }
          })
          .then((data) => {
            setLoading(false);
            setsuccessMsg(data.msg);
            setChangePasswordSuccess(true);
            console.warn("data collected", data);
          })
          .catch((error) => console.log(error));
      }
    } else {
      setErrorSnackbarOpen(true);
      seterrorMsg("Password Does Not Match Requirments");
      setLoading(false);
    }
  };

  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorSnackbarOpen(false);
  };
  const handleChangePasswordSuccessSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setChangePasswordSuccess(false);
  };

  const handleDialogClose = () => {
    setManualData([])
    setManualEntry(false)
    setCsvFile(undefined)
    setHeaders(null)
    setRows(null)
    setPop(false)
  }

  const processCsvForSeperating = (csvData) => {
    console.log(csvData.split('\n'), 'err');
    const rows = csvData.trim().split('\n');
    const header = rows[0].split(',');
    const data = {};

    for (let i = 1; i < rows.length; i++) {
      const values = rows[i].split(',');
      for (let j = 0; j < values.length; j++) {
        const columnName = header[j]?.replace(/ |\r\n|\r|\n/g, '');
        const columnValue = values[j]?.trim();

        if (!data[columnName]) {
          data[columnName] = [];
        }
        data[columnName].push(columnValue);
      }
    }
    data['headers'] = header.length;
    setCSVTxt(data)
  }

  const processCSV = (str, delim = ',') => {
    const head = str.slice(0, str.indexOf('\r\n')).split(delim);
    setHeaders(head);
    let tempTypeMap = {}
    console.log(head)
    for (let i = 0; i < head.length; i++) {
      tempTypeMap[head[i]] = null
    }
    setTypeMap(tempTypeMap)
  }

  const submit = () => {
    try {
      const file = csvFile;
      const reader = new FileReader();
      reader.onload = function (e) {
        const text = e.target.result;
        let res = text.split('\n');
        let res1 = [];
        res.map((data, index) => {
          let in1 = 0;
          let in2 = (data.length - 2);
          res1.push({ email: data.slice(1, in2), user: user.email })
        })
        setBlockCSV(res1);
        processCsvForSeperating(text)
        processCSV(text)
      }
      reader.readAsText(file);
    }
    catch (err) {
      console.log(err)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    noClick: csvFile === undefined ? false : true,
    accept: { "text/csv": [".csv"] },
    onDrop: async (acceptedFiles) => {
      setCsvFile(acceptedFiles[acceptedFiles.length - 1])
      setUploading(true);
      setUploading(false);
      setUploadProgress(0);
    }

  });

  const handleSelectChange = (col, data) => {
    let tempTypeMap = typeMap
    tempTypeMap[col] = data
    setTypeMap(tempTypeMap)
    setIsSomethingChanged(!isSomethingChanged)
  }

  const handleTypeSelectHandler = async () => {
    setButtonLoading(true)
    fetch(`${REACT_APP_SERVER_URL}/upload_csv_blockList`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include",
      method: 'POST',
      body: JSON.stringify({
        "data": block_csv,
        "user": user.email
      })
    }).then((response) => {
      return response.json();
    }).then((data) => {
      setButtonLoading(false);
      if (data.status === 200) {
        let temp = blockList.concat(data.result);
        setblockList(temp);
        handleDialogClose();
        setSuccessSnackbarOpen(true);
        setSuccessMessage(data.msg);
      }
      else {
        setErrorSnackbarOpen(true);
        seterrorMsg("something went wrong");
        return;
      }
    })
  }

  const validateEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  };

  const manualDataChangeHandler = (data, type, i) => {
    let tempManualData = manualData
    tempManualData[i][type] = data
    setIsSomethingChanged(!isSomethingChanged)
    setManualData(tempManualData)
  }

  function removeRow(index) {
    if (manualData.length === 1) {
      setErrorSnackbarOpen(true);
      seterrorMsg("Row cannot be empty!");
      return;
    }
    else {
      let tempManualData = manualData;
      tempManualData.splice(index, 1);
      setIsSomethingChanged(!isSomethingChanged)
      setManualData(tempManualData)
    }
  }

  function manualDataSubmitHandler() {
    setLeadsLoading(true)
    fetch(`${REACT_APP_SERVER_URL}/block_list`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include",
      method: 'POST',
      body: JSON.stringify({
        "data": {
          'data': manualData,
          'user': user.email
        },
      })
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.status === 200) {
        let temp = blockList.concat(data.result);
        handleDialogClose();
        setblockList(temp);
        setSuccessSnackbarOpen(true);
        setSuccessMessage(data.msg);
        setLeadsLoading(false);
      }
      else {
        setErrorSnackbarOpen(true);
        seterrorMsg("something went wrong");
        return;
      }
    })
  }


  function validateEmails(array) {
    return array.every((obj) => obj['email'] && validateEmail(obj['email']));
  }


  return (
    <>
      <Box sx={{ width: "100%", borderRadius: "10px", backgroundColor: "#f7f7f9", display: "flex", gap: "10px", marginLeft: '25px' }}>
        <Tabs value={tabValue}
          variant="fullWidth"
          onChange={handleTabChange}
          classes={{ indicator: classes.customStyleOnActiveTab }}
          indicatorColor={'#607d8b'}
          centered
        >
          <Tab
            disableRipple sx={{ padding: '10px 10px' }}
            label={<div style={{ fontSize: '14px', textTransform: "none", display: "flex", alignItems: "center", justifyContent: 'center', width: "100px", height: "50px" }}
              className={tabValue === 0 ? classes.activeTab : classes.customStyleOnTab}><span>PROFILE</span></div>}
          />
          <Tab
            disableRipple sx={{ padding: '10px 10px' }}
            label={<div style={{ fontSize: '14px', textTransform: "none", display: "flex", alignItems: "center", justifyContent: 'center', width: "100px", height: "50px" }}
              className={tabValue === 1 ? classes.activeTab : classes.customStyleOnTab}><span>EMAILS</span></div>}
          />
        </Tabs>
      </Box>
      {
        profile &&
        <Box sx={{ width: "100%", height: "100%", backgroundColor: "#fafafa", boxShadow: "5px 5px 10px #f2f0f0", borderRadius: '10px', p: 4, marginTop: '20px', marginLeft: '22px' }}>
          {loading ? (
            <Box sx={{ width: "100%", height: "400px", display: 'flex', gap: '10px', marginLeft: '45%' }}>
              <CircularProgress sx={{ position: 'relative', top: '-5px' }} />
              &nbsp;updating.....
            </Box>
          ) : (
            <>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  sx={{ p: 3, pt: 1 }}
                  width="100%"
                >
                  <Box>
                    <Box sx={{ display: "flex", gap: '10px', marginTop: '40px' }}>
                      <StyledTextField
                        id="standard-search"
                        label="New password"
                        type={!showPasswordToggle ? "password" : "text"}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        fullWidth
                        sx={{ backgroundColor: "#fee" }}
                      />
                    </Box>
                    <Box sx={{ display: "flex", gap: '10px' }}>
                      <StyledTextField
                        id="standard-search"
                        label="Confirm password"
                        type={!showPasswordToggle ? "password" : "text"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        fullWidth
                        sx={{ backgroundColor: "#fee" }}
                      />
                    </Box>
                    <li>Password length must be Greater than 5</li>
                    <li>Must have one uppercase character</li>
                    <li>Must have one lowercase character</li>
                    <Checkbox
                      sx={{ margin: "20px auto" }}
                      onClick={() => setShowPasswordToggle(!showPasswordToggle)}
                    />{" "}
                    Show password
                    <Button
                      sx={{ float: "right", margin: 3, marginRight: '-0px' }}
                      onClick={changePasswordNameFunction}
                      variant="contained"
                    >
                      Change Password
                    </Button>
                  </Box>
                </Grid>
              </Dialog>
              <Box sx={{ width: "100%", borderRadius: "10px" }}>
                <Box sx={{ width: "70%" }}>
                  <Container
                    width="lg"
                  /* sx={{ backgroundColor: "#fff", borderRadius: "10px", mt: 1 }} */
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        sx={{ p: 1, pt: 5 }}
                      >
                        <Typography sx={{ fontSize: "20px", fontWeight: "700", marginLeft: '5px' }}>
                          Name
                        </Typography>
                        <Box sx={{ display: "flex", gap: '10px' }}>
                          <StyledTextField
                            required
                            placeholder="Name"
                            type="name"
                            onChange={(e) => setFirstName(e.target.value)}
                            name="name"
                            value={firstName}
                            fullWidth
                            sx={{ backgroundColor: "#fff" }}
                          />
                          <StyledTextField
                            required
                            placeholder="Last"
                            type="name"
                            name="name"
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                            fullWidth
                            sx={{ backgroundColor: "#fff" }}
                          />
                        </Box>
                        <Typography sx={{ fontSize: "20px", fontWeight: "700", marginLeft: '5px' }}>
                          Email
                        </Typography>
                        <StyledTextField
                          required
                          placeholder="Email"
                          type="email"
                          name="email"
                          value={user.email}
                          disabled
                          fullWidth
                          sx={{ backgroundColor: "#fff" }}
                        />
                        {/* <br></br> */}
                        <Typography sx={{ fontSize: "20px", fontWeight: "700", marginLeft: '5px' }}>
                          Password
                        </Typography>
                        <StyledTextField
                          required
                          placeholder="password"
                          type="password"
                          name="email"
                          aria-disabled
                          value={"12345"}
                          fullWidth
                          sx={{ backgroundColor: "#fff" }}
                          onClick={() => {
                            setOpen(true);
                          }}
                        />
                        <Button onClick={changeUserNameFunction} sx={{ marginLeft: '-5px' }}>
                          Update Profile
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
              </Box>
            </>
          )}
        </Box>
      }
      {
        block && <Box sx={{ marginTop: '30px' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginLeft: '28px' }} >
            <Stack direction="row" alignItems="center" gap="20px">
              <Typography>Total mails: <strong>{blockList.length}</strong></Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap="20px" sx={{ float: 'right' }}>
              <Button onClick={() => setPop(true)} sx={{ ml: 1, mb: 1, backgroundColor: '#481BEB', marginRight: '-22px' }} variant="contained">Add data </Button>
            </Stack>
          </Stack>

          <Dialog
            onClose={handleDialogClose}
            open={pop}
            maxWidth='md'
          >
            {/* <DialogTitle>
                    Add Data 
                </DialogTitle> */}
            <DialogContent sx={{
              "&::-webkit-scrollbar": {
                width: "5px",
                height: "2px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#D0CED5",
                borderRadius: "6px",
              },
            }}
            >
              {
                !manualEntry &&
                <>
                  {
                    headers === null &&
                    <Box sx={{width:'600px'}}>
                      <Container {...getRootProps()} maxWidth="sm" sx={{ gap: '15px', borderRadius: "10px", p: 4, border: "1px solid #E7E7E7", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        {
                          csvFile === undefined &&
                          <>
                            <img src={importIcon} alt="icon" width='' />
                            <input
                              {...getInputProps()}
                            />
                            <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "#141B3A" }}>
                              Drag & Drop file here
                            </Typography>
                            <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "#141B3A" }}>or</Typography>
                            <StyledButtonOutlined
                              variant='outlined'
                              sx={{ textTransform: "none", borderColor: '#989898', color: '#7D7D7D' }}
                            >
                              Browse Files
                            </StyledButtonOutlined>

                            <Typography align="center" sx={{ color: "#7D7D7D", fontSize: "12px" }}>
                              <Checkbox
                                // sx={{
                                //     color: '#EF6D6D',
                                //     '&.Mui-checked': {
                                //         color: '#EF6D6D',
                                //     },
                                // }}
                                icon={<RadioButtonUncheckedIcon sx={{ border: '#481BEB' }} />} checkedIcon={<CheckCircleIcon sx={{ color: '#481BEB' }} />}
                              />
                              Import a CSV file. You can put the columns in any order, XCampaign will automatically recognize each custom variable.
                            </Typography>
                          </>
                        }
                        {
                          csvFile !== undefined &&
                          <>
                            <Typography>{csvFile.name}</Typography>
                            {uploading ? (
                              <Box sx={{ width: '100%' }}>
                                <LinearProgressWithLabel value={uploadProgress} />
                              </Box>
                            ) : (
                              <Button variant="outlined" onClick={submit}>Upload</Button>
                            )}
                          </>
                        }
                      </Container>
                      <Typography align="center" sx={{ my: 2 }}>or</Typography>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button onClick={() => {
                          setManualEntry(true)
                          addRowHandler()
                        }} variant='contained' sx={{ backgroundColor: '#481BEB' }}>Enter Manually</Button>
                      </Box>
                    </Box>

                  }
                  {
                    headers !== null &&
                    <>
                      <TableContainer sx={{ borderRadius: '10px', border: "1px solid #E7E7E7" }}>
                        <Table sx={{ minWidth: 650, }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell sx={{ textTransform: "uppercase" }}>Column from sheet</StyledTableCell>
                              <StyledTableCell align='right' sx={{ textTransform: "uppercase" }}>Field Type</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              headers.map((row, index) => (
                                <StyledTableRow key={index} sx={{ boxShadow: "none", borderTop: "1px solid #E7E7E7" }}>
                                  <StyledTableCell>
                                    {row}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                        <Button variant='contained' sx={{ float: "right", px: 5, py: 1 }} onClick={handleTypeSelectHandler}>
                          {buttonLoading ? (
                            <CircularProgress size={25} sx={{ color: "#fff" }} />
                          ) : (
                            'Submit'
                          )}
                        </Button>
                      </Box>
                    </>
                  }
                </>
              }
              {
                manualEntry &&
                <>
                  {
                    manualData.map((data, i) => (
                      <Box sx={{ display: "flex", justifyContent: "space-between", my: 2, width: '600px' }}>
                        <StyledTextField
                          type='email'
                          label="Email"
                          value={data["email"]}
                          helperText={data['email'] ? validateEmail(data['email']) ? "" : "Please enter valid email format" : ""}
                          error={data["email"] ? !validateEmail(data['email']) : false}
                          required
                          onChange={(e) => {
                            manualDataChangeHandler(e.target.value, "email", i)
                          }}
                          sx={{ backgroundColor: "#E7E7E7", borderRadius: "5px", width: '450px' }}
                        />
                        <Button sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: '20px', borderRadius: '12px', paddingLeft: '20px', marginRight: '15px' }} onClick={(e) => { removeRow(i) }} color="error" startIcon={<DeleteIcon sx={{ mx: 0 }} />}>
                        </Button>
                      </Box>
                    ))
                  }
                  <Button sx={{ float: "left" }} onClick={addRowHandler} variant='contained'>Add Row</Button>
                  <Button sx={{ float: "right", marginTop: '10px' }} onClick={manualDataSubmitHandler} variant='outlined' disabled={leadsLoading || !validateEmails(manualData)}>
                    {
                      leadsLoading && <CircularProgress size={25} />
                    }
                    {
                      !leadsLoading && "Submit"
                    }
                  </Button>
                </>
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} variant='outlined' color='error' sx={{ marginRight: '15px' }}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {
            block_list_loading ?
              <Box sx={{ width: "100%", display: "center", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
              :
              <Box>
                {
                  blockList.length != 0 ?
                    <StyledTableContainer>
                      <Table sx={{ minWidth: 350, maxWidth: 650, marginLeft: '20%', borderCollapse: "separate", borderSpacing: "0 10px", }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell >EMAIL</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            blockList.map((data, i) => (
                              <StyledTableRow hover sx={{ cursor: "pointer" }} key={i}>
                                <StyledTableCell >
                                  {data.email}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </StyledTableContainer> :
                    <StyledTableContainer>
                      <Table sx={{ minWidth: 350, maxWidth: 650, marginLeft: '20%', borderCollapse: "separate", borderSpacing: "0 10px", }} aria-label="simple table">
                        <Typography sx={{ textAlign: 'center', fontFamily: 'sans-serif', fontWeight: 'bolder', fontSize: '30px' }}>No data present</Typography>
                      </Table>
                    </StyledTableContainer>
                }
              </Box>
          }
        </Box >
      }
      {/* Yaha se alert box ka code h  */}
      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert
          onClose={handleErrorSnackbarClose}
          variant="filled"
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
        <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        open={changePasswordSuccess}
        autoHideDuration={6000}
        onClose={handleChangePasswordSuccessSnackbar}
      >
        <Alert
          onClose={handleChangePasswordSuccessSnackbar}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Setting;
