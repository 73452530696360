import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, Container, Typography, Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import xCampaignLogo from '../../img/icons/xCampaignLogo.png'
import HomeButton from "../../img/icons/HomeButton.png"
import rectangle from '../../img/svg/rectangle.png'
import ring from '../../img/svg/ring.png'

const mdTheme = createTheme({
    typography: {
        fontFamily: [
            'League Spartan',
            'sans-serif',
        ].join(','),
    }
});
const Success = () => {
    const navigate = useNavigate();
    const submitHandler = () => {
        navigate('/dashboard/subscriptions')
    }
    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <Link exact to="/" >
                <img src={HomeButton} alt="brand-logo" style={{ cursor: "pointer" }} />
            </Link>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img src={xCampaignLogo} alt='brand-logo' />
            </Box>
            <Box maxWidth='sm' sx={{ margin: '0 auto', mt: 10, display: "flex", justifyContent: "center", alignItems: "center", position: 'relative' }}>
                <Container maxWidth='xs' sx={{ zIndex: 10, backgroundColor: "#fff", borderRadius: '10px', p: 5, px: 4, boxShadow: "0px 32px 100px rgba(50, 47, 85, 0.1)", padding: '100px' }}>
                    <Typography sx={{ fontSize: "32px", fontWeight: "bold", fontFamily: "'HK Nova', sans-serif", mb: 4, color: "#38C68B", textAlign: "center" }}>
                        Payment Successful
                    </Typography>
                    {/* <Button
                                type='submit'
                                variant='contained'
                                fullWidth
                                sx={{ mt: 1, borderRadius: "6px", textTransform: "none", fontSize: "14px", fontWeight: "700", backgroundColor: "#481BEB" }}
                            >
                                Send Reset Link
                            </Button> */}
                    {<Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        onClick={submitHandler}
                        sx={{ my: 1, py: 2, borderRadius: "6px", textTransform: "none", fontSize: "14px", fontWeight: "500", backgroundColor: "#3D17C6" }}
                    >
                        Go To Dashboard
                    </Button>}
                </Container>
                <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", right: "calc(100% - 15%)", top: "0", zIndex: 1 }}>
                    <circle cx="23.5" cy="23.5" r="23.5" fill="#FFE163" />
                </svg>
                <img src={rectangle} alt='svg' style={{ position: "absolute", bottom: "-50px", left: "0" }} />
            </Box>
            <img src={ring} alt='svg' style={{ width: "120px", position: "absolute", bottom: '', right: "10%" }} />

        </ThemeProvider>
    )
}

export default Success