import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './editor.css';

const ControlledEditor = ({ value, setStepsData, stepsData, selectedStep, setActiveField, setBodyEmailContent, onChange, onContentChanged }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (value) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(value))
        )
      );
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [value]);

  useEffect(() => {
    if (value) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(value))
        )
      );
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, []);

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    let newValue = draftToHtml(convertToRaw(contentState));
    if (onChange) {
      setBodyEmailContent(newValue)
    } else {
      let tempStepsData = stepsData
      tempStepsData['data'][selectedStep]["varient"][stepsData['data'][selectedStep]['current_varient'] - 1]['body'] = newValue
      setStepsData(tempStepsData)
      setActiveField('DraftEditor');
    }
  };


  const handleImageUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <Editor
      editorState={editorState}
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor"
      onEditorStateChange={onEditorStateChange}
      placeholder="Write your email content...."
      toolbar={{
        history: { inDropdown: true },
        image: {
          uploadCallback: handleImageUpload,
          alt: { present: true, mandatory: false },
        },
      }}
      editorStyle={{ direction: 'ltr' }}
    />
  );
};

export default ControlledEditor;