import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Box, FormControlLabel, Checkbox, Container, Grid, Typography, Button, TextField, CircularProgress, Snackbar, Alert, InputAdornment, IconButton, Stack } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import registerFrame from '../img/images/registerFrame.png'
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import HomeButton from "../img/icons/HomeButton.png"
import xCampaignLogo from '../img/icons/xCampaignLogo.png'
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL

const mdTheme = createTheme({
    typography: {
        fontFamily: ["HK Nova", "Inter"].join(","),
    },
});

const Register = () => {
    const navigate = useNavigate();
    const [firstname, setFirstname] = React.useState('');
    const [lastname, setLastname] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [email, setEmail] = React.useState('')
    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false)
    const [buttonLoading, setButtonLoading] = React.useState(false)
    const [termsCondition, setTermsCondition] = React.useState(false)
    const [privacyPolicy, setPrivacyPolicy] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorSnackbarOpen(false);
    }
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessSnackbarOpen(false);
    }

    function validatePW(str) {
        if (str.length <= 5) {
            return false;
        }

        if (!/[A-Z]/.test(str)) {
            return false;
        }
        if (!/[a-z]/.test(str)) {
            return false;
        }
        return true;
    }

    function validateEmail(data) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(data);
    }

    const registerButtonHandler = (e) => {
        e.preventDefault();
        setButtonLoading(true);

        if (firstname.length < 3 || !validateEmail(email)) {
            setErrorSnackbarOpen(true);
            setButtonLoading(false);
            setErrorMessage('Enter valid name and email');
            return;
        }
        else {
            if (validatePW(password)) {
                fetch(`${REACT_APP_SERVER_URL}/register`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    method: 'POST',
                    credentials: 'include',
                    body: new URLSearchParams({
                        'fname': firstname,
                        'lname': lastname,
                        'password': password,
                        'email': email,
                    })
                })
                    .then(response => {
                        if (response.status === 200) {
                            console.log('register successfully')
                            setButtonLoading(false)
                            setSuccessSnackbarOpen(true)
                            setTimeout(() => {
                                navigate('/login')
                            }, 5000)
                            return response.json()
                        } else {
                            console.log('register failed')
                            setButtonLoading(false)
                            setErrorSnackbarOpen(true)
                            return response.json()
                        }
                    }).then(data => {
                        console.log(data);
                        setErrorMessage(data.msg)
                        setSuccessMessage(data.msg)
                    })
            } else {
                setButtonLoading(false);
                setErrorSnackbarOpen(true);
                setErrorMessage("Enter Valid Password");
            }
        }
    }
    console.log(termsCondition)
    return (
        <ThemeProvider theme={mdTheme}>
            <Link exact to="/" >
                <img src={HomeButton} alt="brand-logo" style={{ cursor: "pointer", marginBottom: "-20px" }} />
            </Link>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Container width='lg' sx={{ backgroundColor: "#fff", borderRadius: '10px', mt: 1 }}>
                    <Grid container sx={{ height: "100%" }}>
                        <Grid item xs={12} sm={12} md={6} lg={7} xl={7} sx={{ p: 3, display: "flex", justifyContent: "center", flexDirection: "column" }}>
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <img src={xCampaignLogo} alt='xlogo' style={{ width: "35%" }} />
                            </Box>
                            <img src={registerFrame} alt="Register" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={5} xl={5} sx={{ padding: '5%', boxShadow: "0px 32px 100px rgba(50,47,85,0.1)", borderRadius: "40px" }}>
                            <Typography sx={{ fontSize: "32px", fontWeight: "bold", fontFamily: "'HK Nova', sans-serif", mb: 4, }}>
                                Create a New Account
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography sx={{ fontSize: "14px", pb: 1, fontWeight: "600", fontFamily: "'Inter', sans-serif", }}>
                                        First Name
                                    </Typography>
                                    <TextField
                                        required
                                        placeholder='Enter First Name'
                                        name='firstName'
                                        onChange={(e) => setFirstname(e.target.value)}
                                        fullWidth
                                        inputProps={{
                                            style: { color: "#9491AD", fontSize: '14px' },
                                        }}
                                        sx={{
                                            fontFamily: "'Inter', sans-serif",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            backgroundColor: "#f7f7f9",
                                            borderRadius: "8px",
                                            color: "#9491AD",
                                            mb: 3,
                                            "& fieldset.MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography sx={{ fontSize: "14px", pb: 1, fontWeight: "600", fontFamily: "'Inter', sans-serif", }}>
                                        Last Name
                                    </Typography>
                                    <TextField
                                        required
                                        placeholder='Enter Last Name'
                                        name='lastName'
                                        onChange={(e) => setLastname(e.target.value)}
                                        fullWidth
                                        inputProps={{
                                            style: { color: "#9491AD", fontSize: '14px' },
                                        }}
                                        sx={{
                                            fontFamily: "'Inter', sans-serif",
                                            fontWeight: "400",
                                            fontSize: "14px",
                                            backgroundColor: "#f7f7f9",
                                            borderRadius: "8px",
                                            color: "#9491AD",
                                            mb: 3,
                                            "& fieldset.MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Typography sx={{ fontSize: "14px", pb: 1, fontWeight: "600", fontFamily: "'Inter', sans-serif", }}>
                                Email
                            </Typography>
                            <TextField
                                required
                                type='email'
                                placeholder='Enter your email'
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                                inputProps={{
                                    style: { color: "#9491AD", fontSize: '14px' },
                                }}
                                sx={{
                                    fontFamily: "'Inter', sans-serif",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    backgroundColor: "#f7f7f9",
                                    borderRadius: "8px",
                                    color: "#9491AD",
                                    mb: 3,
                                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                                        border: "none",
                                    },
                                }}
                            />
                            <Typography sx={{ fontSize: "14px", pb: 1, fontWeight: "600", fontFamily: "'Inter', sans-serif", }}>
                                Password
                            </Typography>
                            <TextField
                                required
                                placeholder='Password'
                                type={showPassword ? "text" : "password"}
                                name='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                inputProps={{
                                    style: { color: "#9491AD" },
                                }}
                                sx={{
                                    fontFamily: "'Inter', sans-serif",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    backgroundColor: "#f7f7f9",
                                    borderRadius: "8px",
                                    "& fieldset.MuiOutlinedInput-notchedOutline": {
                                        border: "none",
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                            {/* <br></br>
                            <br></br>
                            <li>Password length must be Greater than 5</li>
                            <li>Must have one uppercase character</li>
                            <li>Must have one lowercase character</li> */}
                            <FormControlLabel
                                sx={{
                                    marginTop: "15px"
                                }}
                                label={
                                    <Stack direction="row">
                                        <Typography sx={{ mr: 0.5, float: "left", fontFamily: "'Inter', sans-serif", fontWeight: '400', fontSize: '12px', color: '#4F4F4F' }}>I agree to</Typography>
                                        <Link exact to='/termsAndconditons' style={{ fontFamily: "'Inter', sans-serif", fontWeight: '500', fontSize: '12px', color: "#272727" }}>
                                            Terms and Conditions
                                        </Link>
                                    </Stack>
                                }
                                control={
                                    <Checkbox onChange={(e) => setTermsCondition(e.target.checked)} checked={termsCondition} icon={<RadioButtonUncheckedIcon sx={{ border: '#f7f7f7' }} />} checkedIcon={<CheckCircleIcon sx={{ color: '#3D17C6' }} />} />
                                }
                            />
                            <br />
                            <FormControlLabel
                                sx={{
                                    marginTop: ""
                                }}
                                label={
                                    <Stack direction="row">
                                        <Typography sx={{ mr: 0.5, float: "left", fontFamily: "'Inter', sans-serif", fontWeight: '400', fontSize: '12px', color: '#4F4F4F' }}>I agree to</Typography>
                                        <Link exact to='/privacyAndpolicy' style={{ fontFamily: "'Inter', sans-serif", fontWeight: '500', fontSize: '12px', color: "#272727" }}>
                                            Privacy Policy
                                        </Link>
                                    </Stack>
                                }
                                control={
                                    <Checkbox onChange={(e) => setPrivacyPolicy(e.target.checked)} checked={privacyPolicy} icon={<RadioButtonUncheckedIcon sx={{ border: '#f7f7f7' }} />} checkedIcon={<CheckCircleIcon sx={{ color: '#3D17C6' }} />} />
                                }
                            />
                            <Button
                                type='submit'
                                variant='contained'
                                onClick={registerButtonHandler}
                                fullWidth
                                sx={{ mt: 1, pb: 1, pt: 1, borderRadius: "6px", textTransform: "none", fontSize: "14px", fontWeight: "500", backgroundColor: buttonLoading || !termsCondition || !privacyPolicy ? "#e7e7e7" : "#3D17C6" }}
                                disabled={buttonLoading || !termsCondition || !privacyPolicy}
                            >
                                {
                                    buttonLoading && <CircularProgress size={25} />
                                }
                                {
                                    !buttonLoading && "Register with Us"
                                }
                            </Button>
                            <Stack direction='row' sx={{ mt: 2, fontFamily: "'HK Nova', sans-serif" }}>
                                <Typography sx={{ mr: 0.5, fontSize: '14px', color: '#4F4F4F' }}>Already have an account?</Typography>
                                <Link exact to='/login' style={{ color: "#3D17C6", fontSize: '14px', textDecoration: 'none', fontWeight: '700' }}>
                                    Login
                                </Link>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    )
}

export default Register