import React from 'react'
import { ThemeProvider } from "@mui/material/styles";
import {
    Box, Button, Typography, Grid, Chip, Autocomplete,
    Switch,
    CircularProgress,
    Snackbar, Alert,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { StyledHeaderTypography, StyledTextField, colorTheme } from '../../../stylings/styles';


const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Options = (props) => {
    const navigate = useNavigate();
    const [optionsData, setOptionsData] = React.useState([])
    const [isSomethingChanged, setIsSomethingChanged] = React.useState(false)
    const [dataSaved, setDataSaved] = React.useState(false);
    const [selectedEmail, setSelectedEmail] = React.useState([]);
    const [allEmails, setAllEmails] = React.useState(['mail']);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false)
    const [successMessage, setSuccessMessage] = React.useState('');
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(null)
    const [optionLoading, setOptionLoading] = React.useState(false)

    React.useEffect(() => {
        fetch(`${REACT_APP_SERVER_URL}/get_options`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include",
            method: 'POST',
            body: JSON.stringify({
                "campaign": {
                    'campaign_id': props.id
                }
            })
        }).then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else if (response.status === 202) {
                console.log("token expired")
                navigate('/login', { state: { sessionExpired: true } })
            }
            else {
                console.log("some error")
            }
        })
            .then(data => {
                setOptionsData(data['data'])
                setSelectedEmail(data['data']['data']['email_account']['emails'])
                setAllEmails(data['data']['data']['all_email'])
            })
            .catch(error => console.log(error))
    }, [dataSaved])

    console.log(optionsData)
    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorSnackbarOpen(false);
    }
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessSnackbarOpen(false);
    }
    // const handleChange = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     let tempOptionsData = optionsData
    //     tempOptionsData['data']['email_account']['emails'] = typeof value === 'string' ? value.split(',') : value
    //     setOptionsData(tempOptionsData)
    //     setSelectedEmail(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    //     setIsSomethingChanged(!isSomethingChanged)
    // };

    const handleChange = (event, newValue) => {
        const tempOptionsData = { ...optionsData };
        const selectedEmails = typeof newValue === 'string' ? newValue.split(',') : newValue;

        tempOptionsData.data.email_account.emails = selectedEmails;

        setOptionsData(tempOptionsData);
        setSelectedEmail(selectedEmails);
        setIsSomethingChanged(!isSomethingChanged);
    };

    const from_time = []
    const to_time = []
    for (let i = 0; i < 24; i++) {
        if (i < 10) {
            from_time.push(`0${i}:00`)
            to_time.push(`0${i}:00`)
        }
        else {
            from_time.push(`${i}:00`)
            to_time.push(`${i}:00`)
        }
    }

    const deliveryOptimisationHandler = (e) => {
        let tempData = optionsData
        if (tempData['data']["delivery_optimization"] === 'on')
            tempData['data']["delivery_optimization"] = 'off'
        else
            tempData['data']["delivery_optimization"] = 'on'

        setOptionsData(tempData)
        setIsSomethingChanged(!isSomethingChanged)
    }

    const openTrackingHandler = (e) => {
        let tempData = optionsData
        if (tempData['data']["open_tracking"] === 'on')
            tempData['data']["open_tracking"] = 'off'
        else
            tempData['data']["open_tracking"] = 'on'

        setOptionsData(tempData)
        setIsSomethingChanged(!isSomethingChanged)
    }

    const dailyLimitHandler = (e) => {
        let tempData = optionsData
        tempData['data']["daily_limit"] = parseInt(e.target.value)
        setOptionsData(tempData)
        setIsSomethingChanged(!isSomethingChanged)
    }
    const optionsSaveHandler = () => {
        setOptionLoading(true)
        if (optionsData['data']['daily_limit'] >= 200 && optionsData['data']['daily_limit'] <= 2000) {
            fetch(`${REACT_APP_SERVER_URL}/options_update`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                method: 'POST',
                body: JSON.stringify({
                    "data": optionsData,

                })
            }).then(response => {
                if (response.status === 200) {
                    setDataSaved(!dataSaved)
                    setSuccessSnackbarOpen(true)
                    setOptionLoading(false)
                    return response.json()
                }
                else if (response.status === 202) {
                    setOptionLoading(false)
                    console.log("token expired")
                    navigate('/login', { state: { sessionExpired: true } })
                }
                else {
                    setOptionLoading(false)
                    setErrorSnackbarOpen(true)
                    props.saveErrorHandler()
                    console.log("some error")
                }
            })
                .then(data => {
                    setErrorMessage(data.msg)
                    setSuccessMessage(data.msg)
                })
                .catch(error => console.log(error))
        } else {
            setOptionLoading(false)
            setErrorMessage("Daily Limit Should be In Given Range")
            setErrorSnackbarOpen(true)
        }
    }

    const onReplyHandler = (e) => {
        let tempData = optionsData
        if (tempData['data']["on_reply"] === 'on')
            tempData['data']["on_reply"] = 'off'
        else
            tempData['data']["on_reply"] = 'on'

        setOptionsData(tempData)
        // setIsSomethingChanged(!isSomethingChanged)
    }

    console.log(selectedEmail);
    return (
        <ThemeProvider theme={colorTheme}>
            {
                optionsData.length === 0 &&
                <Box sx={{ display: "flex", justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            }
            {
                optionsData.length !== 0 &&
                <>
                    <StyledHeaderTypography sx={{ mb: 1 }}>
                        Sender's Email ID
                    </StyledHeaderTypography>
                    {/* <FormControl sx={{ mb: 2, width: "100%" }}>
                        <StyledSelectOpt

                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            required
                            value={selectedEmail}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} onDelete={() => handleDelete(value)} />
                                    ))}
                                </Box>
                            )}
                        >
                            {allEmails.map((email) => (
                                <MenuItem
                                    key={email}
                                    value={email}
                                >
                                    {email}
                                </MenuItem>
                            ))}
                        </StyledSelectOpt>
                    </FormControl> */}


                    <Autocomplete multiple id="tags-default" placeholder="Favorites" options={allEmails} value={selectedEmail}
                        onChange={handleChange}
                        // onChange={(event, newValue) => setSelectedEmail(newValue)} 
                        getOptionLabel={(option) => option}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip key={index} label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <StyledTextField {...params} variant="outlined" label="Emails" sx={{
                                "& .MuiInputLabel-root": {
                                    "&.Mui-focused": {
                                        color: "#481BEB"
                                    }
                                }
                            }} />
                        )}
                    />




                    <StyledHeaderTypography sx={{ fontSize: "16px", fontWeight: "700" }}>
                        Email Tracking
                    </StyledHeaderTypography>
                    <Switch sx={{ mb: 2 }} color='success' checked={optionsData['data']['open_tracking'] === 'on'} onChange={openTrackingHandler} name="open_tracking" />
                    {/* <StyledHeaderTypography sx={{ fontSize: "16px", fontWeight: "700" }}>
                        Auto Optimiser
                    </StyledHeaderTypography>
                    <Switch color='success' checked={optionsData['data']['delivery_optimization'] === 'on'} name="delivery_optimization" onChange={deliveryOptimisationHandler} />
                    <Typography sx={{ fontSize: "14px", fontWeight: "400", color: "#7d7d7d", mb: 2 }}>
                        * This features removes the email account, if found in SPAM
                    </Typography> */}
                    <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                        Stop Sending Mail On Reply
                    </Typography>
                    <Switch sx={{ mb: 2,}} color='success' checked={optionsData['data']['on_reply'] === 'on'} onChange={onReplyHandler} name="on_reply" />
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        {/* <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                            <Typography sx={{ fontSize: "16px", fontWeight: "700", mb: 1 }}>
                                Time Gap Between the Email Delivery
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: "flex", alignItems: "center", gap: '10px' }}>
                                    <Typography sx={{ }}>Between</Typography>
                                    <StyledSelectOpt
                                        style={{ width: "200px" }}
                                    >
                                        {
                                            from_time.map(time => (
                                                <MenuItem key={time} value={time}>{time}</MenuItem>
                                            ))
                                        }
                                    </StyledSelectOpt>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: "flex", alignItems: "center", gap: '10px' }}>
                                    <Typography sx={{ }}>To</Typography>
                                    <StyledSelectOpt
                                        style={{ width: "200px" }}
                                    >
                                        {
                                            from_time.map(time => (
                                                <MenuItem key={time} value={time}>{time}</MenuItem>
                                            ))
                                        }
                                    </StyledSelectOpt>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Typography sx={{ fontSize: "16px", fontWeight: "700", mb: 1 }}>
                                Daily Sending Limit (200 - 2000)
                            </Typography>
                            <StyledTextField
                                type="number"
                                value={optionsData['data']["daily_limit"]}
                                onChange={dailyLimitHandler}
                                fullWidth
                                inputProps={{
                                    min: "200",
                                    max: "2000"
                                }}
                                sx={{
                                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                                    {
                                        display: 'none',
                                    },
                                    '& input[type=number]': {
                                        MozAppearance: 'textfield',
                                    },
                                }}
                            />
                            <Typography sx={{ fontSize: "14px", fontWeight: "400", color: "#7d7d7d", mb: 2 }}>
                                * This will reach 20 new lead per day
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={optionsSaveHandler} variant="contained" disabled={optionLoading}>
                            {
                                optionLoading && <CircularProgress size={25} />
                            }
                            {
                                !optionLoading && "Save"
                            }
                        </Button>
                    </Box>
                </>
            }
            <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    )
}

export default Options