import React from 'react'
import { Box, Card, Button, Typography, Grid } from '@mui/material'
import cookie from 'cookiejs';
import jwt from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
const Integrations = () => {
    const user = jwt(cookie.get("token"));
    const navigate = useNavigate();
    
    if(user.admin){
        navigate("/login")
    }
    return (
        <Box>
            <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <Card sx={{ border: "1px solid #e7e7e7", borderRadius: "10px", display: "flex", justifyContent: "center", p: 3, boxShadow: "none",flexDirection:"column" }}>
                        <Box sx={{ display: "flex", justifyContent: "start", width: "100%", flexDirection: "column" }}>
                            <Typography>Asana</Typography>
                            <Typography>Integrate Asana with XCampaign</Typography>
                        </Box>
                        <Button
                            fullWidth
                            variant='contained'
                            sx={{ backgroundColor: 'rgba(72, 27, 235, 0.05)', borderRadius: '6px', color: '#481BEB', boxShadow: "none",mt:2,fontSize:'14px',fontWeight:"700",textTransform:"none" }}
                        >
                            Connect
                        </Button>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <Card sx={{ border: "1px solid #e7e7e7", borderRadius: "10px", display: "flex", justifyContent: "center", p: 3, boxShadow: "none",flexDirection:"column" }}>
                        <Box sx={{ display: "flex", justifyContent: "start", width: "100%", flexDirection: "column" }}>
                            <Typography>Asana</Typography>
                            <Typography>Integrate Asana with XCampaign</Typography>
                        </Box>
                        <Button
                            fullWidth
                            variant='contained'
                            sx={{ backgroundColor: 'rgba(72, 27, 235, 0.05)', borderRadius: '6px', color: '#481BEB', boxShadow: "none",mt:2,fontSize:'14px',fontWeight:"700",textTransform:"none" }}
                        >
                            Connect
                        </Button>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <Card sx={{ border: "1px solid #e7e7e7", borderRadius: "10px", display: "flex", justifyContent: "center", p: 3, boxShadow: "none",flexDirection:"column" }}>
                        <Box sx={{ display: "flex", justifyContent: "start", width: "100%", flexDirection: "column" }}>
                            <Typography>Asana</Typography>
                            <Typography>Integrate Asana with XCampaign</Typography>
                        </Box>
                        <Button
                            fullWidth
                            variant='contained'
                            sx={{ backgroundColor: 'rgba(72, 27, 235, 0.05)', borderRadius: '6px', color: '#481BEB', boxShadow: "none",mt:2,fontSize:'14px',fontWeight:"700",textTransform:"none" }}
                        >
                            Connect
                        </Button>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <Card sx={{ border: "1px solid #e7e7e7", borderRadius: "10px", display: "flex", justifyContent: "center", p: 3, boxShadow: "none",flexDirection:"column" }}>
                        <Box sx={{ display: "flex", justifyContent: "start", width: "100%", flexDirection: "column" }}>
                            <Typography>Asana</Typography>
                            <Typography>Integrate Asana with XCampaign</Typography>
                        </Box>
                        <Button
                            fullWidth
                            variant='contained'
                            sx={{ backgroundColor: 'rgba(72, 27, 235, 0.05)', borderRadius: '6px', color: '#481BEB', boxShadow: "none",mt:2,fontSize:'14px',fontWeight:"700",textTransform:"none" }}
                        >
                            Connect
                        </Button>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <Card sx={{ border: "1px solid #e7e7e7", borderRadius: "10px", display: "flex", justifyContent: "center", p: 3, boxShadow: "none",flexDirection:"column" }}>
                        <Box sx={{ display: "flex", justifyContent: "start", width: "100%", flexDirection: "column" }}>
                            <Typography>Asana</Typography>
                            <Typography>Integrate Asana with XCampaign</Typography>
                        </Box>
                        <Button
                            fullWidth
                            variant='contained'
                            sx={{ backgroundColor: 'rgba(72, 27, 235, 0.05)', borderRadius: '6px', color: '#481BEB', boxShadow: "none",mt:2,fontSize:'14px',fontWeight:"700",textTransform:"none" }}
                        >
                            Connect
                        </Button>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <Card sx={{ border: "1px solid #e7e7e7", borderRadius: "10px", display: "flex", justifyContent: "center", p: 3, boxShadow: "none",flexDirection:"column" }}>
                        <Box sx={{ display: "flex", justifyContent: "start", width: "100%", flexDirection: "column" }}>
                            <Typography>Asana</Typography>
                            <Typography>Integrate Asana with XCampaign</Typography>
                        </Box>
                        <Button
                            fullWidth
                            variant='contained'
                            sx={{ backgroundColor: 'rgba(72, 27, 235, 0.05)', borderRadius: '6px', color: '#481BEB', boxShadow: "none",mt:2,fontSize:'14px',fontWeight:"700",textTransform:"none" }}
                        >
                            Connect
                        </Button>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <Card sx={{ border: "1px solid #e7e7e7", borderRadius: "10px", display: "flex", justifyContent: "center", p: 3, boxShadow: "none",flexDirection:"column" }}>
                        <Box sx={{ display: "flex", justifyContent: "start", width: "100%", flexDirection: "column" }}>
                            <Typography>Asana</Typography>
                            <Typography>Integrate Asana with XCampaign</Typography>
                        </Box>
                        <Button
                            fullWidth
                            variant='contained'
                            sx={{ backgroundColor: 'rgba(72, 27, 235, 0.05)', borderRadius: '6px', color: '#481BEB', boxShadow: "none",mt:2,fontSize:'14px',fontWeight:"700",textTransform:"none" }}
                        >
                            Connect
                        </Button>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <Card sx={{ border: "1px solid #e7e7e7", borderRadius: "10px", display: "flex", justifyContent: "center", p: 3, boxShadow: "none",flexDirection:"column" }}>
                        <Box sx={{ display: "flex", justifyContent: "start", width: "100%", flexDirection: "column" }}>
                            <Typography>Asana</Typography>
                            <Typography>Integrate Asana with XCampaign</Typography>
                        </Box>
                        <Button
                            fullWidth
                            variant='contained'
                            sx={{ backgroundColor: 'rgba(72, 27, 235, 0.05)', borderRadius: '6px', color: '#481BEB', boxShadow: "none",mt:2,fontSize:'14px',fontWeight:"700",textTransform:"none" }}
                        >
                            Connect
                        </Button>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Integrations