import React from 'react'
import { CircularProgress, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const VerifyAccount = () => {
    const navigate = useNavigate()
    const [success, setSuccess] = React.useState(null)
    const token = window.location.pathname.split('/')[2]
    React.useEffect(() => {

        fetch(`${REACT_APP_SERVER_URL}/verify-account/${token}`, {
            headers: {
                'Accept': 'application/json',
            },
            credentials: "include",
            method: 'GET',

        }).then(response => {
            if (response.status === 200) {
                console.log(response)
                setSuccess(true)
                return response.json()
            }
            else {
                setSuccess(false)
                return response.json()
            }

        })
            .then(data => {
                navigate('/login', { state: { accountVerified: success ? 'success' : 'error', msg: data.msg } })
            })
            .catch(error => console.log(error))

    }, [])
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', height: "100vh", alignItems: 'center' }}>
            <CircularProgress />
        </Box>
    )
}

export default VerifyAccount